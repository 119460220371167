export const LOAD_PAGE = '[Invoice] load page';
export const LOAD_PAGE_COMPLETED = '[Invoice] load page completed';
export const SELECT_PAGE = '[Invoice] select page';
export const CHANGE_PAGER = '[Invoice] change pager';
export const SINGLE_LOAD = '[Invoice] single load';
export const SINGLE_LOAD_COMPLETED = '[Invoice] single load completed';
export const SELECT = '[Invoice] select';
export const DELETE = '[Invoice] delete';
export const DELETE_COMPLETED = '[Invoice] delete completed';
export const SEARCH = '[Invoice] search';
export const SEARCHING = '[Invoice] searching';
export const LOADALL = '[Invoice] load all';
export const LOADALL_COMPLETED = '[Invoice] load all completed';
export const RESETSTATE = '[Invoice] reset state';
export const RELOAD_ENTITY = '[Invoice] reload entity';
