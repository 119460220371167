<ion-header mode="ios" class="pb-3 pt-8">
  <bkkr-container>
    <h3 class="font-bold mb-0">{{ 'LANGUAGE_SELECTOR_TITLE' | translate }}</h3>
  </bkkr-container>
</ion-header>
<ion-content>
  <bkkr-container>
    <shared-language-select-form (completed)="onComplete()"></shared-language-select-form>
  </bkkr-container>
</ion-content>
