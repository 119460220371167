import { BaseStaticSelector } from '../../../common/store/selectors/base-static-selector';
import { ContentCategoryModel } from '../../models/content-category.model';
import { ContentCategoryState } from '../reducers/content-category.reducer';

export type ContentCategoryStatePart = {
  contentCategory: ContentCategoryState;
};
class ContentCategorySelectors extends BaseStaticSelector<ContentCategoryState, ContentCategoryModel> {
  constructor() {
    super((state: ContentCategoryStatePart): ContentCategoryState => state.contentCategory);
  }
}

export const ContentCategorySelector = new ContentCategorySelectors();
