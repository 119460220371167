import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { SpotifyTrackDetailsModel } from '../models/spotify-track-details.model';
import { SpotifyTrackEditModel } from '../models/spotify-track-edit.model';
import { SpotifyTrackSearchModel } from '../models/spotify-track-search.model';

export abstract class SpotifyTrackServiceAbstract {
  abstract edit(model: SpotifyTrackEditModel, id: string): Promise<ResponseContainer<SpotifyTrackDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>
  ): Promise<PagerResponseContainer<SpotifyTrackDetailsModel>>;
  abstract get(id: string): Promise<ResponseContainer<SpotifyTrackDetailsModel>>;
  abstract delete(id: string): Promise<ResponseContainer<unknown>>;
}
