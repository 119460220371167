import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { SpotifyArtistSetResponseModel } from '../models/spotift-artist-set-response.model';
import { SpotifyArtistDetailsModel } from '../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../models/spotify-artist-search.model';
import { SpotifyArtistSetModel } from '../models/spotify-artist-set.model';

export abstract class SpotifyArtistServiceAbstract {
  abstract getPage(
    pager: PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>
  ): Promise<PagerResponseContainer<SpotifyArtistDetailsModel>>;
  abstract set(model: SpotifyArtistSetModel): Promise<ResponseContainer<SpotifyArtistSetResponseModel>>;
}
