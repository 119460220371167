/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Expose } from 'class-transformer';

import { BankAccountMandateModel } from './bank-account-mandate.model';

export class BankAccountDetailsModel {
  @Expose()
  id: number;
  @Expose()
  iban: string;
  @Expose()
  bic: string;
  @Expose()
  countryId: number;
  @Expose()
  zip: string;
  @Expose()
  city: string;
  @Expose()
  address: string;
  @Expose()
  name?: string;

  @Expose()
  mandate: BankAccountMandateModel;
}
