import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { last, map, takeWhile } from 'rxjs/operators';

import { Store } from '@ngrx/store';

export const loadedGuard: CanActivateFn = () =>
  isLoaded().pipe(
    takeWhile((isLoaded) => !isLoaded, true),
    last()
  );

const isLoaded = (store = inject(Store)) =>
  store.pipe(
    map((state) =>
      Object.keys(state)
        .map((key) => ({ isLoaded: state[key]?.loaded, key }))
        // .map((a)=>{console.log(a);return a;})
        .map(({ isLoaded }) => (typeof isLoaded === 'undefined' ? true : isLoaded))
        .reduce((allLoaded, moduleLoaded) => allLoaded && moduleLoaded, true)
    )
  );
