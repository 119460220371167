import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TransactionEffects } from '../store/transaction.effects';
import { TransactionServiceAbstract } from '../providers/transaction.service.abstract';
import { TransactionService } from '../providers/transaction.service';
import { transactionReducer } from '../store/transaction.reducer';


@NgModule({
  imports: [EffectsModule.forFeature([TransactionEffects]), StoreModule.forFeature('transaction', transactionReducer)],
  providers: [{ provide: TransactionServiceAbstract, useClass: TransactionService }],
})
export class TransactionStateModule {}
