import { Mappings } from '../../../tools/mapping/mappings';
import { GenreModel } from '../../models/genre.model';
import { GenreActions, GenreActionType } from '../actions';

export class GenreState {
  entities: { [key: number]: GenreModel };
  ids: number[];
  loaded: boolean;
}

const initialState: GenreState = {
  entities: {},
  ids: [],
  loaded: false,
};

export const genreReducer = (state: GenreState = initialState, action: GenreActions.All): GenreState => {
  switch (action.type) {
    case GenreActionType.LOAD_FROM_DB_COMPLETED: {
      if (!state.loaded) {
        return Mappings.assign(state, addPayloadToState(state, action.payload));
      } else {
        return state;
      }
    }
    case GenreActionType.LOAD_COMPLETED: {
      return Mappings.assign(state, addPayloadToState(state, action.payload));
    }
    case GenreActionType.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

const addPayloadToState = (state: GenreState, payload: GenreModel[]) => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((genre) => genre.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
  loaded: true,
});
