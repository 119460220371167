import { BaseStaticSelector } from '../../../common/store';
import { LanguageModel } from '../../models/language.model';
import { LanguageState } from '../reducers/language.reducer';

export type LanguageStatePart = { language: LanguageState };

class LanguageSelectors extends BaseStaticSelector<LanguageState, LanguageModel> {
  constructor() {
    super((state: LanguageStatePart): LanguageState => state.language);
  }
}

export const LanguageSelector = new LanguageSelectors();
