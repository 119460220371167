export const LOAD_PAGE = '[User] load page';
export const LOAD_PAGE_COMPLETED = '[User] load page completed';
export const SELECT_PAGE = '[User] select page';
export const CHANGE_PAGER = '[User] change pager';
export const SINGLE_LOAD = '[User] single load';
export const SINGLE_LOAD_COMPLETED = '[User] single load completed';
export const SELECT = '[User] select';
export const DELETE = '[User] delete';
export const DELETE_COMPLETED = '[User] delete completed';
export const SEARCH = '[User] search';
export const SEARCHING = '[User] searching';
export const LOADALL = '[User] load all';
export const LOADALL_COMPLETED = '[User] load all completed';
export const RESETSTATE = '[User] reset state';
export const RELOAD_ENTITY = '[User] reload entity';
export const REORDER_LOCAL = '[User] reorder local';
export const REORDER_REMOTE = '[User] reorder remote';
