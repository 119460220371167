import { ChangeDetectorRef, Pipe, PipeTransform, inject } from '@angular/core';

import { checkRoles } from '../../user/tools/role-tools/check-roles';

@Pipe({
  name: 'role',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  cdr = inject(ChangeDetectorRef);

  transform(rule: bigint, roles: number[]) {
    return checkRoles(rule, roles);
  }
}
