import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { WebsocketEffects } from '@songpush/shared/websocket';
import { DealFacade } from '@songpush/shared/deal/facade';
import { EmailStateModule } from '@songpush/shared/email';
import { FlowStateModule } from '@songpush/shared/flow/module';
import { SessionEffects } from '@songpush/core/session/store';
import { GenreEffects } from '@songpush/core/genre/store';
import { SubGenreEffects } from '@songpush/core/sub-genre/store';
import { MoodEffects } from '@songpush/core/mood/store';
import { LanguageEffects } from '@songpush/core/language/store';
import { ContentCategoryEffects } from '@songpush/core/content-category/store';
import { CountryEffects } from '@songpush/core/country/store';
import { CityEffects } from '@songpush/core/city/store';
import { SongEffects } from '@songpush/core/song/store';
import { TicketEffects } from '@songpush/core/ticket/store';
import { SpotifyArtistEffects } from '@songpush/core/spotify-artist/store';
import { UserEffects } from '@songpush/core/user/store';
import { CompanyEffects } from '@songpush/core/company/store';
import { SpotifyTrackEffects } from '@songpush/core/spotify-track/store';
import { BankAccountEffects } from '@songpush/core/bank-account/store';
import { CampaignV2Effects } from '@songpush/core/campaign-v2/store';
import { DealEffects } from '@songpush/core/deal/store';
import { SettingsEffects } from '@songpush/core/settings/store';
import { SocialBlockEffects } from '@songpush/core/social-block/store';
import { InvoiceEffects } from '@songpush/core/invoice/store';
import { DealLimitStateModule } from '@songpush/core/deal-limit/module';
import { InviteStateModule } from '@songpush/core/invite/module';
import { TeamStateModule } from '@songpush/core/team/module';
import { WalletStateModule } from '@songpush/core/wallet/module';
import { TransactionStateModule } from '@songpush/core/transaction/module';
import { SettingsNotifictaionGroupModule } from '@songpush/core/settings-notification-group/module';
import { SystemMessageStateModule } from '@songpush/core/system-message/module';
import { ManagerInvitationStateModule } from '@songpush/core/manager-invitation/module';
import { ManagerDealStateModule } from '@songpush/core/manager-deal/module';
import { AnalyticsStateModule } from '@songpush/core/analytics/module';
import { StatStateModule } from '@songpush/core/stat/module';
import { InstagramStateModule } from '@songpush/core/instagram/module';
import { SongService, SongServiceAbstract } from '@songpush/core/song/services';
import { CountryService, CountryServiceAbstract } from '@songpush/core/country/services/providers';
import { CampaignV2Service, CampaignV2ServiceAbstract } from '@songpush/core/campaign-v2/services';
import { TicketService, TicketServiceAbstract } from '@songpush/core/ticket/services';
import { FormValidatorServiceAbstract } from '@songpush/core/form/services/form-validator';
import { UserService, UserServiceAbstract } from '@songpush/core/user/services';
import { CompanyService, CompanyServiceAbstract } from '@songpush/core/company/services';
import { DealService, DealServiceAbstract } from '@songpush/core/deal/providers';
import { GenreService, GenreServiceAbstract } from '@songpush/core/genre/services';
import { MoodService, MoodServiceAbstract } from '@songpush/core/mood/services';
import { LanguageService, LanguageServiceAbstract } from '@songpush/core/language/services';
import { CityService, CityServiceAbstract } from '@songpush/core/city/services';
import { SpotifyArtistService, SpotifyArtistServiceAbstract } from '@songpush/core/spotify-artist/services';
import { MangopayService, MangopayServiceAbstract } from '@songpush/core/mangopay/services';
import { BankAccountService, BankAccountServiceAbstract } from '@songpush/core/bank-account/services';
import { TiktokService, TiktokServiceAbstract } from '@songpush/core/tiktok/services';
import { SettingsService, SettingsServiceAbstract } from '@songpush/core/settings/services';
import { InvoiceService, InvoiceServiceAbstract } from '@songpush/core/invoice/services';
import { SpotifyTrackService, SpotifyTrackServiceAbstract } from '@songpush/core/spotify-track/services';
import { ContentCategoryService, ContentCategoryServiceAbstract } from '@songpush/core/content-category/services';
import { SocialBlockService, SocialBlockServiceAbstract } from '@songpush/core/social-block/services/providers';
import { SessionManagedFacade } from '@songpush/core/session/facade';
import { SubGenreService, SubGenreServiceAbstract } from '@songpush/core/sub-genre/services';
import { metaReducers, rootReducer } from '@songpush/shared/common/store/reducers';
import { FormValidatorService } from '@songpush/shared/common/services/form-validator';

import { CommonStateModule } from './common/common.state.module';
import { WebsocketStateModule } from './websocket/websocket.state.module';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(rootReducer, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({}),
    EffectsModule.forRoot([
      SessionEffects,
      GenreEffects,
      SubGenreEffects,
      MoodEffects,
      LanguageEffects,
      ContentCategoryEffects,
      CountryEffects,
      CityEffects,
      SongEffects,
      TicketEffects,
      SpotifyArtistEffects,
      WebsocketEffects,
      UserEffects,
      CompanyEffects,
      SpotifyTrackEffects,
      BankAccountEffects,
      CampaignV2Effects,
      DealEffects,
      SettingsEffects,
      SocialBlockEffects,
      InvoiceEffects,
    ]),
    CommonStateModule,
    WebsocketStateModule,
    DealLimitStateModule,
    InviteStateModule,
    TeamStateModule,
    EmailStateModule,
    FlowStateModule,
    WalletStateModule,
    TransactionStateModule,
    SettingsNotifictaionGroupModule,
    SystemMessageStateModule,
    ManagerInvitationStateModule,
    ManagerDealStateModule,
    AnalyticsStateModule,
    StatStateModule,
    InstagramStateModule,
  ],
  providers: [
    { provide: SongServiceAbstract, useClass: SongService },
    { provide: TicketServiceAbstract, useClass: TicketService },
    { provide: UserServiceAbstract, useClass: UserService },
    { provide: GenreServiceAbstract, useClass: GenreService },
    { provide: SubGenreServiceAbstract, useClass: SubGenreService },
    { provide: MoodServiceAbstract, useClass: MoodService },
    { provide: LanguageServiceAbstract, useClass: LanguageService },
    {
      provide: ContentCategoryServiceAbstract,
      useClass: ContentCategoryService,
    },
    { provide: CountryServiceAbstract, useClass: CountryService },
    { provide: CityServiceAbstract, useClass: CityService },
    { provide: CampaignV2ServiceAbstract, useClass: CampaignV2Service },
    { provide: FormValidatorServiceAbstract, useClass: FormValidatorService },
    { provide: SpotifyArtistServiceAbstract, useClass: SpotifyArtistService },
    { provide: SpotifyTrackServiceAbstract, useClass: SpotifyTrackService },
    { provide: UserServiceAbstract, useClass: UserService },
    { provide: CompanyServiceAbstract, useClass: CompanyService },
    { provide: MangopayServiceAbstract, useClass: MangopayService },
    { provide: BankAccountServiceAbstract, useClass: BankAccountService },
    { provide: DealServiceAbstract, useClass: DealService },
    { provide: TiktokServiceAbstract, useClass: TiktokService },
    { provide: SettingsServiceAbstract, useClass: SettingsService },
    { provide: SocialBlockServiceAbstract, useClass: SocialBlockService },
    { provide: InvoiceServiceAbstract, useClass: InvoiceService },
    DealFacade,
    SessionManagedFacade,
  ],
})
export class AppStoreModule {}
