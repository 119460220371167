/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from '@ngrx/store';

import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { CompanyState } from '../reducers/company.reducer';

export type CompanyStatePart = { company: CompanyState };

class CompanySelectors extends BaseMultiPagerSelector<CompanyState, CompanyDetailsModel, CompanySearchModel> {
  get = createSelector(this.getList, (list) => list.find((item) => item?.own));

  getType = createSelector(this.get, (company) => company?.type);

  getPayInProcess = createSelector(this.get, (company) => company?.campaignPayinProcess);

  constructor() {
    super((state: CompanyStatePart): CompanyState => state.company);
  }
}

export const CompanySelector = new CompanySelectors();
