import { Injectable } from '@angular/core';

import { defer, Observable } from 'rxjs';
import { delayWhen, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';

import { SettingsSelector } from '../../../settings/store/selectors';
import { MoodModel } from '../../models/mood.model';
import { MoodServiceAbstract } from '../../services/mood.service.abstract';
import { MoodActions, MoodActionType } from '../actions';
import { MoodSelector } from '../selectors/mood.selector';
import { filterEmpty } from '../../../tools/map/filter-empty';


@Injectable()
export class MoodEffects {
  openDB$: Observable<LocalForage> = defer(() => this.storage.ready()).pipe(
    delayWhen(() =>
      this.store.select(SettingsSelector.getLoaded).pipe(
        filter((loaded) => !!loaded),
        take(1)
      )
    )
  );

  initMood$ = createEffect(() =>
    this.actions$.pipe(
      ofType('@ngrx/effects/init'),
      switchMap(() => this.openDB$),
      // load from db and from api
      mergeMap(() => [new MoodActions.LoadFromDb()])
    )
  );

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoodActionType.LOAD_FROM_DB),
      switchMap(() => this.openDB$),
      switchMap(() => this.storage.get(this.MOOD_KEY)),
      // filter non stored state
      filterEmpty(),
      map((plainState) => (plainState ? plainToClass(MoodModel, plainState as any[]) : null)),
      map((moods) => new MoodActions.LoadFromDbCompleted(moods))
    )
  );

  saveToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoodActionType.SAVE_TO_DB),
      mergeMap(() => this.openDB$),
      // get current
      mergeMap(() => this.store.select(MoodSelector.getAll).pipe(take(1))),
      // if there is no token we dont have to save
      mergeMap((state) => this.storage.set(this.MOOD_KEY, state)),
      map(() => new MoodActions.SaveToDbCompleted())
    )
  );

  loadFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoodActionType.LOAD),
      mergeMap(() => this.moodService.getAll()),
      map((moods) => new MoodActions.LoadCompleted(moods))
    )
  );

  loadCompletedToSave = createEffect(() =>
    this.actions$.pipe(
      ofType(MoodActionType.LOAD_COMPLETED),
      map(() => new MoodActions.SaveToDb())
    )
  );

  private readonly MOOD_KEY = 'moods';

  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: Storage,
    private moodService: MoodServiceAbstract
  ) {}
}
