import { NgModule, makeEnvironmentProviders } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideState } from '@ngrx/store';

import { managerInvitationReducer } from '../store/manager-invitation.reducer';
import { ManagerInvitationEffects } from '../store/manager-invitation.effects';
import { ManagerInvitationServiceAbstract } from '../providers/manager-invitation.service.abstract';
import { ManagerInvitationService } from '../providers/manager-invitation.service';

export const provideManagerInvitationState = () =>
  makeEnvironmentProviders([
    provideState('managerInvitation', managerInvitationReducer),
    provideEffects([ManagerInvitationEffects]),
    { provide: ManagerInvitationServiceAbstract, useClass: ManagerInvitationService },
  ]);

@NgModule({
  imports: [
    EffectsModule.forFeature([ManagerInvitationEffects]),
    StoreModule.forFeature('managerInvitation', managerInvitationReducer),
  ],
  providers: [{ provide: ManagerInvitationServiceAbstract, useClass: ManagerInvitationService }],
})
export class ManagerInvitationStateModule {}
