import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DealLimitEffects } from '../store/deal-limit.effects';
import { DealLimitServiceAbstract } from '../services/deal-limit.service.abstract';
import { DealLimitFacade } from '../facade/deal-limit.facade';
import { DealLimitService } from '../services/deal-limit.service';
import { dealLimitReducer } from '../store/deal-limit.reducer';

@NgModule({
  imports: [EffectsModule.forFeature([DealLimitEffects]), StoreModule.forFeature('dealLimit', dealLimitReducer)],
  providers: [{ provide: DealLimitServiceAbstract, useClass: DealLimitService }, DealLimitFacade],
})
export class DealLimitStateModule {}
