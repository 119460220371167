import { PagerEntity } from '../../../common/models';
import { Mappings } from '../../../tools/mapping/mappings';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { CountryActions, CountryActionType } from '../actions';

export class CountryState {
  entities: { [key: number]: CountryModel };
  selectedId: number;
  pagerIds: string[];
  knownIds: number[];
  pagers: {
    [key: string]: PagerEntity<CountryModel, CountrySearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<CountryModel, CountrySearchModel>;
}

const initialPager = new PagerEntity<CountryModel, CountrySearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null, // should be more than limit
  limit: 50,
  asc: true,
  orderBy: '',
  search: new CountrySearchModel({
    search: '',
  }),
});

const initialState: CountryState = {
  entities: {},
  selectedId: null,
  pagerIds: ['geoTargeting'],
  pagers: {
    ['geoTargeting']: Mappings.assign(initialPager, { search: new CountrySearchModel({ geoTargeting: true }) }),
  },
  ids: [],
  initialPager,
  knownIds: [38, 133, 155, 193, 202, 203, 204],
};

export const countryReducer = (state: CountryState = initialState, action: CountryActions.All): CountryState => {
  switch (action.type) {
    case CountryActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case CountryActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case CountryActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case CountryActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case CountryActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case CountryActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case CountryActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case CountryActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case CountryActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
