import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { getTiktokSoundUrlRegex } from '@songpush/validators/tools/tiktok-sound-url-regex';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';
import { SongCreateModel } from '../models/song-create.model';
import { SongDetailsModel } from '../models/song-details.model';
import { SongEditModel } from '../models/song-edit.model';
import { SongSearchModel } from '../models/song-search.model';
import { SongServiceAbstract } from './song.service.abstract';

const getSongIdType = (id: number | string) => {
  const tiktokRegex = getTiktokSoundUrlRegex();
  if (`${id}`.match(tiktokRegex)) {
    return 'tiktok';
  } else if (isNaN(id as number)) {
    return 'spotify';
  }
  return 'internal';
};
@Injectable()
export class SongService extends SongServiceAbstract {
  private readonly songUrl = `${this.apiUrl}song`;
  private readonly songBySpotify = `${this.songUrl}/spotify`;
  private readonly songByTiktokUrl = `${this.songUrl}/tiktok`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string // @Inject('UPLOAD_URL') // private uploadUrl: string, // @Inject('DOWNLOAD_URL') // private downloadUrl: string
  ) {
    super();
  }

  edit(
    model: SongEditModel,
    id: number
  ): Promise<ResponseContainer<SongDetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .put(`${this.songUrl}/${id}`, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(SongDetailsModel), resp)
        )
      )
      .toPromise();
  }
  getPage(
    pager: PagerEntity<SongDetailsModel, SongSearchModel>
  ): Promise<PagerResponseContainer<SongDetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.songUrl, { params } as any)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(SongDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  getSongRequestById(id: number | string) {
    switch (getSongIdType(id)) {
      case 'internal':
        return this.http.get(`${this.songUrl}/${id}`);
      case 'spotify':
        return this.http.get(`${this.songBySpotify}/${id}`);
      case 'tiktok':
        return this.http.get(`${this.songByTiktokUrl}`, {
          params: new HttpParams({
            fromObject: { url: Mappings.getBaseUrl(id as string) },
          }),
        });
      default:
        throw 'Song id is not supported';
    }
  }

  get(id: number | string): Promise<ResponseContainer<SongDetailsModel>> {
    return this.getSongRequestById(id)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(SongDetailsModel), resp)
        )
      )
      .toPromise();
  }
  create(model: SongCreateModel): Promise<ResponseContainer<SongDetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.songUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(SongDetailsModel), resp)
        )
      )
      .toPromise();
  }
  delete(id: number): Promise<ResponseContainer<unknown>> {
    return this.http
      .delete(`${this.songUrl}/${id}`)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  // download(name: string): Observable<{
  //   progress: HttpProgressEvent;
  //   response: Blob;
  // }> {
  //   const request = new HttpRequest(
  //     'GET',
  //     `${this.downloadUrl}/song/${name}.mp3`,
  //     { responseType: 'blob', reportProgress: true }
  //   );
  //   const req = this.http.request(request).pipe(share());
  //   const progress = merge(
  //     of({
  //       type: HttpEventType.DownloadProgress,
  //       loaded: 0,
  //       total: -1
  //     } as HttpProgressEvent),
  //     req.pipe(
  //       filter((event) => event.type === HttpEventType.DownloadProgress),
  //       map((event: HttpProgressEvent) => event)
  //     )
  //   );
  //   // eslint-disable-next-line
  //   const finished: Observable<Blob> = merge(
  //     of(null),
  //     req.pipe(
  //       filter((event) => event.type === HttpEventType.Response),
  //       map((resp: HttpResponse<Blob>) => resp.body)
  //     )
  //   );

  //   const resp = combineLatest([
  //     progress,
  //     finished
  //   ])
  //     .pipe(
  //       map(([progress, response]) =>
  //         ({ progress, response })
  //       )
  //     );
  //   return resp;
  // }

  // upload(file: File): Observable<{
  //   progress: HttpProgressEvent;
  //   response: ResponseContainer<IdNameModel>;
  // }> {
  //   const body = new FormData();
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'multipart/form-data'
  //   });
  //   const options = {
  //     reportProgress: true,
  //     headers
  //   };
  //   body.append(
  //     'song',
  //     file,
  //     file.name,
  //   );

  //   const request = new HttpRequest('POST', `${this.uploadUrl}/song`, body, options);
  //   const req = this.http.request(request).pipe(share());
  //   const progress = req.pipe(
  //     filter((event) => event.type === HttpEventType.UploadProgress),
  //     map((event: HttpProgressEvent) => event)
  //   );
  //   const finished = merge(
  //     of(null),
  //     req.pipe(
  //       filter((event) => event.type === HttpEventType.Response)
  //     )
  //   ).pipe(
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //     map((resp) => resp ? plainToClassFromExist(new ResponseContainer(IdNameModel), resp.body) : null)
  //   );

  //   return combineLatest([
  //     progress,
  //     finished
  //   ])
  //     .pipe(
  //       map(([progress, response]) =>
  //         ({ progress, response })
  //       )
  //     );
  // }
}
