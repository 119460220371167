import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import { bufferToggle, debounceTime, filter, first, map, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { SessionActions } from '../../../session/store/actions';
import { SongSearchModel } from '../../../song/models/song-search.model';
import { SongActions } from '../../../song/store/actions';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2ServiceAbstract } from '../../services/campaign-v2.service.abstract';
import { CampaignV2ActionType, CampaignV2Actions } from '../actions';
import { hasSongInStore } from '../../../song/guards/song-exits.guard';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { CampaignV2Selector, CampaignV2StatePart } from '../selectors';
import { CountryActions } from '../../../country/store/actions';
import { hasCountryInStore } from '../../../country/services/guards/country-exist.guard';
import { CountrySearchModel } from '../../../country/models/country-search.model';

@Injectable()
export class CampaignV2Effects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.SELECT_PAGE),
      mergeMap(async (action: CampaignV2Actions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      mergeMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.campaignService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new CampaignV2Actions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId,
            action.wipe
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: CampaignV2Actions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new CampaignV2Actions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.SEARCHING),
      mergeMap((action: CampaignV2Actions.Searching) => zip(of(action), this.getCampaignV2s(action.pagerId))),
      map(([action, resp]) => new CampaignV2Actions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.LOADALL),
      mergeMap((action: CampaignV2Actions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: CampaignV2DetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.campaignService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new CampaignV2Actions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new CampaignV2Actions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.SINGLE_LOAD),
      mergeMap(async (action: CampaignV2Actions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.campaignService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CampaignV2Actions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignV2ActionType.RELOAD_ENTITY),
      mergeMap(async (action: CampaignV2Actions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.campaignService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CampaignV2Actions.SingleLoadeCompleted(resp.values))
    )
  );

  resolveCreator$ = createEffect(
    () =>
      this.resolveEntities('songId', SongActions.ChangePager, SongSearchModel, SongActions.LoadAll, (id) =>
        hasSongInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveCountries$ = createEffect(
    () =>
      this.resolveEntities('countryIds', CountryActions.ChangePager, CountrySearchModel, CountryActions.LoadAll, (id) =>
        hasCountryInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  getPager = this.store.select(CampaignV2Selector.getPagers).pipe(take(1));
  getIds = this.store.select(CampaignV2Selector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private campaignService: CampaignV2ServiceAbstract,
    private store: Store<CampaignV2StatePart>
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>> {
    return this.store.select(CampaignV2Selector.getPager(instance)).pipe(first());
  }

  getSearchModel(instance: string): Observable<CampaignV2SearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getCampaignV2s(instance: string): Observable<PagerResponse<CampaignV2DetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      first(),
      mergeMap((pager) => this.campaignService.getPage(pager)),
      map((resp) => resp.values)
    );
  }

  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        CampaignV2ActionType.SINGLE_LOAD_COMPLETED,
        CampaignV2ActionType.LOADALL_COMPLETED,
        CampaignV2ActionType.LOAD_PAGE_COMPLETED
      )
    );
  }

  resolveEntities<T, Z>(
    key: keyof CampaignV2DetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action:
            | CampaignV2Actions.SingleLoadeCompleted
            | CampaignV2Actions.LoadAllCompleted
            | CampaignV2Actions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(0)),
      filter((e) => e.length > 0),
      mergeMap((requests) =>
        zip(
          ...requests
            .map((request) => request[key] as number)
            .flatMap((ids) => ids)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }
}
