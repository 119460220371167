import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';
import { CitySearchModel } from '../models/city-search.model';
import { CityModel } from '../models/city.model';
import { CityServiceAbstract } from './city.service.abstract';

@Injectable()
export class CityService extends CityServiceAbstract {
  private cityUrl = this.apiUrl + 'search/city';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  getPage(pager: PagerEntity<CityModel, CitySearchModel>): Promise<PagerResponseContainer<CityModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.cityUrl, {
        params,
      } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(CityModel), resp)))
      .toPromise();
  }

  get(id: number): Promise<ResponseContainer<CityModel>> {
    return this.http
      .get(`${this.cityUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CityModel), resp)))
      .toPromise();
  }
}
