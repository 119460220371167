export const LOAD_PAGE = '[Company] load page';
export const LOAD_PAGE_COMPLETED = '[Company] load page completed';
export const SELECT_PAGE = '[Company] select page';
export const CHANGE_PAGER = '[Company] change pager';
export const SINGLE_LOAD = '[Company] single load';
export const SINGLE_LOAD_COMPLETED = '[Company] single load completed';
export const SELECT = '[Company] select';
export const DELETE = '[Company] delete';
export const DELETE_COMPLETED = '[Company] delete completed';
export const SEARCH = '[Company] search';
export const SEARCHING = '[Company] searching';
export const LOADALL = '[Company] load all';
export const LOADALL_COMPLETED = '[Company] load all completed';
export const RESETSTATE = '[Company] reset state';
export const RELOAD_ENTITY = '[Company] reload entity';
export const REORDER_LOCAL = '[Company] reorder local';
export const REORDER_REMOTE = '[Company] reorder remote';
