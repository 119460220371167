import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { FlowProgressReducer } from '../store/reducers/flow-progress.reducer';

@NgModule({
  imports: [StoreModule.forFeature('FlowProgress', FlowProgressReducer)],
})
export class FlowStateModule {}
