import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TeamServiceAbstract } from '../providers/team.service.abstract';
import { TeamEffects } from '../store/team.effects';
import { TeamService } from '../providers/team.service';
import { teamReducer } from '../store/team.reducer';

@NgModule({
  imports: [EffectsModule.forFeature([TeamEffects]), StoreModule.forFeature('team', teamReducer)],
  providers: [{ provide: TeamServiceAbstract, useClass: TeamService }],
})
export class TeamStateModule {}
