export const LOAD_PAGE = '[CampaignV2] load page';
export const LOAD_PAGE_COMPLETED = '[CampaignV2] load page completed';
export const SELECT_PAGE = '[CampaignV2] select page';
export const CHANGE_PAGER = '[CampaignV2] change pager';
export const SINGLE_LOAD = '[CampaignV2] single load';
export const SINGLE_LOAD_COMPLETED = '[CampaignV2] single load completed';
export const SELECT = '[CampaignV2] select';
export const DELETE = '[CampaignV2] delete';
export const DELETE_COMPLETED = '[CampaignV2] delete completed';
export const SEARCH = '[CampaignV2] search';
export const SEARCHING = '[CampaignV2] searching';
export const LOADALL = '[CampaignV2] load all';
export const LOADALL_COMPLETED = '[CampaignV2] load all completed';
export const RESETSTATE = '[CampaignV2] reset state';
export const RELOAD_ENTITY = '[CampaignV2] reload entity';
