import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';
import { DealCreateModel } from '../models/deal-create.model';
import { DealDetailsModel } from '../models/deal-details.model';
import { DealEditModel } from '../models/deal-edit.model';
import { DealSearchModel } from '../models/deal-search.model';
import { DealServiceAbstract } from './deal.service.abstract';

@Injectable()
export class DealService extends DealServiceAbstract {
  private readonly dealUrl = `${this.apiUrl}deal`;
  private readonly budgetUrl = `${this.dealUrl}/budget`;
  private readonly acceptUrl = `${this.dealUrl}/accept`;
  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  getPage(pager: PagerEntity<DealDetailsModel, DealSearchModel>): Promise<PagerResponseContainer<DealDetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.dealUrl, { params } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(DealDetailsModel), resp)))
      .toPromise();
  }

  get(id: number): Promise<ResponseContainer<DealDetailsModel>> {
    return this.http
      .get(`${this.dealUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(DealDetailsModel), resp)))
      .toPromise();
  }

  create(model: DealCreateModel): Promise<ResponseContainer<DealDetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.dealUrl, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(DealDetailsModel), resp)))
      .toPromise();
  }

  delete(id: number): Promise<ResponseContainer<DealDetailsModel>> {
    return this.http
      .delete(`${this.dealUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(DealDetailsModel), resp)))
      .toPromise();
  }

  edit(model: DealEditModel, id: number): Promise<ResponseContainer<DealDetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .put(`${this.dealUrl}/${id}`, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(DealDetailsModel), resp)))
      .toPromise();
  }
}
