import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { TiktokServiceAbstract } from '../services/tiktok.service.abstract';
import { MINUTE_IN_MS } from '../../tools/timeconst/timeconst';


@Injectable({
  providedIn: 'root',
})
export class TiktokSongExistGuard implements CanActivate {
  constructor(private router: Router, private tiktokService: TiktokServiceAbstract) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasTiktokSong(route.params.id).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => (exist || null) ?? this.router.navigate(['404']))
    );
  }

  hasTiktokSongInApi(id: string): Observable<boolean> {
    return defer(() => this.tiktokService.getSongMetadata(id)).pipe(
      map((resp) => !!resp?.values),
      timeout(1 * MINUTE_IN_MS),
      catchError(() => of(false))
    );
  }

  hasTiktokSong(id: string): Observable<boolean> {
    return this.hasTiktokSongInApi(id);
  }
}
