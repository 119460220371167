import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import { bufferToggle, debounceTime, filter, flatMap, map, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { DealSearchModel } from '../../../deal/models/deal-search.model';
import { DealActions } from '../../../deal/store/actions';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { hasUserInStore } from '../../../user/guards/user-exist.guard';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { UserActions } from '../../../user/store/actions';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { TicketServiceAbstract } from '../../services/providers/ticket.service.abstract';
import { TicketActionType, TicketActions } from '../actions';
import { TicketSelector } from '../selectors/ticket.selector';
import { hasDealInStore } from '../../../deal/guards/deal-exits.guard';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class TicketEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.SELECT_PAGE),
      flatMap(async (action: TicketActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      flatMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.ticketService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new TicketActions.LoadPageCompleted(page, response.values.maxCount, response.values.results, action.pagerId)
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: TicketActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new TicketActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.SEARCHING),
      mergeMap((action: TicketActions.Searching) => zip(of(action), this.getTickets(action.pagerId))),
      map(([action, resp]) => new TicketActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.LOADALL),
      mergeMap((action: TicketActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: TicketDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.ticketService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new TicketActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => new TicketActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.SINGLE_LOAD),
      mergeMap(async (action: TicketActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.ticketService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new TicketActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActionType.RELOAD_ENTITY),
      mergeMap(async (action: TicketActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.ticketService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new TicketActions.SingleLoadeCompleted(resp.values))
    )
  );

  resolveUser$ = createEffect(
    () =>
      this.resolveEntities('userId', UserActions.ChangePager, UserSearchModel, UserActions.LoadAll, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveDeal$ = createEffect(
    () =>
      this.resolveEntities('dealId', DealActions.ChangePager, DealSearchModel, DealActions.LoadAll, (id) =>
        hasDealInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  getPager = this.store.select(TicketSelector.getPagers).pipe(take(1));
  getIds = this.store.select(TicketSelector.getIds).pipe(take(1));

  constructor(private actions$: Actions, private ticketService: TicketServiceAbstract, private store: Store) {}

  getSelectedPager(instance: string): Observable<PagerEntity<TicketDetailsModel, TicketSearchModel>> {
    return this.store.select(TicketSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<TicketSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getTickets(instance: string): Observable<PagerResponse<TicketDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.ticketService.getPage(pager)),
      map((resp) => resp.values)
    );
  }

  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        TicketActionType.SINGLE_LOAD_COMPLETED,
        TicketActionType.LOADALL_COMPLETED,
        TicketActionType.LOAD_PAGE_COMPLETED
      )
    );
  }

  resolveEntities<T, Z>(
    key: keyof TicketDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action: TicketActions.SingleLoadeCompleted | TicketActions.LoadAllCompleted | TicketActions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(0)),
      filter((e) => e.length > 0),
      mergeMap((tickets) =>
        zip(
          ...tickets
            .map((ticket) => ticket[key] as number)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }
}
