import { Action } from '@ngrx/store';

import { SubGenreModel } from '../../models/sub-genre.model';
import * as SubGenreActionTypes from './sub-genre.actions.type';

export class LoadFromDb implements Action {
  readonly type = SubGenreActionTypes.LOAD_FROM_DB;
}
export class LoadFromDbCompleted implements Action {
  readonly type = SubGenreActionTypes.LOAD_FROM_DB_COMPLETED;
  constructor(public payload: SubGenreModel[]) {}
}
export class SaveToDb implements Action {
  readonly type = SubGenreActionTypes.SAVE_TO_DB;
}
export class SaveToDbCompleted implements Action {
  readonly type = SubGenreActionTypes.SAVE_TO_DB_COMPLETED;
}
export class Load implements Action {
  readonly type = SubGenreActionTypes.LOAD;
}
export class LoadCompleted implements Action {
  readonly type = SubGenreActionTypes.LOAD_COMPLETED;
  constructor(public payload: SubGenreModel[]) {}
}

export class ResetState implements Action {
  readonly type = SubGenreActionTypes.RESET_STATE;
}

export type All = LoadFromDbCompleted | LoadCompleted | ResetState;
