import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import * as SongActionType from './song.actions.type';

export class ReloadEntity implements Action {
  readonly type = SongActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = SongActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = SongActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: SongDetailsModel[],
    public pagerId: string = 'default',
    public wipe: boolean = false
  ) {}
}

export class SelectPage implements Action {
  readonly type = SongActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default') {}
}

export class ChangePager implements Action {
  readonly type = SongActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<SongDetailsModel, SongSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = SongActionType.SEARCH;
  constructor(
    public payload: SongSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = SongActionType.SEARCHING;
  constructor(
    public payload: SongSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = SongActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = SongActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: SongDetailsModel) {}
}
export class Select implements Action {
  readonly type = SongActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = SongActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = SongActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = SongActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = SongActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<SongDetailsModel, SongSearchModel>,
    public payload: SongDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = SongActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
