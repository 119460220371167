import { inject } from '@angular/core';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';


import { ManagerInvitationEditModel } from '../models/manager-invitation-edit.model';
import { managerInvitationAction } from './manager-invitation.action';
import { managerInvitationSelector } from './manager-invitation.selector';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { SessionActions } from '../../session/store/actions/session.actions';
import { ManagerInvitationServiceAbstract } from '../providers/manager-invitation.service.abstract';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { ManagerInvitationCreateModel } from '../models/manager-invitation-create.model';

export class ManagerInvitationEffects extends PagerEffects<
  'ManagerInvitation',
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel,
  ManagerInvitationEditModel,
  ManagerInvitationCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => managerInvitationAction.resetState())
    )
  );

  constructor() {
    super(managerInvitationAction, managerInvitationSelector, inject(ManagerInvitationServiceAbstract));
  }
}
