import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { CampaignV2ServiceAbstract } from '../services/campaign-v2.service.abstract';
import { CampaignV2Actions } from '../store/actions';
import { CampaignV2Selector, CampaignV2StatePart } from '../store/selectors/campaign-v2.selector';

@Injectable()
export class CampaignV2ExistGuard implements CanActivate {
  constructor(
    private store: Store<CampaignV2StatePart>,
    private campaignV2Service: CampaignV2ServiceAbstract,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasCampaignV2(route.params.campaignId).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => (exist || null) ?? this.router.navigate(['404']))
    );
  }

  hasCampaignV2InStore(id: number): Observable<boolean> {
    return this.store.select(CampaignV2Selector.getItem(id)).pipe(
      map((ent) => !!ent),
      take(1)
    );
  }

  hasCampaignV2InApi(id: number): Observable<boolean> {
    return defer(() => this.campaignV2Service.get(id)).pipe(
      map((campaignV2) => new CampaignV2Actions.SingleLoadeCompleted(campaignV2.values)),
      tap((action: CampaignV2Actions.SingleLoadeCompleted) => this.store.dispatch(action)),
      map((action) => !!action?.payload),
      catchError(() => of(false))
    );
  }

  hasCampaignV2(id: number): Observable<boolean> {
    return this.hasCampaignV2InStore(id).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return of(inStore);
        }
        return this.hasCampaignV2InApi(id);
      })
    );
  }
}
