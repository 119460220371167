/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HttpParams } from '@angular/common/http';

import { combineLatest, merge, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MemoizedSelector } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ClassTransformOptions, instanceToPlain } from 'class-transformer';

import { getInstagramUrlRegex } from '@songpush/validators/tools/instagram-url-regex';

import { IdNameModel } from '../../common/models/id-name.model';
import { PagerEntity } from '../../common/models/PagerEntity';
import { LocalDatePipe } from '../../common/pipes/local-date/local-date.pipe';
import { SocialType } from '../../social/models/social-type';
import { IHasPagerEntities } from '../reducer-helper/model/has-pager-entities';
import { HOUR_IN_MS, MINUTE_IN_MS, SECOND_IN_MS } from '../timeconst/timeconst';

const windowLocation = typeof location === 'undefined' ? null : location;
export class Mappings {
  static assign<T>(main: T, part: { [P in keyof T]?: T[P] }): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const constructor = (main as any)?.constructor;
    return Object.assign(
      constructor instanceof Function
        ? new (constructor as new (...args: unknown[]) => T)()
        : {},
      main,
      part
    );
  }

  static entitiesPlusList<T>(
    ents: { [key: number]: T },
    list: T[],
    key: keyof T
  ): { [key: number]: T } {
    return list.reduce(
      (newEnts, ent) =>
        Mappings.assign(newEnts, { [ent[key] as any as number]: ent }),
      ents
    );
  }

  static mergeIds<T>(arr1: T[], arr2: T[]): T[] {
    return arr2.reduce(
      (ids, id) => (ids.includes(id) ? ids : [...ids, id]),
      arr1
    );
  }

  static patchPager<T, Z, U extends { pager: PagerEntity<T, Z> }>(
    state: U,
    patch: Partial<PagerEntity<T, Z>>
  ): U {
    return Mappings.assign(state, {
      pager: Mappings.assign(state.pager, patch),
    } as Partial<U>);
  }
  static patchMultiplePager<T, Z, U extends IHasPagerEntities<T, Z>>(
    state: U,
    patch: Partial<PagerEntity<T, Z>>,
    initialPager: PagerEntity<T, Z>,
    id: string = 'default'
  ): U {
    const selectedPageNum =
      patch.selectedPageNum ?? state.pagers[id]?.selectedPageNum;
    if (selectedPageNum) {
      patch = { ...patch, selectedPageNum };
    }
    const pagerIds = Mappings.mergeIds(state.pagerIds, [id]);

    return Mappings.assign(state, {
      pagerIds,
      pagers: Mappings.assign(state.pagers, {
        [id]: Mappings.assign(state.pagers[id] || initialPager, {
          ...patch,
          id,
        }),
      }),
    } as Partial<U>);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static mapSelector<T, U, K, P>(
    selector: MemoizedSelector<T, U, K>,
    p: new (...args: unknown[]) => P
  ): MemoizedSelector<P, U, K> {
    return selector as any as MemoizedSelector<P, U, K>;
  }

  static dateToString(date: Date): string {
    if (typeof date === 'string') {
      const parseable = Date.parse(date);
      if (!isNaN(parseable)) {
        date = new Date(date);
      } else {
        date = null;
      }
    }

    if (date instanceof Date) {
      return date.toISOString();
    } else {
      return '';
    }
  }

  static dateFromUtc(str: string): Date {
    return new Date(str);
  }

  static enumKeys(anyenum: unknown): number[] {
    return Object.keys(anyenum)
      .map((enumMember) => parseInt(enumMember, 10))
      .filter((enumMember) => enumMember >= 0);
  }

  static dateToTime(source: Date | string): string {
    const date = this.toDate(source);
    if (date !== null) {
      return `${date.getHours()}:${date.getMinutes()}`;
    } else {
      return null;
    }
  }

  static timeToDate(str: Date | string): Date {
    if (str instanceof Date) {
      return str;
    } else if (str) {
      const timeSplit = str.split(':');
      if (
        timeSplit.length > 1 &&
        !isNaN(parseInt(timeSplit[0], 10)) &&
        !isNaN(parseInt(timeSplit[1], 10))
      ) {
        return new Date(
          0,
          0,
          0,
          parseInt(timeSplit[0], 10),
          parseInt(timeSplit[1], 10)
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Add the relevant `0` to the
   * @private
   * @param num
   * @return  prefix if any of the numbers are less than 10
   *          i.e. 5 -> 05
   */
  static addPrefixWhenRelevant(num: number, locals: number = 2) {
    let ret = `${num}`;

    while (ret.length < locals) {
      ret = `0${ret}`;
    }

    return ret;
  }

  static timeFormat(miliseconds: number) {
    const hours = this.addPrefixWhenRelevant(
      Math.floor(miliseconds / HOUR_IN_MS)
    );
    const minutes = this.addPrefixWhenRelevant(
      Math.floor((miliseconds % HOUR_IN_MS) / MINUTE_IN_MS)
    );
    const seconds = this.addPrefixWhenRelevant(
      Math.floor((miliseconds % MINUTE_IN_MS) / SECOND_IN_MS)
    );
    return `${hours}:${minutes}:${seconds}`;
  }

  static toDate(date: Date | string): Date {
    if (date instanceof Date) {
      return date;
    } else {
      const pareseable = Date.parse(date);
      if (!isNaN(pareseable)) {
        return new Date(pareseable);
      } else {
        return null;
      }
    }
  }

  static youtubeEmbed(id: string): string {
    return `https://www.youtube.com/embed/${id}?enablejsapi=1&origin=${encodeURIComponent(
      windowLocation?.origin
    )}`;
  }

  static youtubeWatch(id: string): string {
    return `https://www.youtube.com/watch/${id}`;
  }

  static spotifyEmbed(id: string): string {
    return `https://open.spotify.com/embed/track/${id}`;
  }

  static spotifyTrack(id: string): string {
    return `https://open.spotify.com/track/${id}`;
  }

  static pagerToDomain<T, U>(
    pager: PagerEntity<T, U>,
    classTransformOptions: ClassTransformOptions = null
  ): HttpParams {
    const domain = instanceToPlain(pager, classTransformOptions);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const domainSearchPref = { ...domain, search: '', ...domain?.search };
    const domainSearch = Mappings.escapeNullFields(domainSearchPref);

    return Mappings.createHttpParams(domainSearch);
  }

  static createHttpParams(params: any) {
    return new HttpParams({
      fromObject: params,
      encoder: {
        encodeKey: (key: string): string => encodeURIComponent(key),
        encodeValue: (key: string): string => encodeURIComponent(key),
        decodeKey: (key: string): string => decodeURIComponent(key),
        decodeValue: (key: string): string => decodeURIComponent(key),
      },
    });
  }

  static escapeNullFields<T extends { [key: string | number]: any }>(
    obj: T
  ): Partial<T> {
    return Object.keys(obj).reduce(
      (all, key) =>
        !!obj[key] ||
        obj[key] === 0 ||
        obj[key] === false ||
        (Array.isArray(obj[key]) && obj[key].length !== 0)
          ? Object.assign(all, { [key]: obj[key] })
          : all,
      {} as Partial<T>
    );
  }

  static instagramIdToUrl(id: string): string {
    return `https://www.instagram.com/${id}`;
  }

  static instagramEmbed(url: string): string {
    const regex = getInstagramUrlRegex();
    const id = regex.exec(url)?.groups?.id;

    return `https://www.instagram.com/p/${id}/embed/`;
  }

  static spotifyArtistIdToUrl(id: string): string {
    return `https://open.spotify.com/artist/${id}`;
  }

  static twitchIdToUrl(id: string): string {
    return `https://www.twitch.tv/${id}`;
  }

  static youtubeIdToUrl(id: string): string {
    return `https://www.youtube.com/channel/${id}`;
  }

  static tiktokIdToUrl(id: string): string {
    return `https://www.tiktok.com/@${id}`;
  }

  static socialProfileLink(id: string, name: string, type: SocialType): string {
    switch (type) {
      case SocialType.Instagram:
        return Mappings.instagramIdToUrl(name);
      case SocialType.SpotifyArtist:
        return Mappings.spotifyArtistIdToUrl(id);
      case SocialType.Twitch:
        return Mappings.twitchIdToUrl(name);
      case SocialType.Youtube:
        return Mappings.youtubeIdToUrl(id);
      case SocialType.TikTok:
        // We should switch back to name since TikTok have made changes on 6 April, 2024 that broke the functionality of URLs with Ids
        // return Mappings.tiktokIdToUrl(id);
        return Mappings.tiktokIdToUrl(name);
      default:
        return null;
    }
  }

  static socialProfileIcon(type: SocialType): string {
    return type === SocialType.SpotifyArtist ? 'spotify' : SocialType[type];
  }

  static socialProfileColor(type: SocialType): string {
    return type === SocialType.SpotifyArtist ? 'spotify' : SocialType[type];
  }

  static pagerToUrl<T, U extends { search?: string }>(
    route: string,
    pager: Partial<PagerEntity<T, U>>
  ) {
    if (!pager?.search?.search) {
      pager = Mappings.assign(pager, {
        search: Mappings.assign(pager.search, { search: '' } as Partial<U>),
      });
    }
    const queryParams = Mappings.escapeNullFields(pager?.search || {});
    const ret = {
      url: [
        route,
        'list',
        pager.selectedPageNum || 1,
        pager.limit || '',
        pager.orderBy,
        pager.asc ? true : false,
      ].join('/'),
      queryParams: queryParams,
    };
    return ret;
  }

  static pagerToUrlSimple<T, U extends { search?: string }>(
    route: string,
    pager: Partial<PagerEntity<T, U>>
  ) {
    if (!pager?.search?.search) {
      pager = Mappings.assign(pager, {
        search: Mappings.assign(pager.search, { search: '' } as Partial<U>),
      });
    }
    const queryParams = Mappings.escapeNullFields(pager?.search || {});
    const ret = {
      url: [route, 'list'].join('/'),
      queryParams: queryParams,
    };
    return ret;
  }

  static formatDateError(datePipe: LocalDatePipe) {
    return (date) =>
      date
        ? datePipe.transform(new Date(date).toISOString(), 'mediumDate')
        : '';
  }

  static enumTranslate(
    prefix: string,
    translate: TranslateService,
    enumObj: any
  ): Observable<{ [key: number]: IdNameModel }> {
    return of(Mappings.enumKeys(enumObj)).pipe(
      switchMap((keys) =>
        combineLatest(
          keys.map((id) =>
            merge(of(null), translate.onLangChange).pipe(
              map(() =>
                translate.instant(`${prefix}${enumObj[id].toUpperCase()}`)
              ),
              map((name) => ({ id, name }))
            )
          )
        ).pipe(
          map((idNames) =>
            idNames.reduce(
              (all, idName) => Mappings.assign(all, { [idName.id]: idName }),
              {} as { [key: number]: IdNameModel }
            )
          )
        )
      )
    );
  }

  static dateTimeToDate(date: Date, offset = 0) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + offset
    );
  }

  static getBaseUrl = (source: string) => {
    const url = new URL(source);
    return `${url.origin}${url.pathname}`;
  };

  static formatDateOnly(date: Date | string) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return `${date.getFullYear()}-${Mappings.addPrefixWhenRelevant(
      date.getMonth() + 1
    )}-${Mappings.addPrefixWhenRelevant(date.getDate())}`;
  }
  static paramToNumber(param: string) {
    const parsedNumber = parseInt(param, 10);
    if (isNaN(parsedNumber)) {
      return '';
    }
    return parsedNumber;
  }
  static paramToBoolean(
    param: string | boolean,
    defaultValue: boolean | string
  ) {
    return param
      ? JSON.parse(param as string)
      : param === false
      ? false
      : defaultValue;
  }
}
