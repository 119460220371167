import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, instanceToPlain, plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { MangopayRequestUrlModel } from '../../mangopay/models/mangopay-request-url.model';
import { MangopayUrlModel } from '../../mangopay/models/mangopay-url.model';
import { Mappings } from '../../tools/mapping/mappings';
import { CampaignV2CreateModel } from '../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../models/campaign-v2-edit.model';
import { CampaignV2SearchModel } from '../models/campaign-v2-search.model';
import { CampaignV2SumModel } from '../models/campaign-v2-sum.model';
import { CampaignV2TargetModel } from '../models/campaign-v2-target.model';
import { CampaignV2SummaryPreviewParamsModel } from '../models/campaign-v2-summary-preview.params.model';
import { CampaignV2ServiceAbstract } from './campaign-v2.service.abstract';

@Injectable()
export class CampaignV2Service extends CampaignV2ServiceAbstract {
  private readonly campaignV2Url = `${this.apiUrl}campaign-v2`;
  private readonly tartgetUrl = `${this.campaignV2Url}/target`;
  private readonly payoutUrl = `${this.campaignV2Url}/payin`;
  private readonly payoutFromWalletUrl = `${this.campaignV2Url}/payin/wallet`;
  private readonly summaryPreviewUrl = `${this.campaignV2Url}/summary-preview`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string,
    @Inject(ENVIRONMENT)
    private environment: any
  ) {
    super();
  }

  getPage(
    pager: PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>
  ): Promise<PagerResponseContainer<CampaignV2DetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.campaignV2Url, { params } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(CampaignV2DetailsModel), resp)))
      .toPromise();
  }

  get(id: number): Promise<ResponseContainer<CampaignV2DetailsModel>> {
    return this.http
      .get(`${this.campaignV2Url}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CampaignV2DetailsModel), resp)))
      .toPromise();
  }

  create(model: CampaignV2CreateModel): Promise<ResponseContainer<CampaignV2DetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.campaignV2Url, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CampaignV2DetailsModel), resp)))
      .toPromise();
  }

  setTarget(model: CampaignV2TargetModel, id: number): Promise<ResponseContainer<CampaignV2DetailsModel>> {
    const domain = instanceToPlain(model);
    return this.http
      .put(`${this.tartgetUrl}/${id}`, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CampaignV2DetailsModel), resp)))
      .toPromise();
  }

  delete(id: number): Promise<ResponseContainer<unknown>> {
    return this.http
      .delete(`${this.campaignV2Url}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)))
      .toPromise();
  }

  edit(model: CampaignV2EditModel, id: number): Promise<ResponseContainer<unknown>> {
    const domain = classToPlain(model);
    return this.http
      .put(`${this.campaignV2Url}/${id}`, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)))
      .toPromise();
  }

  getPayoutUrl(model: MangopayRequestUrlModel, id: number): Promise<ResponseContainer<MangopayUrlModel>> {
    let domain = classToPlain(model);
    if (this.environment.hybrid) {
      domain = { ...domain, platform: 3 };
    }
    return this.http
      .post(`${this.payoutUrl}/${id}`, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(MangopayUrlModel), resp)))
      .toPromise();
  }

  payFromWallet(model: MangopayRequestUrlModel, id: number) {
    const domain = classToPlain(model);
    let params: HttpParams;
    if (this.environment.hybrid) {
      params = new HttpParams({ fromObject: { platform: 3 } });
    }
    return this.http
      .post(`${this.payoutFromWalletUrl}/${id}`, domain, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(MangopayUrlModel), resp)));
  }

  getSummaryPreview(model: CampaignV2SummaryPreviewParamsModel): Promise<ResponseContainer<CampaignV2SumModel>> {
    const body = instanceToPlain(model);
    return this.http
      .post(this.summaryPreviewUrl, body)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CampaignV2SumModel), resp)))
      .toPromise();
  }
}
