import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { WalletDetailsModel } from '../models/wallet-details.model';
import { WalletSearchModel } from '../models/wallet-search.model';
import { WalletType } from '../models/wallet-type';
import { walletAction } from './wallet.action';

export type WalletState = IHasPagerEntities<WalletDetailsModel, WalletSearchModel>;
const initialPager = new PagerEntity<WalletDetailsModel, WalletSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: true,
  orderBy: 'id',
  search: new WalletSearchModel({
    types: [WalletType.payin, WalletType.payout],
  }),
});

const walletInitialState: WalletState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const walletReducer = createPagerReducer({
  details: WalletDetailsModel,
  filter: WalletSearchModel,
  initialState: walletInitialState,
  actions: walletAction,
  source: 'Wallet',
});
