import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { BankAccountServiceAbstract } from '../services/bank-account.service.abstract';
import { BankAccountActions } from '../store/actions';
import { BankAccountSelector } from '../store/selectors/bank-account.selector';

@Injectable({
  providedIn: 'root',
})
export class BankAccountMyGuard implements CanActivate {
  constructor(private store: Store, private bankAccountService: BankAccountServiceAbstract, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const required = route.data.bankAccountRequired ?? true;
    return this.hasBankAccount(required).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => exist || this.router.navigate(['404']))
    );
  }

  hasBankAccountInStore(): Observable<boolean> {
    return this.store.select(BankAccountSelector.get).pipe(
      map((comapny) => !!comapny),
      take(1)
    );
  }

  hasBankAccountInApi(): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => this.bankAccountService.get()),
      map((bankAccount) =>
        bankAccount.values ? new BankAccountActions.SingleLoadeCompleted(bankAccount.values) : null
      ),
      tap((action: BankAccountActions.SingleLoadeCompleted) => (action ? this.store.dispatch(action) : null)),
      map(() => true),
      catchError(() => of(false))
    );
  }
  hasBankAccount(required: boolean): Observable<boolean> {
    return this.hasBankAccountInStore().pipe(
      switchMap((inStore) => {
        if (inStore) {
          return of(inStore);
        }
        return this.hasBankAccountInApi();
      }),
      map((exist) => !required || exist)
    );
  }
}
