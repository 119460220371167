import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { TicketActions } from '../../store/actions';
import { TicketSelector } from '../../store/selectors';
import { TicketServiceAbstract } from '../providers/ticket.service.abstract';

@Injectable()
export class TicketExistGuard implements CanActivate {
  constructor(private store: Store, private ticketService: TicketServiceAbstract, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasTicket(parseInt(route.params.id, 10)).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => (exist || null) ?? this.router.navigate(['404']))
    );
  }

  hasTicketInStore(id: number): Observable<boolean> {
    return this.store.select(TicketSelector.getItem(id)).pipe(
      map((ent) => !!ent),
      take(1)
    );
  }

  hasTicketInApi(id: number): Observable<boolean> {
    return of(this.ticketService.get(id)).pipe(
      flatMap((promise) => promise),
      map((ticket) => new TicketActions.SingleLoadeCompleted(ticket.values)),
      tap((action: TicketActions.SingleLoadeCompleted) => this.store.dispatch(action)),
      map((action) => !!action?.payload),
      catchError(() => of(false))
    );
  }
  hasTicket(id: number): Observable<boolean> {
    return this.hasTicketInStore(id).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return of(inStore);
        }
        return this.hasTicketInApi(id);
      })
    );
  }
}
