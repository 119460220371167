import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SpotifyTrackServiceAbstract } from '../services/spotify-track.service.abstract';
import { SpotifyTrackActions } from '../store/actions';
import { SpotifyTrackSelector } from '../store/selectors/spotify-track.selector';

@Injectable({
  providedIn: 'root',
})
export class SpotifyTrackExistGuard implements CanActivate {
  constructor(private store: Store, private spotifyTrackService: SpotifyTrackServiceAbstract, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasSpotifyTrack(route.params.id).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => (exist || null) ?? this.router.navigate(['404']))
    );
  }

  hasSpotifyTrackInStore(id: string): Observable<boolean> {
    return this.store.select(SpotifyTrackSelector.getItem(id)).pipe(
      map((ent) => !!ent),
      take(1)
    );
  }

  hasSpotifyTrackInApi(id: string): Observable<boolean> {
    return defer(() => this.spotifyTrackService.get(id)).pipe(
      map((resp) => resp.values),
      tap((spotifyTrack) => {
        if (spotifyTrack) {
          this.store.dispatch(new SpotifyTrackActions.SingleLoadeCompleted(spotifyTrack));
        }
      }),
      map((payload) => !!payload),
      catchError(() => of(false))
    );
  }
  hasSpotifyTrack(id: string): Observable<boolean> {
    return this.hasSpotifyTrackInStore(id).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return of(inStore);
        }
        return this.hasSpotifyTrackInApi(id);
      })
    );
  }
}
