<ion-radio-group value="en-us" [value]="selectedLanguage$ | async" (ionChange)="onComplete($event)">
  <ion-item>
    <ui-flag slot="start" name="de"></ui-flag>
    <ion-label>
      <h4 class="!mb-0">Deutsch</h4>
      <p class="!text-xs">{{ 'LANGUAGE_DE' | translate }}</p>
    </ion-label>
    <ion-radio slot="end" [value]="SettingsLanguage.de"></ion-radio>
  </ion-item>
  <ion-item>
    <ui-flag slot="start" name="us"></ui-flag>
    <ion-label>
      <h4 class="!mb-0">English, US</h4>
      <p class="!text-xs">{{ 'LANGUAGE_EN-US' | translate }}</p>
    </ion-label>
    <ion-radio slot="end" [value]="SettingsLanguage.enUS"></ion-radio>
  </ion-item>
</ion-radio-group>
