import { Action } from '@ngrx/store';

import { GenreModel } from '../../models/genre.model';
import * as GenreActionTypes from './genre.actions.type';

export class LoadFromDb implements Action {
  readonly type = GenreActionTypes.LOAD_FROM_DB;
}
export class LoadFromDbCompleted implements Action {
  readonly type = GenreActionTypes.LOAD_FROM_DB_COMPLETED;
  constructor(public payload: GenreModel[]) {}
}
export class SaveToDb implements Action {
  readonly type = GenreActionTypes.SAVE_TO_DB;
}
export class SaveToDbCompleted implements Action {
  readonly type = GenreActionTypes.SAVE_TO_DB_COMPLETED;
}
export class Load implements Action {
  readonly type = GenreActionTypes.LOAD;
}
export class LoadCompleted implements Action {
  readonly type = GenreActionTypes.LOAD_COMPLETED;
  constructor(public payload: GenreModel[]) {}
}
export class ResetState implements Action {
  readonly type = GenreActionTypes.RESET_STATE;
}

export type All = LoadFromDbCompleted | LoadCompleted | ResetState;
