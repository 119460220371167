import { Injectable } from '@angular/core';

import { Observable, defer, interval, of, zip } from 'rxjs';
import {
  bufferToggle,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { CampaignV2ExistGuard } from '../../../campaign-v2/guards/campaign-v2-exits.guard';
import { CampaignV2SearchModel } from '../../../campaign-v2/models/campaign-v2-search.model';
import { CampaignV2Actions } from '../../../campaign-v2/store/actions';
import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { hasSongInStore } from '../../../song/guards/song-exits.guard';
import { SongSearchModel } from '../../../song/models/song-search.model';
import { SongActions } from '../../../song/store';
import { TicketSearchModel } from '../../../ticket/models/ticket-search.model';
import { TicketExistGuard } from '../../../ticket/services/guards/ticket-exits.guard';
import { TicketActions } from '../../../ticket/store/actions';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { hasUserInStore } from '../../../user/guards/user-exist.guard';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { UserActions } from '../../../user/store/actions';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { DealServiceAbstract } from '../../providers/deal.service.abstract';
import { DealActionType, DealActions } from '../actions';
import { DealSelector } from '../selectors';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class DealEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.SELECT_PAGE, DealActionType.LOAD_PAGE),
      mergeMap((action: DealActions.SelectPage) =>
        this.store.select(DealSelector.getPager(action.pagerId)).pipe(
          distinctUntilChanged((prevPager, currPager) =>
            this.propEquals(prevPager, currPager, 'asc', 'limit', 'orderBy', 'search')
          ),
          map((pager) =>
            Mappings.assign(pager, {
              selectedPageNum: action.selectedPageNum ?? pager.selectedPageNum + 1,
            })
          ),
          switchMap((pager) =>
            defer(() => this.dealService.getPage(pager)).pipe(
              map((response) => ({
                response,
                page: pager.selectedPageNum,
                action,
                pager,
              }))
            )
          ),
          takeUntil(this.instanceLoaded(action.pagerId))
        )
      ),
      map(
        ({ response, page, action }) =>
          new DealActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId,
            action?.wipe
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: DealActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new DealActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.SEARCHING),
      mergeMap((action: DealActions.Searching) => zip(of(action), this.getDeals(action.pagerId))),
      map(([action, resp]) => new DealActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.LOADALL),
      mergeMap((action: DealActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: DealDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.dealService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new DealActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new DealActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.SINGLE_LOAD),
      mergeMap(async (action: DealActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.dealService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new DealActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealActionType.RELOAD_ENTITY),
      mergeMap(async (action: DealActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.dealService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new DealActions.SingleLoadeCompleted(resp.values))
    )
  );

  resolveSong$ = createEffect(
    () =>
      this.resolveEntities('songId', SongActions.ChangePager, SongSearchModel, SongActions.LoadAll, (id) =>
        hasSongInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveCampaign$ = createEffect(
    () =>
      this.resolveEntities(
        'campaignId',
        CampaignV2Actions.ChangePager,
        CampaignV2SearchModel,
        CampaignV2Actions.LoadAll,
        (id) => this.campaignV2ExistGuard.hasCampaignV2InStore(id).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveTicket$ = createEffect(
    () =>
      this.resolveEntities('ticketId', TicketActions.ChangePager, TicketSearchModel, TicketActions.LoadAll, (id) =>
        this.ticketExistGuard.hasTicketInStore(id).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveArtist$ = createEffect(
    () =>
      this.resolveEntities('artistId', UserActions.ChangePager, UserSearchModel, UserActions.LoadAll, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveCreator$ = createEffect(
    () =>
      this.resolveEntities('creatorId', UserActions.ChangePager, UserSearchModel, UserActions.LoadAll, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolvehistoryUsers$ = createEffect(
    () =>
      this.resolveEntities('historyUserIds', UserActions.ChangePager, UserSearchModel, UserActions.LoadAll, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  getPager = this.store.select(DealSelector.getPagers).pipe(take(1));
  getIds = this.store.select(DealSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private dealService: DealServiceAbstract,
    private store: Store,
    private campaignV2ExistGuard: CampaignV2ExistGuard,
    private ticketExistGuard: TicketExistGuard
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<DealDetailsModel, DealSearchModel>> {
    return this.store.select(DealSelector.getPager(instance)).pipe(first());
  }

  getSearchModel(instance: string): Observable<DealSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getDeals(instance: string): Observable<PagerResponse<DealDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      first(),
      mergeMap((pager) => this.dealService.getPage(pager)),
      map((resp) => resp.values)
    );
  }

  getLoadComplete() {
    return this.actions$.pipe(
      ofType(DealActionType.SINGLE_LOAD_COMPLETED, DealActionType.LOADALL_COMPLETED, DealActionType.LOAD_PAGE_COMPLETED)
    );
  }

  resolveEntities<T, Z>(
    key: keyof DealDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (action: DealActions.SingleLoadeCompleted | DealActions.LoadAllCompleted | DealActions.LoadPageCompleted) =>
          Array.isArray(action.payload) ? action.payload : [action.payload]
      ),
      bufferToggle(this.getLoadComplete(), () => interval(0)),
      filter((e) => e.length > 0),
      mergeMap((requests) =>
        zip(
          ...requests
            .flatMap((request) => request[key] as number[] | number)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }

  propEquals = <T>(obj1: T, obj2: T, ...keys: (keyof T)[]): boolean => {
    return !keys.find((key) => obj1[key] !== obj2[key]);
  };

  instanceLoaded = (pagerId: string) =>
    this.actions$.pipe(
      ofType(DealActionType.LOAD_PAGE_COMPLETED),
      filter((compAction: DealActions.LoadPageCompleted) => compAction.pagerId === pagerId),
      take(1)
    );
}
