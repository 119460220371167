import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, tap } from 'rxjs';

import { getTiktokSoundUrlRegex } from '@songpush/validators/tools/tiktok-sound-url-regex';

import { SpotifyTrackExistGuard } from '../../spotify-track/guards/spotify-track-exits.guard';
import { TiktokSongExistGuard } from '../../tiktok/guards/tiktok-song-exits.guard';

@Injectable({
  providedIn: 'root',
})
export class SongInitGuard implements CanActivate {
  constructor(
    private spotifyTrackExistGuard: SpotifyTrackExistGuard,
    private tiktokSongExistGuard: TiktokSongExistGuard,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.hasCorrectInitId(decodeURIComponent(route.params.id)).pipe(
      tap((exist) => (exist || null) ?? this.router.navigate(['/', '404']))
    );
  }

  hasCorrectInitId(id: string) {
    const isTiktokUrl = getTiktokSoundUrlRegex();
    if (id.match(isTiktokUrl)) {
      return this.tiktokSongExistGuard.hasTiktokSong(id);
    }
    return this.spotifyTrackExistGuard.hasSpotifyTrack(id);
  }
}
