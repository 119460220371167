import { NgModule, makeEnvironmentProviders } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideState } from '@ngrx/store';

import { managerDealReducer } from '../store/manager-deal.reducer';
import { ManagerDealEffects } from '../store/manager-deal.effects';
import { ManagerDealServiceAbstract } from '../providers/manager-deal.service.abstract';
import { ManagerDealService } from '../providers/manager-deal.service';

export const provideManagerDealState = () =>
  makeEnvironmentProviders([
    provideState('managerDeal', managerDealReducer),
    provideEffects([ManagerDealEffects]),
    { provide: ManagerDealServiceAbstract, useClass: ManagerDealService },
  ]);

@NgModule({
  imports: [EffectsModule.forFeature([ManagerDealEffects]), StoreModule.forFeature('managerDeal', managerDealReducer)],
  providers: [{ provide: ManagerDealServiceAbstract, useClass: ManagerDealService }],
})
export class ManagerDealStateModule {}
