import { Mappings } from '../../../tools/mapping/mappings';
import { LanguageModel } from '../../models/language.model';
import { LanguageActions, LanguageActionType } from '../actions';

export class LanguageState {
  entities: { [key: number]: LanguageModel };
  ids: number[];
  loaded: boolean;
}

const initialState: LanguageState = {
  entities: {},
  ids: [],
  loaded: false,
};

export const languageReducer = (state: LanguageState = initialState, action: LanguageActions.All): LanguageState => {
  switch (action.type) {
    case LanguageActionType.LOAD_FROM_DB_COMPLETED: {
      if (!state.loaded) {
        return Mappings.assign(state, addPayloadToState(state, action.payload));
      } else {
        return state;
      }
    }
    case LanguageActionType.LOAD_COMPLETED: {
      return Mappings.assign(state, addPayloadToState(state, action.payload));
    }
    case LanguageActionType.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

const addPayloadToState = (state: LanguageState, payload: LanguageModel[]) => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((language) => language.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
  loaded: true,
});
