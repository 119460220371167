import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { IHasId } from '../../tools/reducer-helper';

export class IdNameModel implements IHasId {
  @Expose()
  id: Identifier;

  @Expose()
  name: string;
}
