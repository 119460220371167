import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import * as SocialBlockActionType from './social-block.actions.type';

export class ReloadEntity implements Action {
  readonly type = SocialBlockActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = SocialBlockActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = SocialBlockActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: SocialBlockDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}

export class SelectPage implements Action {
  readonly type = SocialBlockActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = SocialBlockActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = SocialBlockActionType.SEARCH;
  constructor(
    public payload: SocialBlockSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = SocialBlockActionType.SEARCHING;
  constructor(
    public payload: SocialBlockSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = SocialBlockActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = SocialBlockActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: SocialBlockDetailsModel) {}
}
export class Select implements Action {
  readonly type = SocialBlockActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = SocialBlockActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = SocialBlockActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = SocialBlockActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = SocialBlockActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>,
    public payload: SocialBlockDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = SocialBlockActionType.RESETSTATE;
}

export class ReorderLocal implements Action {
  readonly type = SocialBlockActionType.REORDER_LOCAL;
  constructor(public from: number, public to: number, public slaveScheduleId: number, public pagerId: string) {}
}
export class ReorderRemote implements Action {
  readonly type = SocialBlockActionType.REORDER_REMOTE;
  constructor(public pagerId: string, public slaveScheduleId: number) {}
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState
  | ReorderLocal;
