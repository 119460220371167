import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, map, mergeMap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';


import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { StatEditModel } from '../models/stat-edit.model';
import { statAction } from './stat.action';
import { statSelector } from './stat.selector';
import { Mappings } from '../../tools/mapping/mappings';
import { StatDetailsModel } from '../models/stat-details.model';
import { StatSearchModel } from '../models/stat-search.model';
import { StatCreateModel } from '../models/stat-create.model';
import { SessionActions } from '../../session/store/actions/session.actions';
import { fulfillSource } from '../tools/fulfill';
import { StatServiceAbstract } from '../providers/stat.service.abstract';


export class StatEffects extends PagerEffects<
  'Stat',
  StatDetailsModel,
  StatSearchModel,
  StatEditModel,
  StatCreateModel
> {
  router = inject(Router);

  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => statAction.resetState())
    )
  );

  loadAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadAll),
      mergeMap((action) => this.getPager(action.pagerId).pipe(map((pager) => ({ pager, action })))),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: StatDetailsModel[] = [];

        while (pager.maxCount === null || payload.length < pager.maxCount) {
          const resp = await this.pagerService.getPage(pager).toPromise();
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
          });
        }
        payload = fulfillSource(
          pager.search.resolution,
          payload,
          new Date(pager.search.toDate),
          pager.maxCount,
          pager.search.type
        );
        return this.actions.loadAllCompleted({
          pager: Mappings.assign(pager, { pages: { [1]: payload.map(({ id }) => id) } }),
          payload,
          pagerId: action.pagerId,
        });
      })
    )
  );

  constructor() {
    super(statAction, statSelector, inject(StatServiceAbstract));
  }
}
