<!-- We use the header here to proper page transitioning on hybrid platform -->
<ion-header
  mode="ios"
  [translucent]="true"
  collapse="fade"
  *ngIf="header !== 'none'"
  [class.lg:hidden]="header === 'mobile-only'"
>
  <ui-header [logo]="false" [back]="canGoBack">
    <ng-content header-buttons-start select="[header-buttons-start]"></ng-content>
    <ng-content header-buttons-end select="[header-buttons-end]"></ng-content>
  </ui-header>
  <ng-content select="[header]"></ng-content>
</ion-header>

<!-- Keep header and content here for proper page transitioning -->
<ion-content [fullscreen]="true">
  <div
    fixed
    class="hidden lg:flex flex-col justify-between lg:fixed lg:top-0 lg:left-0 lg:w-[40%] lg:max-w-[42rem] lg:h-full lg:z-50"
  >
    <ng-content select="[start]"></ng-content>
  </div>
  <div class="lg:pl-[40%] h-full w-full">
    <div class="flex items-center justify-center lg:justify-start w-full p-4 lg:px-20 lg:py-16 min-h-full">
      <ng-content select="[end]"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>
</ion-content>

<ng-content select="[footer]"></ng-content>
