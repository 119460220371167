import { Action } from '@ngrx/store';

import { ContentCategoryModel } from '../../models/content-category.model';
import * as ContentCategoryActionTypes from './content-category.actions.type';

export class LoadFromDb implements Action {
  readonly type = ContentCategoryActionTypes.LOAD_FROM_DB;
}
export class LoadFromDbCompleted implements Action {
  readonly type = ContentCategoryActionTypes.LOAD_FROM_DB_COMPLETED;
  constructor(public payload: ContentCategoryModel[]) {}
}
export class SaveToDb implements Action {
  readonly type = ContentCategoryActionTypes.SAVE_TO_DB;
}
export class SaveToDbCompleted implements Action {
  readonly type = ContentCategoryActionTypes.SAVE_TO_DB_COMPLETED;
}
export class Load implements Action {
  readonly type = ContentCategoryActionTypes.LOAD;
}
export class LoadCompleted implements Action {
  readonly type = ContentCategoryActionTypes.LOAD_COMPLETED;
  constructor(public payload: ContentCategoryModel[]) {}
}

export type All = LoadFromDbCompleted | LoadCompleted;
