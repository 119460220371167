import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { DealCreateModel } from '../models/deal-create.model';
import { DealDetailsModel } from '../models/deal-details.model';
import { DealEditModel } from '../models/deal-edit.model';
import { DealSearchModel } from '../models/deal-search.model';

export abstract class DealServiceAbstract {
  abstract getPage(
    pager: PagerEntity<DealDetailsModel, DealSearchModel>
  ): Promise<PagerResponseContainer<DealDetailsModel>>;
  abstract get(id: Identifier): Promise<ResponseContainer<DealDetailsModel>>;
  abstract create(model: DealCreateModel): Promise<ResponseContainer<DealDetailsModel>>;
  abstract delete(id: Identifier): Promise<ResponseContainer<DealDetailsModel>>;
  abstract edit(model: DealEditModel, id: Identifier): Promise<ResponseContainer<DealDetailsModel>>;
}
