import { Expose, Transform } from 'class-transformer';

export class SocialStatModel {
  @Expose()
  avgClicks: number;
  @Expose()
  avgSpViewers: number;
  @Expose()
  avgViewers: number;
  @Expose()
  maxClicks: number;
  @Expose()
  maxSpViewers: number;
  @Expose()
  maxViewers: number;
  @Expose()
  @Transform((opts) => opts?.obj?.avgClicks / opts?.obj?.avgViewers)
  conversion: number;
  @Expose()
  viewScore: number;
  @Expose({ name: 'updateAble' })
  updateable: boolean;
  @Expose()
  followers: number;
}
