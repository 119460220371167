import { createSelector } from '@ngrx/store';

import { BaseStaticSelector } from '../../../common/store/selectors';
import { GenreModel } from '../../../genre/models/genre.model';
import { Mappings } from '../../../tools/mapping/mappings';
import { SubGenreModel } from '../../models/sub-genre.model';
import { SubGenreState } from '../reducers/sub-genre.reducer';

export type SubGenreStatePart = { subGenre: SubGenreState };

class SubGenreSelectors extends BaseStaticSelector<SubGenreState, SubGenreModel> {
  groupByGenre = createSelector(this.getAll, (genres) =>
    genres.reduce(
      (group, genre) =>
        Mappings.assign(group, {
          [genre.genreId]: [...(group[genre.genreId] || []), genre],
        }),
      {} as { [key: number]: GenreModel[] }
    )
  );

  constructor() {
    super((state: SubGenreStatePart): SubGenreState => state.subGenre);
  }
}

export const SubGenreSelector = new SubGenreSelectors();
