import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { SocialBlockActions, SocialBlockActionType } from '../actions';

export class SocialBlockState {
  entities: { [key: number]: SocialBlockDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>;
}
const initialState: SocialBlockState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 50,
    asc: false,
    orderBy: 'id',
    search: new SocialBlockSearchModel({
      search: '',
    }),
  }),
};

export const socialBlockReducer = (
  state: SocialBlockState = initialState,
  action: SocialBlockActions.All
): SocialBlockState => {
  switch (action.type) {
    case SocialBlockActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case SocialBlockActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case SocialBlockActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case SocialBlockActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case SocialBlockActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case SocialBlockActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case SocialBlockActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case SocialBlockActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case SocialBlockActionType.RESETSTATE:
      return initialState;

    // case SocialBlockActionType.REORDER_LOCAL:
    //   return ReducerTools.reorderLocal(action, state, state.initialPager);

    default:
      return state;
  }
};
