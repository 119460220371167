import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { Mappings } from '../../../tools/mapping/mappings';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { CountryServiceAbstract } from './country.service.abstract';

@Injectable()
export class CountryService extends CountryServiceAbstract {
  private countryUrl = this.apiUrl + 'search/country';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  getPage(pager: PagerEntity<CountryModel, CountrySearchModel>): Promise<PagerResponseContainer<CountryModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.countryUrl, {
        params,
      } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(CountryModel), resp)))
      .toPromise();
  }
  get(id: number): Promise<ResponseContainer<CountryModel>> {
    return this.http
      .get(`${this.countryUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(CountryModel), resp)))
      .toPromise();
  }
}
