import { Injectable } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { debounceTime, filter, flatMap, map, mergeMap, take } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { SpotifyArtistServiceAbstract } from '../../services/spotify-artist.service.abstract';
import { SpotifyArtistActionType, SpotifyArtistActions } from '../actions';
import { SpotifyArtistSelector } from '../selectors/spotify-artist.selector';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class SpotifyArtistEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyArtistActionType.SELECT_PAGE),
      flatMap(async (action: SpotifyArtistActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      flatMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.spotifyArtistService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new SpotifyArtistActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyArtistActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: SpotifyArtistActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new SpotifyArtistActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyArtistActionType.SEARCHING),
      mergeMap((action: SpotifyArtistActions.Searching) => zip(of(action), this.getSpotifyArtists(action.pagerId))),
      map(
        ([action, resp]) => new SpotifyArtistActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId)
      )
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyArtistActionType.LOADALL),
      mergeMap((action: SpotifyArtistActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: SpotifyArtistDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.spotifyArtistService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new SpotifyArtistActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new SpotifyArtistActions.ResetState())
    )
  );

  getPager = this.store.select(SpotifyArtistSelector.getPagers).pipe(take(1));

  constructor(
    private actions$: Actions,
    private spotifyArtistService: SpotifyArtistServiceAbstract,
    private store: Store
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>> {
    return this.store.select(SpotifyArtistSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<SpotifyArtistSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getSpotifyArtists(instance: string): Observable<PagerResponse<SpotifyArtistDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      mergeMap((pager) => this.spotifyArtistService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
}
