import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import { bufferToggle, debounceTime, filter, groupBy, map, mergeAll, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { CountryActions } from '../../../country/store/actions';
import { FormValidatorServiceAbstract } from '../../../form/services/form-validator/form-validator.service.abstract';
import { SessionActions } from '../../../session/store/actions';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { CompanyServiceAbstract } from '../../services/company.service.abstract';
import { CompanyActionType, CompanyActions } from '../actions';
import { CompanySelector } from '../selectors/company.selector';
import { filterEmpty } from '../../../tools/map/filter-empty';


@Injectable()
export class CompanyEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SELECT_PAGE),
      mergeMap(async (action: CompanyActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      mergeMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.companyService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new CompanyActions.LoadPageCompleted(page, response.values.maxCount, response.values.results, action.pagerId)
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: CompanyActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new CompanyActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SEARCHING),
      mergeMap((action: CompanyActions.Searching) => zip(of(action), this.getCompanys(action.pagerId))),
      map(([action, resp]) => new CompanyActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.DELETE),
      mergeMap(async (action: CompanyActions.Delete) => ({
        action,
        response: await this.companyService.delete(action.id),
      })),
      tap(({ response }) =>
        response.status.globalMessage ? this.formValidator.showToast(response.status.globalMessage) : null
      ),
      filter(({ action, response }) => response?.status?.success),
      map(({ action }) => new CompanyActions.DeleteCompleted(action.id))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.LOADALL),
      mergeMap((action: CompanyActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: CompanyDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.companyService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new CompanyActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => new CompanyActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SINGLE_LOAD),
      mergeMap(async (action: CompanyActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.companyService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CompanyActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.RELOAD_ENTITY),
      mergeMap(async (action: CompanyActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.companyService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CompanyActions.SingleLoadeCompleted(resp.values))
    )
  );

  autoTriggerReorderRemote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.REORDER_LOCAL),
      map((action: CompanyActions.ReorderLocal) => action),
      groupBy((action) => action.pagerId),
      map((group) => group.pipe(debounceTime(3000))),
      mergeAll(),
      map((action) => new CompanyActions.ReorderRemote(action.pagerId, action.slaveScheduleId))
    )
  );

  mergeCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SINGLE_LOAD_COMPLETED),
      map((action: CompanyActions.SingleLoadeCompleted) => action.payload.country),
      filterEmpty(),
      map((country) => new CountryActions.SingleLoadeCompleted(country))
    )
  );

  mergePhoneCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.SINGLE_LOAD_COMPLETED),
      map((action: CompanyActions.SingleLoadeCompleted) => action.payload.phoneCountry),
      filterEmpty(),
      map((country) => new CountryActions.SingleLoadeCompleted(country))
    )
  );

  getPager = this.store.select(CompanySelector.getPagers).pipe(take(1));
  getIds = this.store.select(CompanySelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private companyService: CompanyServiceAbstract,
    private store: Store,
    private formValidator: FormValidatorServiceAbstract
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<CompanyDetailsModel, CompanySearchModel>> {
    return this.store.select(CompanySelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<CompanySearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getCompanys(instance: string): Observable<PagerResponse<CompanyDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.companyService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        CompanyActionType.SINGLE_LOAD_COMPLETED,
        CompanyActionType.LOADALL_COMPLETED,
        CompanyActionType.LOAD_PAGE_COMPLETED
      )
    );
  }

  resolveEntities<T, Z>(
    key: keyof CompanyDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action:
            | CompanyActions.SingleLoadeCompleted
            | CompanyActions.LoadAllCompleted
            | CompanyActions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(10)),
      filter((e) => e.length > 0),
      mergeMap((companys) =>
        zip(
          ...companys
            .map((company) => company[key] as number)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }
}
