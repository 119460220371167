import { createSelector } from '@ngrx/store';


import { Scope } from '../../models/scope';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { Mappings } from '../../../tools/mapping/mappings';

export class BaseMultiPagerSelector<T extends IHasPagerEntities<U, Z>, U, Z> {
  getEntities = createSelector(this.getState, (state) => state.entities);
  getPagers = createSelector(this.getState, (state) => state.pagers);
  getIds = createSelector(this.getState, (state) => state.ids);
  getSelectedId = createSelector(this.getState, (state) => state.selectedId);
  getPagerIds = createSelector(this.getState, (state) => state.pagerIds);
  getInitialPager = createSelector(this.getState, (state) => state.initialPager);

  getSelected = createSelector(this.getEntities, this.getSelectedId, (ent, id) => ent[id]);

  getList = createSelector(this.getEntities, this.getIds, (ents, ids) => ids.map((id) => ents[id]));

  constructor(public getState: (state: unknown) => T) {}

  getItem = (id: number | string) => createSelector(this.getEntities, (ents) => ents[id]);

  getMaxCount = (instance = 'default') => createSelector(this.getPager(instance), (pager) => pager.maxCount);
  getSelectedPageNum = (instance = 'default') =>
    createSelector(this.getPager(instance), (pager) => pager.selectedPageNum);

  getMaxPageNum = (instance: string = 'default') =>
    createSelector(this.getPager(instance), ({ maxCount, limit }) => Math.ceil(maxCount / limit));
  getSelectedPageInstance = (instance: string = 'default') =>
    createSelector(this.getEntities, this.getPager(instance), (ent, pager) =>
      pager.pages[pager.selectedPageNum]?.map((id) => ent[id])
    );

  getAllPagerIdInstace = (instance: string = 'default') =>
    createSelector(
      this.getPagers,
      this.getInitialPager,
      (pager, iPager) =>
        Object.keys((pager[instance] || iPager).pages)
          .map((id) => parseInt(id, 10))
          .reduce((all, id) => [...all, ...pager[instance].pages[id]], [] as number[]) as number[]
    );

  getAllInstance = (instance: string = 'default') =>
    createSelector(this.getAllPagerIdInstace(instance), this.getEntities, (ids, ents) => ids.map((id) => ents[id]));

  getPager = (instance: string = 'default') =>
    createSelector(this.getPagers, this.getInitialPager, (pager, iPager) => pager[instance] || iPager);

  getScope = (instance: string = 'default') =>
    createSelector(
      this.getPager(instance),
      this.getAllPagerIdInstace(instance),
      this.getEntities,
      (pager, ids, ents) =>
        ({
          instance,
          pager,
          all: ids.map((id) => ents[id]),
          ids,
        } as Scope<U, Z>)
    );

  getPagerWithId = (instance: string = 'default') =>
    createSelector(this.getPager(instance), (pager) => Mappings.assign(pager, { id: instance }));

  getSelectedPageIds = (instance: string = 'default') =>
    createSelector(this.getPager(instance), (pager) => pager?.pages?.[pager.selectedPageNum] || []);

  getSelectedPageItems = (instance: string = 'default') =>
    createSelector(this.getSelectedPageIds(instance), this.getEntities, (ids, ents) => ids.map((id) => ents[id]));

  getScopePage = (instance: string = 'default') =>
    createSelector(
      this.getPager(instance),
      this.getSelectedPageIds(instance),
      this.getSelectedPageItems(instance),
      (pager, ids, all) =>
        ({
          instance,
          pager,
          ids,
          all,
        } as Scope<U, Z>)
    );

  getAt = (instance: string = 'default', index: number) =>
    createSelector(this.getAllPagerIdInstace(instance), this.getEntities, (ids, entities) => entities[ids[index]]);

  getIndex = (id: number | string, instance: string) =>
    createSelector(this.getAllPagerIdInstace(instance), (ids) => {
      const selectedIndex = ids.indexOf(id as number);
      return selectedIndex;
    });

  getNextId = (id: number | string, instance: string = 'default') =>
    createSelector(this.getIndex(id, instance), this.getAllPagerIdInstace(instance), (index, pagerIds) => {
      return pagerIds[index + 1];
    });

  getPreviousId = (id: number | string, instance: string = 'default') =>
    createSelector(
      this.getIndex(id, instance),
      this.getAllPagerIdInstace(instance),
      this.getEntities,
      (index, pagerIds) => {
        return pagerIds[index - 1];
      }
    );
}
