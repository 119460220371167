import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import * as SpotifyArtistActionType from './spotify-artist.actions.type';

export class LoadPage implements Action {
  readonly type = SpotifyArtistActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = SpotifyArtistActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: SpotifyArtistDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}

export class SelectPage implements Action {
  readonly type = SpotifyArtistActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe = false) {}
}

export class ChangePager implements Action {
  readonly type = SpotifyArtistActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = SpotifyArtistActionType.SEARCH;
  constructor(
    public payload: SpotifyArtistSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = SpotifyArtistActionType.SEARCHING;
  constructor(
    public payload: SpotifyArtistSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = SpotifyArtistActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = SpotifyArtistActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: SpotifyArtistDetailsModel) {}
}
export class Select implements Action {
  readonly type = SpotifyArtistActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = SpotifyArtistActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = SpotifyArtistActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = SpotifyArtistActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = SpotifyArtistActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>,
    public payload: SpotifyArtistDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = SpotifyArtistActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
