import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import { SpotifyTrackActions, SpotifyTrackActionType } from '../actions';

export class SpotifyTrackState {
  entities: { [key: number]: SpotifyTrackDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>;
}
const initialState: SpotifyTrackState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 30,
    asc: true,
    orderBy: 'id',
    search: new SpotifyTrackSearchModel({
      search: '',
    }),
  }),
};

export const spotifyTrackReducer = (
  state: SpotifyTrackState = initialState,
  action: SpotifyTrackActions.All
): SpotifyTrackState => {
  switch (action.type) {
    case SpotifyTrackActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case SpotifyTrackActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case SpotifyTrackActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case SpotifyTrackActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
