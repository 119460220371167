import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';
import { SpotifyArtistSetResponseModel } from '../models/spotift-artist-set-response.model';
import { SpotifyArtistDetailsModel } from '../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../models/spotify-artist-search.model';
import { SpotifyArtistSetModel } from '../models/spotify-artist-set.model';
import { SpotifyArtistServiceAbstract } from './spotify-artist.service.abstract';

@Injectable()
export class SpotifyArtistService extends SpotifyArtistServiceAbstract {
  private readonly spotifyArtistUrl = this.apiUrl + 'spotify-artist';

  isManagerResource = true;

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }
  protected getParams(params = new HttpParams(), isManagerResource = this.isManagerResource) {
    return isManagerResource ? params.append('managerResource', true) : params;
  }

  getPage(
    pager: PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>
  ): Promise<PagerResponseContainer<SpotifyArtistDetailsModel>> {
    const params: unknown = this.getParams(Mappings.pagerToDomain(pager));
    return this.http
      .get(this.spotifyArtistUrl, { params } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(SpotifyArtistDetailsModel), resp)))
      .toPromise();
  }

  set(model: SpotifyArtistSetModel): Promise<ResponseContainer<SpotifyArtistSetResponseModel>> {
    const domain = classToPlain(model);

    return this.http
      .post(this.spotifyArtistUrl, domain, { params: this.getParams() })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(SpotifyArtistSetResponseModel), resp)))
      .toPromise();
  }
}
