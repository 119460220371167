import { routerReducer, RouterState } from '@ngrx/router-store';


import { SessionStatePart } from '@songpush/core/session/store/selectors';
import { GenreStatePart } from '@songpush/core/genre/store/selectors';
import { SubGenreStatePart } from '@songpush/core/sub-genre/store/selectors';
import { LanguageStatePart } from '@songpush/core/language/store/selectors';
import { MoodStatePart } from '@songpush/core/mood/store/selectors';
import { ContentCategoryStatePart } from '@songpush/core/content-category/store/selectors';
import { CountryStatePart } from '@songpush/core/country/store/selectors';
import { CityStatePart } from '@songpush/core/city/store/selectors';
import { SongStatePart } from '@songpush/core/song/store/selectors';
import { TicketStatePart } from '@songpush/core/ticket/store/selectors';
import { SpotifyArtistStatePart } from '@songpush/core/spotify-artist/store/selectors';
import { UserStatePart } from '@songpush/core/user/store/selectors';
import { CompanyStatePart } from '@songpush/core/company/store/selectors';
import { SpotifyTrackStatePart } from '@songpush/core/spotify-track/store/selectors';
import { BankAccountStatePart } from '@songpush/core/bank-account/store/selectors';
import { CampaignV2StatePart } from '@songpush/core/campaign-v2/store/selectors';
import { DealStatePart } from '@songpush/core/deal/store/selectors';
import { SettingsStatePart } from '@songpush/core/settings/store/selectors';
import { SocialBlockStatePart } from '@songpush/core/social-block/store/selectors';
import { InvoiceStatePart } from '@songpush/core/invoice/store/selectors';
import { invoiceReducer, InvoiceState } from '@songpush/core/invoice/store/reducers';
import { socialBlockReducer, SocialBlockState } from '@songpush/core/social-block/store/reducers';
import { settingsReducer, SettingsState } from '@songpush/core/settings/store/reducers';
import { dealReducer, DealState } from '@songpush/core/deal/store/reducers';
import { campaignV2Reducer, CampaignV2State } from '@songpush/core/campaign-v2/store/reducers';
import { bankAccountReducer, BankAccountState } from '@songpush/core/bank-account/store/reducers';
import { spotifyTrackReducer, SpotifyTrackState } from '@songpush/core/spotify-track/store/reducers';
import { companyReducer, CompanyState } from '@songpush/core/company/store/reducers';
import { userReducer, UserState } from '@songpush/core/user/store/reducers';
import { spotifyArtistReducer, SpotifyArtistState } from '@songpush/core/spotify-artist/store/reducers';
import { ticketReducer, TicketState } from '@songpush/core/ticket/store/reducers';
import { songReducer, SongState } from '@songpush/core/song/store/reducers';
import { cityReducer, CityState } from '@songpush/core/city/store/reducers';
import { countryReducer, CountryState } from '@songpush/core/country/store/reducers';
import { contentCategoryReducer, ContentCategoryState } from '@songpush/core/content-category/store/reducers';
import { moodReducer, MoodState } from '@songpush/core/mood/store/reducers';
import { languageReducer, LanguageState } from '@songpush/core/language/store/reducers';
import { subGenreReducer, SubGenreState } from '@songpush/core/sub-genre/store/reducers';
import { genreReducer, GenreState } from '@songpush/core/genre/store/reducers';
import { sessionReducer, SessionState } from '@songpush/core/session/store/reducers';

import { patchStoreMetaReducer } from './patch-store.meta.reducer';
import { FlowProgressState } from '../../../flow/store/reducers';


export class State
  implements
    SessionStatePart,
    GenreStatePart,
    SubGenreStatePart,
    LanguageStatePart,
    MoodStatePart,
    ContentCategoryStatePart,
    CountryStatePart,
    CityStatePart,
    SongStatePart,
    TicketStatePart,
    SpotifyArtistStatePart,
    UserStatePart,
    CompanyStatePart,
    SpotifyTrackStatePart,
    BankAccountStatePart,
    CampaignV2StatePart,
    DealStatePart,
    SettingsStatePart,
    SocialBlockStatePart,
    InvoiceStatePart
{
  invoice: InvoiceState;
  socialBlock: SocialBlockState;
  settings: SettingsState;
  deal: DealState;
  campaignV2: CampaignV2State;
  bankAccount: BankAccountState;
  spotifyTrack: SpotifyTrackState;
  company: CompanyState;
  user: UserState;
  spotifyArtist: SpotifyArtistState;
  ticket: TicketState;
  song: SongState;
  city: CityState;
  country: CountryState;
  contentCategory: ContentCategoryState;
  mood: MoodState;
  language: LanguageState;
  subGenre: SubGenreState;
  genre: GenreState;
  session: SessionState;
  router: RouterState;
  FlowProgress: FlowProgressState;
}

export const rootReducer = {
  router: routerReducer,
  session: sessionReducer,
  genre: genreReducer,
  subGenre: subGenreReducer,
  language: languageReducer,
  mood: moodReducer,
  contentCategory: contentCategoryReducer,
  country: countryReducer,
  city: cityReducer,
  song: songReducer,
  ticket: ticketReducer,
  spotifyArtist: spotifyArtistReducer,
  user: userReducer,
  company: companyReducer,
  spotifyTrack: spotifyTrackReducer,
  bankAccount: bankAccountReducer,
  campaignV2: campaignV2Reducer,
  deal: dealReducer,
  settings: settingsReducer,
  socialBlock: socialBlockReducer,
  invoice: invoiceReducer,
};

/// TODO !environment.production ? [patchStoreMetaReducer, storeFreeze] :
export const metaReducers = [patchStoreMetaReducer];
