import { Mappings } from '../../../tools/mapping/mappings';
import { MoodModel } from '../../models/mood.model';
import { MoodActions, MoodActionType } from '../actions';

export class MoodState {
  entities: { [key: number]: MoodModel };
  ids: number[];
  loaded: boolean;
}

const initialState: MoodState = {
  entities: {},
  ids: [],
  loaded: false,
};

export const moodReducer = (state: MoodState = initialState, action: MoodActions.All): MoodState => {
  switch (action.type) {
    case MoodActionType.LOAD_FROM_DB_COMPLETED: {
      if (!state.loaded) {
        return Mappings.assign(state, addPayloadToState(state, action.payload));
      } else {
        return state;
      }
    }
    case MoodActionType.LOAD_COMPLETED: {
      return Mappings.assign(state, addPayloadToState(state, action.payload));
    }
    default:
      return state;
  }
};

const addPayloadToState = (state: MoodState, payload: MoodModel[]) => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((mood) => mood.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
  loaded: true,
});
