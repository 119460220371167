import { Injectable } from '@angular/core';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ReorderModel } from '../../../common/models/reorder.model';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { SocialBlockAddMany } from '../../models/social-block-add-many.model';
import { SocialBlockCreateModel } from '../../models/social-block-create.model';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockEditModel } from '../../models/social-block-edit.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';

@Injectable()
export abstract class SocialBlockServiceAbstract {
  abstract edit(model: SocialBlockEditModel, id?: number): Promise<ResponseContainer<SocialBlockDetailsModel>>;
  abstract create(model: SocialBlockCreateModel): Promise<ResponseContainer<SocialBlockDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>
  ): Promise<PagerResponseContainer<SocialBlockDetailsModel>>;
  abstract get(id?: number): Promise<ResponseContainer<SocialBlockDetailsModel>>;
  abstract delete(id: number): Promise<ResponseContainer<unknown>>;
  abstract reorder(model: ReorderModel, id: number): Promise<ResponseContainer<unknown>>;
  abstract checkTaxNumber(taxNumber: string, countryISO: string): Promise<boolean>;
  abstract addMany(model: SocialBlockAddMany): Promise<ResponseContainer<SocialBlockDetailsModel>>;
}
