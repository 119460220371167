import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { getTaxNumberRegex } from '@songpush/validators/tools/tax-number-regex';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ReorderModel } from '../../common/models/reorder.model';
import { Mappings } from '../../tools/mapping/mappings';
import { CompanyCreateModel } from '../models/company-create.model';
import { CompanyDetailsModel } from '../models/company-details.model';
import { CompanyEditModel } from '../models/company-edit.model';
import { CompanySearchModel } from '../models/company-search.model';
import { CompanyServiceAbstract } from './company.service.abstract';

@Injectable()
export class CompanyService extends CompanyServiceAbstract {
  private readonly companyUrl = this.apiUrl + 'company';
  private readonly companyPublicUrl = this.apiUrl + 'company-public';
  private readonly reorderUrl = `${this.apiUrl}slave-schedule/change-company-order`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  create(
    model: CompanyCreateModel
  ): Promise<ResponseContainer<CompanyDetailsModel>> {
    const params = new HttpParams({
      fromObject: { managerResource: true },
    });

    const domain = classToPlain(model);
    return this.http
      .post(this.companyUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(CompanyDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  edit(
    model: CompanyEditModel,
    id?: number
  ): Promise<ResponseContainer<CompanyDetailsModel>> {
    const params = new HttpParams({
      fromObject: { managerResource: true },
    });

    const domain = classToPlain(model);
    return this.http
      .put(id ? `${this.companyUrl}/${id}` : this.companyUrl, domain, {
        params,
      })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(CompanyDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  getPage(
    pager: PagerEntity<CompanyDetailsModel, CompanySearchModel>
  ): Promise<PagerResponseContainer<CompanyDetailsModel>> {
    const params: HttpParams = Mappings.pagerToDomain(pager).append(
      'managerResource',
      true
    );

    return this.http
      .get(this.companyPublicUrl, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(CompanyDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  get(id?: number): Promise<ResponseContainer<CompanyDetailsModel>> {
    const params = new HttpParams({
      fromObject: {
        managerResource: true,
      },
    });

    return this.http
      .get(id ? `${this.companyUrl}/${id}` : `${this.companyUrl}`, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(CompanyDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  delete(id: number): Promise<ResponseContainer<unknown>> {
    return this.http
      .delete(`${this.companyUrl}/${id}`)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  reorder(
    model: ReorderModel,
    id: number
  ): Promise<ResponseContainer<unknown>> {
    const domainModel = classToPlain(model);
    return this.http
      .put(`${this.reorderUrl}/${id}`, domainModel)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  checkTaxNumber(taxNumber: string, countryISO: string) {
    return Promise.resolve(
      !!getTaxNumberRegex(countryISO)?.exec?.(taxNumber?.toLowerCase())
    );
  }
}
