import { NgModule, makeEnvironmentProviders } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideState } from '@ngrx/store';

import { settingsNotificationGroupReducer } from '../store/settings-notification-group.reducer';
import { SettingsNotificationGroupEffects } from '../store/settings-notification-group.effects';
import { SettingsNotificationGroupServiceAbstract } from '../providers/settings-notification-group.service.abstract';
import { SettingsNotificationGroupService } from '../providers/settings-notification-group.service';

export const provideSettingsNotificationGroupState = () =>
  makeEnvironmentProviders([
    provideState('settingsNotificationGroup', settingsNotificationGroupReducer),
    provideEffects([SettingsNotificationGroupEffects]),
    { provide: SettingsNotificationGroupServiceAbstract, useClass: SettingsNotificationGroupService },
  ]);

@NgModule({
  imports: [
    EffectsModule.forFeature([SettingsNotificationGroupEffects]),
    StoreModule.forFeature('settingsNotificationGroup', settingsNotificationGroupReducer),
  ],
  providers: [{ provide: SettingsNotificationGroupServiceAbstract, useClass: SettingsNotificationGroupService }],
})
export class SettingsNotifictaionGroupModule {}
