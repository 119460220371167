import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import {
  classToPlain,
  plainToClass,
  plainToClassFromExist,
} from 'class-transformer';

import { getTaxNumberRegex } from '@songpush/validators/tools/tax-number-regex';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ReorderModel } from '../../common/models/reorder.model';
import { Mappings } from '../../tools/mapping/mappings';
import { BankAccountCreateModel } from '../models/bank-account-create.model';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountEditModel } from '../models/bank-account-edit.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { BankAccountServiceAbstract } from './bank-account.service.abstract';

@Injectable()
export class BankAccountService extends BankAccountServiceAbstract {
  private readonly bankAccountUrl = `${this.apiUrl}company/bank-account`;
  private readonly mandateUrl = `${this.apiUrl}company/bank-account/mandate`;
  private readonly reorderUrl = `${this.bankAccountUrl}change-order`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  create(
    model: BankAccountCreateModel
  ): Promise<ResponseContainer<BankAccountDetailsModel>> {
    const domain = classToPlain(model);
    const params = new HttpParams({ fromObject: { managerResource: true } });
    return this.http
      .post(this.bankAccountUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(BankAccountDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  edit(
    model: BankAccountEditModel,
    id?: number
  ): Promise<ResponseContainer<BankAccountDetailsModel>> {
    const domain = classToPlain(model);
    const params = new HttpParams({ fromObject: { managerResource: true } });
    return this.http
      .put(id ? `${this.bankAccountUrl}/${id}` : this.bankAccountUrl, domain, {
        params,
      })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(BankAccountDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  getPage(
    pager: PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>
  ): Promise<PagerResponseContainer<BankAccountDetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager).append(
      'managerResource',
      true
    );
    return this.http
      .get(this.bankAccountUrl, { params } as any)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(BankAccountDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  get(id?: number): Promise<ResponseContainer<BankAccountDetailsModel>> {
    const params = new HttpParams({ fromObject: { managerResource: true } });
    return this.http
      .get(id ? `${this.bankAccountUrl}/${id}` : `${this.bankAccountUrl}`, {
        params,
      })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(BankAccountDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  delete(id: number): Promise<ResponseContainer<unknown>> {
    const params = new HttpParams({ fromObject: { managerResource: true } });
    return this.http
      .delete(`${this.bankAccountUrl}/${id}`, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  reorder(
    model: ReorderModel,
    id: number
  ): Promise<ResponseContainer<unknown>> {
    const domainModel = classToPlain(model);
    return this.http
      .put(`${this.reorderUrl}/${id}`, domainModel)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  checkTaxNumber(taxNumber: string, countryISO: string) {
    return Promise.resolve(
      !!getTaxNumberRegex(countryISO)?.exec?.(taxNumber?.toLowerCase())
    );
  }

  createMandate() {
    const params = new HttpParams({ fromObject: { managerResource: true } });
    return this.http
      .post(this.mandateUrl, {}, { params })
      .pipe(map((resp) => plainToClass(ResponseContainer, resp)));
  }
}
