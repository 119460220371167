import { Mappings } from '../../../tools/mapping/mappings';
import { ContentCategoryModel } from '../../models/content-category.model';
import { ContentCategoryActions, ContentCategoryActionType } from '../actions';

export class ContentCategoryState {
  entities: { [key: number]: ContentCategoryModel };
  ids: number[];
  loaded: boolean;
}

const initialState: ContentCategoryState = {
  entities: {},
  ids: [],
  loaded: false,
};

export const contentCategoryReducer = (
  state: ContentCategoryState = initialState,
  action: ContentCategoryActions.All
): ContentCategoryState => {
  switch (action.type) {
    case ContentCategoryActionType.LOAD_FROM_DB_COMPLETED: {
      if (!state.loaded) {
        return Mappings.assign(state, addPayloadToState(state, action.payload));
      } else {
        return state;
      }
    }
    case ContentCategoryActionType.LOAD_COMPLETED: {
      return Mappings.assign(state, addPayloadToState(state, action.payload));
    }
    default:
      return state;
  }
};

const addPayloadToState = (state: ContentCategoryState, payload: ContentCategoryModel[]) => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((contentCategory) => contentCategory.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
  loaded: true,
});
