/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector } from '@ngrx/store';

import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { SocialBlockState } from '../reducers/social-block.reducer';

export type SocialBlockStatePart = { socialBlock: SocialBlockState };

class SocialBlockSelectors extends BaseMultiPagerSelector<
  SocialBlockState,
  SocialBlockDetailsModel,
  SocialBlockSearchModel
> {
  getAllFlat = (pagerId = 'default') =>
    createSelector(this.getAllInstance(pagerId), (items) => items.map(({ name }) => name));

  constructor() {
    super((state: SocialBlockStatePart): SocialBlockState => state.socialBlock);
  }
}

export const SocialBlockSelector = new SocialBlockSelectors();
