import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2State } from '../reducers/campaign-v2.reducer';

export type CampaignV2StatePart = { campaignV2: CampaignV2State };

export const getCampaignV2State = (state: CampaignV2StatePart): CampaignV2State => state.campaignV2;

class CampaignV2Selectors extends BaseMultiPagerSelector<
  CampaignV2State,
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  constructor() {
    super((state: CampaignV2StatePart): CampaignV2State => state.campaignV2);
  }
}

export const CampaignV2Selector = new CampaignV2Selectors();
