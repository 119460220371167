import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import {
  bufferToggle,
  debounceTime,
  filter,
  flatMap,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { SongActionType, SongActions } from '../actions';
import { SongSelector } from '../selectors';
import { SongServiceAbstract } from '../../services/song.service.abstract';
import { FormValidatorServiceAbstract } from '../../../form/services/form-validator/form-validator.service.abstract';
import { SongDetailsModel } from '../../models/song-details.model';
import { PagerEntity } from '../../../common/models/PagerEntity';
import { SongSearchModel } from '../../models/song-search.model';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { UserActions, UserSearchModel, hasUserInStore } from '../../../user';

@Injectable()
export class SongEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.SELECT_PAGE),
      flatMap(async (action: SongActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      flatMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.SongService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new SongActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: SongActions.Search) =>
        zip(of(action), this.getSearchModel(action.pagerId))
      ),
      filter(
        ([action, search]) =>
          !action.event.silent &&
          (!ObjectExtensions.compare(action.payload, search) ||
            action.event.force)
      ),
      map(
        ([action]) =>
          new SongActions.Searching(
            action.payload,
            action.event,
            action.pagerId
          )
      )
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.SEARCHING),
      mergeMap((action: SongActions.Searching) =>
        zip(of(action), this.getSongs(action.pagerId))
      ),
      map(
        ([action, resp]) =>
          new SongActions.LoadPageCompleted(
            1,
            resp.maxCount,
            resp.results,
            action.pagerId,
            true
          )
      )
    )
  );

  deleteSong$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.DELETE),
      mergeMap(async (action: SongActions.Delete) => ({
        action,
        response: await this.SongService.delete(action.id),
      })),
      tap(({ response }) =>
        response.status.globalMessage
          ? this.formValidator.showToast(response.status.globalMessage)
          : null
      ),
      filter(({ action, response }) => response?.status?.success),
      map(({ action }) => new SongActions.DeleteCompleted(action.id))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.LOADALL),
      mergeMap((action: SongActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(
          map((pager) => ({ pager, action }))
        )
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: SongDetailsModel[] = [];

        while (
          pager.maxCount === null ||
          pager.limit * (pager.selectedPageNum - 1) < pager.maxCount
        ) {
          const resp = await this.SongService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new SongActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new SongActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.SINGLE_LOAD),
      mergeMap(async (action: SongActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.SongService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SongActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SongActionType.RELOAD_ENTITY),
      mergeMap(async (action: SongActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.SongService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SongActions.SingleLoadeCompleted(resp.values))
    )
  );

  resolveArtists$ = createEffect(
    () =>
      this.resolveEntities(
        'userId',
        UserActions.ChangePager,
        UserSearchModel,
        UserActions.LoadAll,
        (id) =>
          hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  getIds = this.store.select(SongSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private SongService: SongServiceAbstract,
    private store: Store,
    private formValidator: FormValidatorServiceAbstract
  ) {}

  getSelectedPager(
    instance: string
  ): Observable<PagerEntity<SongDetailsModel, SongSearchModel>> {
    return this.store.select(SongSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<SongSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getSongs(instance: string): Observable<PagerResponse<SongDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.SongService.getPage(pager)),
      map((resp) => resp.values)
    );
  }

  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        SongActionType.SINGLE_LOAD_COMPLETED,
        SongActionType.LOADALL_COMPLETED,
        SongActionType.LOAD_PAGE_COMPLETED
      )
    );
  }

  resolveEntities<T, Z>(
    key: keyof SongDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action:
            | SongActions.SingleLoadeCompleted
            | SongActions.LoadAllCompleted
            | SongActions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(0)),
      filter((e) => e.length > 0),
      mergeMap((requests) =>
        zip(
          ...requests
            .flatMap((request) => request[key] as number)
            .reduce(
              (ids, id) => (ids.includes(id) ? ids : [...ids, id]),
              [] as number[]
            )
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }
}
