import { Action } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { Mappings } from '../mapping/mappings';
import { IChangePagerAction } from '../reducer-helper/model/change-pager';
import { IHasPagerEntities } from '../reducer-helper/model/has-pager-entities';
import { IHasId } from '../reducer-helper/model/i-has-id';
import { IHasOrder } from '../reducer-helper/model/i-has-order';
import { ILoadAllCompletedAction } from '../reducer-helper/model/load-all-completed';
import { ILoadPageCompleteAction } from '../reducer-helper/model/load-page-completed';
import { IReorderAction } from '../reducer-helper/model/reorder';
import { ISearching } from '../reducer-helper/model/searching';
import { ISelectAction } from '../reducer-helper/model/select';
import { ISelectPageAction } from '../reducer-helper/model/select-page-action';
import { ISingleLoadCompletedAction } from '../reducer-helper/model/single-load-completed';

export interface IHasPagerEntity<T, Z> {
  pager: PagerEntity<T, Z>;
  entities: { [key: string]: T; [key: number]: T };
  ids: (number | string)[];
  selectedId: number | string;
}

export interface ILoadPageAction extends Action {
  page?: number;
  pagerId?: string;
}

export class ReducerTools {
  static selectPageReduce<T, Z, U extends IHasPagerEntities<T, Z>>(
    action: ISelectPageAction,
    state: U,
    initialPager: PagerEntity<T, Z>
  ): U {
    let patch: Partial<PagerEntity<T, Z>> = { selectedPageNum: action.selectedPageNum };
    if (action.selectedPageNum === 1 && action.wipe) {
      patch = { ...patch, hasNew: false };
    }
    return Mappings.patchMultiplePager(state, patch, initialPager, action.pagerId);
  }

  static loadPageCompleteReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ILoadPageCompleteAction<T>,
    state: U,
    initialPager: PagerEntity<T, Z>
  ): U {
    const newEntities = action.payload.reduce(
      (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
      {} as typeof state.entities
    );
    const ids = action.payload.map(({ id }) => id);
    const pages = Mappings.assign(action.wipe ? {} : state.pagers[action.pagerId]?.pages, { [action.page]: ids });
    const entities = Mappings.assign(state.entities, newEntities);

    return Mappings.assign(state, {
      entities,
      ids: Mappings.mergeIds(state.ids, ids),
      pagers: Mappings.assign(state.pagers, {
        [action.pagerId]: Mappings.assign(state.pagers[action.pagerId] || initialPager, {
          pages,
          maxCount: action.maxCount,
          selectedPageNum: action.page,
        }),
      } as Partial<{ [key: string]: PagerEntity<T, Z> }>),
    } as Partial<U>);
  }

  static searchingReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ISearching<Z>,
    state: U,
    initialPager: PagerEntity<T, Z>
  ): U {
    return Mappings.patchMultiplePager(
      state,
      {
        search: Mappings.assign((state.pagers[action.pagerId] || initialPager).search, action.payload as Partial<Z>),
      },
      initialPager,
      action.pagerId
    );
  }

  static loadAllCompletedReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ILoadAllCompletedAction<T, Z>,
    state: U
  ): U {
    const newEntities = action.payload.reduce(
      (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
      {} as typeof state.entities
    );
    const entities = Mappings.assign(state.entities, newEntities);
    const ids = action.payload.map(({ id }) => id);
    return Mappings.assign(state, {
      entities,
      ids,
      pagers: Mappings.assign(state.pagers, {
        [action.pagerId]: action.pager,
      }),
    } as Partial<U>);
  }

  static changePagerReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: IChangePagerAction<T, Z>,
    state: U,
    initialPager: PagerEntity<T, Z>
  ): U {
    return Mappings.patchMultiplePager(state, action.payload, initialPager, action.pagerId);
  }

  static selectReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ISelectAction,
    state: U,
    initialPager: PagerEntity<T, Z> // To resolve generic types
  ): U {
    return Mappings.assign(state, { selectedId: action.id } as Partial<U>);
  }
  static singleLoadCompletedReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ISingleLoadCompletedAction<T>,
    state: U
  ): U {
    return Mappings.assign(state, {
      ids: Mappings.mergeIds(state.ids, [action.payload.id]),
      entities: Mappings.assign(state.entities, {
        [action.payload.id]: action.payload,
      }),
    } as Partial<U>);
  }

  static reorderLocal<T extends IHasId & IHasOrder, Z, U extends IHasPagerEntities<T, Z>>(
    action: IReorderAction,
    state: U,
    initialPager: PagerEntity<T, Z> // To resolve generic types
  ) {
    const pagerPages = (state.pagers[action.pagerId] || initialPager).pages;
    const pagerIds = Object.keys((state.pagers[action.pagerId] || initialPager).pages)
      .map((keyStr) => parseInt(keyStr, 10))
      .reduce((all, key) => [...all, ...pagerPages[key]], [] as number[]);

    const listShouldReorder = pagerIds.map((id) => state.entities[id]).sort((a, b) => a.sortorder - b.sortorder);

    const tmpList = [...listShouldReorder];
    const item = tmpList.splice(action.from, 1)[0];
    tmpList.splice(action.to, 0, item);

    const sortedList = tmpList.map((value, index) => Mappings.assign(value, { sortorder: index } as Partial<T>));

    const newEnts = Mappings.entitiesPlusList(state.entities, sortedList, 'id');
    return Mappings.assign(state, { entities: newEnts } as Partial<U>);
  }

  static deleteReduce<T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
    action: ISelectAction,
    state: U,
    initialPager: PagerEntity<T, Z>
  ): U {
    const { [action.id]: removed, ...newEntities } = state.entities;
    const onPage = Object.keys(state.pagers)
      .map((instace) => ({
        instace,
        pages: Object.keys(state.pagers[instace].pages).map((id) => parseInt(id, 10)),
      }))
      .flatMap((instacePages) =>
        instacePages.pages.map((page) => ({
          instace: instacePages.instace,
          pageId: page,
          ids: state.pagers[instacePages.instace].pages[page],
        }))
      )
      .filter(
        (pagerIds) =>
          // if id can be 0, then you should check !== undefined
          pagerIds.ids.find((page) => page === action.id) !== undefined
      );
    let newPages = state.pagers;

    onPage.forEach((pagerIds) => {
      const newPage = state.pagers[pagerIds.instace].pages[pagerIds.pageId].filter((id) => id !== action.id);
      newPages = Mappings.assign(newPages, {
        [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
          pages: Mappings.assign(newPages[pagerIds.instace].pages, {
            [pagerIds.pageId]: newPage,
          }),
        }),
      });
    });

    return Mappings.assign(state, {
      entities: newEntities,
      pagers: newPages,
      ids: state.ids.filter((id) => id !== action.id),
    } as Partial<U>);
  }
}
