import {
  HttpDownloadProgressEvent,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpUploadProgressEvent,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, retry, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { ENVIRONMENT } from '@songpush/core/common/tokens';
import { parseBoolean } from '@songpush/core/tools/type';
import { SettingsSelector } from '@songpush/core/settings/store/selectors';
import { SessionActions } from '@songpush/core/session/store/actions';



@Injectable()
export class UserHttpInterceptorService implements HttpInterceptor {
  constructor(private store: Store, private router: Router, @Inject(ENVIRONMENT) public environment: any) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getHeader(req).pipe(
      mergeMap((headers) => {
        const shouldRetry =
          req.params.get('retry') === null
            ? req.method === 'GET' // default value on get->true,other->false
            : parseBoolean(req.params.get('retry'));

        const params = req.params.delete('retry');

        // should clone becouse headers/params are read only props
        req = req.clone({ headers, params });

        let firedRequest = next.handle(req);

        firedRequest = firedRequest.pipe(
          retry({
            delay: (error) => (shouldRetry ? this.onRequestErrorRetry(error) : this.onRequestError(error)),
          }),
          this.mapFailed()
        );

        if (req.reportProgress) {
          firedRequest = firedRequest.pipe(
            map((event) => {
              let status;
              switch (event.type) {
                case HttpEventType.Sent:
                  return event;

                case HttpEventType.UploadProgress:
                  status = Math.round((100 * event.loaded) / event.total);
                  return {
                    type: event.type,
                    total: status,
                    loaded: 0,
                  } as HttpUploadProgressEvent;

                case HttpEventType.DownloadProgress:
                  status = Math.round((100 * event.loaded) / event.total);
                  return {
                    type: event.type,
                    total: status,
                    loaded: 0,
                  } as HttpDownloadProgressEvent;

                // case HttpEventType.Response:
                //   return event;

                default:
                  return event;
              }
            })
          );
        }

        return firedRequest;
      })
    );
  }

  getHeader(req: HttpRequest<any>) {
    return this.store
      .select(SettingsSelector.getLanguage)
      .pipe(take(1))
      .pipe(
        map((lang) => {
          let header: HttpHeaders = req.headers || new HttpHeaders();
          // set more header here if required
          const url = req.url;
          if (
            url.startsWith(this.environment.apiUrl) ||
            url.startsWith(this.environment.downloadUrl) ||
            url.startsWith(this.environment.uploadUrl) ||
            url.startsWith(this.environment.mediaUrl) ||
            url.startsWith(this.environment.shortlinkUrl)
          ) {
            header = header.append('gmt', `${new Date().getTimezoneOffset()}`);
            if (lang) {
              header = header.append('lang', lang);
            }
          }
          return header;
        })
      );
  }

  onRequestErrorRetry(error: HttpErrorResponse) {
    if (error.status === 401 && !error.url.endsWith('/logout')) {
      this.store.dispatch(SessionActions.logout({}));
      throw error.error || error;
    } else if (error.status === 500 || error.status === 400 || error.status === 404 || error.status === 403) {
      throw error.error || error;
    } else if (error.status === 410) {
      this.router.navigate(['410'], {
        skipLocationChange: true,
      });
      throw error;
    } else if (error.status === 423) {
      this.router.navigate(['423'], {
        skipLocationChange: true,
      });
      throw error.error || error;
    } else if (error.status === 0) {
      return timer(5000);
    } else if (!error.status) {
      throw error;
    }
    return null;
  }

  onRequestError(error: HttpErrorResponse): any {
    if (error.status === 401 && !error.url.endsWith('/logout')) {
      this.store.dispatch(SessionActions.logout({}));
    } else if (error.status === 423) {
      this.router.navigate(['423'], {
        skipLocationChange: true,
      });
    }
    throw error.error || error;
  }

  mapFailed() {
    return <T>(source: Observable<HttpEvent<T>>): Observable<HttpEvent<T>> =>
      source.pipe(
        catchError((error: HttpErrorResponse) => {
          if ([403, 404, 410].includes(error.status)) {
            let resp: HttpResponse<any>;
            if (error.url.endsWith('.json')) {
              resp = new HttpResponse({
                ...error,
                body: [],
                status: 200,
              });
            } else {
              resp = new HttpResponse({
                ...error,
                body: { status: { success: true }, values: null },
                status: 200,
              });
            }
            return of(resp);
          }
          throw error;
        })
      );
  }
}
