export const LOAD_PAGE = '[SpotifyTrack] load page';
export const LOAD_PAGE_COMPLETED = '[SpotifyTrack] load page completed';
export const SELECT_PAGE = '[SpotifyTrack] select page';
export const CHANGE_PAGER = '[SpotifyTrack] change pager';
export const SINGLE_LOAD = '[SpotifyTrack] single load';
export const SINGLE_LOAD_COMPLETED = '[SpotifyTrack] single load completed';
export const SELECT = '[SpotifyTrack] select';
export const DELETE = '[SpotifyTrack] delete';
export const DELETE_COMPLETED = '[SpotifyTrack] delete completed';
export const SEARCH = '[SpotifyTrack] search';
export const SEARCHING = '[SpotifyTrack] searching';
export const LOADALL = '[SpotifyTrack] load all';
export const LOADALL_COMPLETED = '[SpotifyTrack] load all completed';
export const RESETSTATE = '[SpotifyTrack] reset state';
export const RELOAD_ENTITY = '[SpotifyTrack] reload entity';
export const REORDER_LOCAL = '[SpotifyTrack] reorder local';
export const REORDER_REMOTE = '[SpotifyTrack] reorder remote';
