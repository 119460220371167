import { Injectable } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { FormValidatorServiceAbstract } from '../../../form/services/form-validator/form-validator.service.abstract';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserSearchModel } from '../../models/user-search.model';
import { UserServiceAbstract } from '../../services/user.service.abstract';
import { UserActions, UserActionType } from '../actions';
import { UserSelector } from '../selectors/user.selector';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class UserEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.SELECT_PAGE),
      mergeMap((action: UserActions.SelectPage) =>
        this.getSelectedPager(action.pagerId).pipe(
          map((pager) => ({ pager, selectedPageNum: action.selectedPageNum, action }))
        )
      ),
      mergeMap(({ selectedPageNum, pager, action }) =>
        this.userService.getPage(pager).pipe(
          map((response) => ({
            response,
            page: selectedPageNum,
            action,
          }))
        )
      ),
      map(
        ({ response, page, action }) =>
          new UserActions.LoadPageCompleted(page, response.values.maxCount, response.values.results, action.pagerId)
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: UserActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new UserActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.SEARCHING),
      mergeMap((action: UserActions.Searching) => zip(of(action), this.getUsers(action.pagerId))),
      map(([action, resp]) => new UserActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId, true))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.LOADALL),
      mergeMap((action: UserActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: UserDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.userService.getPage(pager).toPromise();
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new UserActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new UserActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.SINGLE_LOAD),
      mergeMap(async (action: UserActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.userService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new UserActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionType.RELOAD_ENTITY),
      mergeMap(async (action: UserActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.userService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new UserActions.SingleLoadeCompleted(resp.values))
    )
  );

  getIds = this.store.select(UserSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private userService: UserServiceAbstract,
    private store: Store,
    private formValidator: FormValidatorServiceAbstract
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<UserDetailsModel, UserSearchModel>> {
    return this.store.select(UserSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<UserSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getUsers(instance: string): Observable<PagerResponse<UserDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.userService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
}
