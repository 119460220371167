export const LOAD_PAGE = '[SocialBlock] load page';
export const LOAD_PAGE_COMPLETED = '[SocialBlock] load page completed';
export const SELECT_PAGE = '[SocialBlock] select page';
export const CHANGE_PAGER = '[SocialBlock] change pager';
export const SINGLE_LOAD = '[SocialBlock] single load';
export const SINGLE_LOAD_COMPLETED = '[SocialBlock] single load completed';
export const SELECT = '[SocialBlock] select';
export const DELETE = '[SocialBlock] delete';
export const DELETE_COMPLETED = '[SocialBlock] delete completed';
export const SEARCH = '[SocialBlock] search';
export const SEARCHING = '[SocialBlock] searching';
export const LOADALL = '[SocialBlock] load all';
export const LOADALL_COMPLETED = '[SocialBlock] load all completed';
export const RESETSTATE = '[SocialBlock] reset state';
export const RELOAD_ENTITY = '[SocialBlock] reload entity';
export const REORDER_LOCAL = '[SocialBlock] reorder local';
export const REORDER_REMOTE = '[SocialBlock] reorder remote';
