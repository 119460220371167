import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';
import { systemMessageAction } from './system-message.action';

export type SystemMessageState = IHasPagerEntities<SystemMessageDetailsModel, SystemMessageSearchModel>;
const initialPager = new PagerEntity<SystemMessageDetailsModel, SystemMessageSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: true,
  orderBy: 'id',
  search: new SystemMessageSearchModel({}),
});

const systemMessageInitialState: SystemMessageState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const systemMessageReducer = createPagerReducer({
  details: SystemMessageDetailsModel,
  filter: SystemMessageSearchModel,
  initialState: systemMessageInitialState,
  actions: systemMessageAction,
  source: 'SystemMessage',
});
