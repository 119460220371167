import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { WalletEffects } from '../store/wallet.effects';
import { walletReducer } from '../store/wallet.reducer';
import { WalletServiceAbstract } from '../providers/wallet.service.abstract';
import { WalletService } from '../providers/wallet.service';



@NgModule({
  imports: [EffectsModule.forFeature([WalletEffects]), StoreModule.forFeature('wallet', walletReducer)],
  providers: [{ provide: WalletServiceAbstract, useClass: WalletService }],
})
export class WalletStateModule {}
