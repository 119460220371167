import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { InvoiceState } from '../reducers/invoice.reducer';

export type InvoiceStatePart = { invoice: InvoiceState };

class InvoiceSelectors extends BaseMultiPagerSelector<InvoiceState, InvoiceDetailsModel, InvoiceSearchModel> {
  constructor() {
    super((state: InvoiceStatePart): InvoiceState => state.invoice);
  }
}

export const InvoiceSelector = new InvoiceSelectors();
