<ui-layout-split>
  <a header-buttons-start class="inline-block" routerLink="/">
    <ui-logo class="mx-auto !h-[48px] !w-[48px]"></ui-logo>
  </a>

  <bkkr-card start fill="solid" class="hero-card">
    <bkkr-card-content>
      <ui-logo routerLink="/" class="inline-block pb-12"></ui-logo>
      <div class="card-content-inner">
        <ng-container *ngIf="response$ | async as response; else loadingTpl">
          <ng-container *ngIf="response?.status?.success">
            <bkkr-icon name="check-circle" color="success" class="large"></bkkr-icon>
          </ng-container>

          <ng-container *ngIf="!response?.status?.success">
            <bkkr-icon name="times-circle" color="danger" class="large"></bkkr-icon>
          </ng-container>
        </ng-container>
      </div>
    </bkkr-card-content>
  </bkkr-card>
  <div end class="split-end-content">
    <ion-row class="justify-end mb-4 lg:mb-8">
      <ion-col size="auto">
        <shared-language-select></shared-language-select>
      </ion-col>
    </ion-row>

    <ng-container *ngIf="response$ | async as response">
      <ng-container *ngIf="response?.status?.success; else notSuccessTpl">
        <h1 class="font-bold">{{ 'USER_EMAIL_UNSUBSCRIBE_SUCCESS_TITLE' | translate }}</h1>
        <p class="mb-12">{{ 'USER_EMAIL_UNSUBSCRIBE_SUCCESS_SUBTITLE' | translate }}</p>

        <bkkr-button expand="block" color="primary" routerLink="/">
          {{ 'USER_EMAIL_UNSUBSCRIBE_BUTTON' | translate }}
        </bkkr-button>
      </ng-container>
      <ng-template #notSuccessTpl>
        <h1 class="font-bold">
          {{ 'USER_EMAIL_UNSUBSCRIBE_ERROR_TITLE' | translate }}
        </h1>
        <p class="mb-0">
          {{ response.status.globalMessage | translate }}
        </p>
      </ng-template>
    </ng-container>
  </div>
  <div end class="split-end-content pt-12">
    <ui-copyright></ui-copyright>
  </div>
</ui-layout-split>

<ng-template #loadingTpl>
  <ion-spinner class="mb-4"></ion-spinner>
  <p>{{ 'USER_EMAIL_UNSUBSCRIBE_LOADING_TITLE' | translate }}</p>
</ng-template>
