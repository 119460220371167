import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { windowManagerService } from '@songpush/core/common/services/window-manger';

import { WindowManagerService } from './service/window-manager.service';
import { DownloadEffects } from './state/download.effects';
import { GoogleAnalyticsEffects } from './state/google-analytics.effects';

@NgModule({
  imports: [EffectsModule.forFeature([DownloadEffects, GoogleAnalyticsEffects])],
  providers: [{ provide: windowManagerService, useClass: WindowManagerService }],
})
export class CommonStateModule {}
