import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { SpotifyArtistActions, SpotifyArtistActionType } from '../actions';

export class SpotifyArtistState {
  entities: { [key: string]: SpotifyArtistDetailsModel };
  selectedId: string;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>;
  };
  initialPager: PagerEntity<SpotifyArtistDetailsModel, SpotifyArtistSearchModel>;
  ids: number[];
}
const initialState: SpotifyArtistState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 50,
    asc: true,
    orderBy: 'id',
    search: new SpotifyArtistSearchModel({
      search: '',
    }),
  }),
};

export const spotifyArtistReducer = (
  state: SpotifyArtistState = initialState,
  action: SpotifyArtistActions.All
): SpotifyArtistState => {
  switch (action.type) {
    case SpotifyArtistActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case SpotifyArtistActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case SpotifyArtistActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case SpotifyArtistActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
