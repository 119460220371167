import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ReorderModel } from '../../common/models/reorder.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { CompanyCreateModel } from '../models/company-create.model';
import { CompanyDetailsModel } from '../models/company-details.model';
import { CompanyEditModel } from '../models/company-edit.model';
import { CompanySearchModel } from '../models/company-search.model';

export abstract class CompanyServiceAbstract {
  abstract edit(model: CompanyEditModel, id?: number): Promise<ResponseContainer<CompanyDetailsModel>>;
  abstract create(model: CompanyCreateModel): Promise<ResponseContainer<CompanyDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<CompanyDetailsModel, CompanySearchModel>
  ): Promise<PagerResponseContainer<CompanyDetailsModel>>;
  abstract get(id?: number): Promise<ResponseContainer<CompanyDetailsModel>>;
  abstract delete(id: number): Promise<ResponseContainer<unknown>>;
  abstract reorder(model: ReorderModel, id: number): Promise<ResponseContainer<unknown>>;
  abstract checkTaxNumber(taxNumber: string, countryISO: string): Promise<boolean>;
}
