import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';


import { SessionActions } from '../store/actions';
import { SessionSelector } from '../store/selectors/session.selector';
import { filterEmpty } from '../../tools/map/filter-empty';
import { UserDetailsModel } from '../../user/models/user-details.model';

export const sessionUserRefreshGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store = inject(Store)
) =>
  store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded === true),
    switchMap(() => store.select(SessionSelector.selectUser)),
    filterEmpty(),
    tap((user) => refreshUserWhenFromDb(user, store)),
    map(() => true),
    take(1)
  );

const refreshUserWhenFromDb = (user: UserDetailsModel, store: Store) => {
  if (user.fromdb) {
    store.dispatch(SessionActions.refreshuser({ manager: false }));
  }
};
