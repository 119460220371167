import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { CityActions, CityActionType } from '../actions';

export class CityState {
  entities: { [key: number]: CityModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<CityModel, CitySearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<CityModel, CitySearchModel>;
}

const initialState: CityState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 50,
    asc: true,
    orderBy: '',
    search: new CitySearchModel({
      search: '',
    }),
  }),
};

export const cityReducer = (state: CityState = initialState, action: CityActions.All): CityState => {
  switch (action.type) {
    case CityActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case CityActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case CityActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case CityActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case CityActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case CityActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case CityActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case CityActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case CityActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
