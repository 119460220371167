import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import { bufferToggle, debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { CampaignV2ExistGuard } from '../../../campaign-v2/guards/campaign-v2-exits.guard';
import { CampaignV2SearchModel } from '../../../campaign-v2/models/campaign-v2-search.model';
import { CampaignV2Actions } from '../../../campaign-v2/store/actions';
import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { InvoiceServiceAbstract } from '../../services/invoice.service.abstract';
import { InvoiceActionType, InvoiceActions } from '../actions';
import { InvoiceSelector } from '../selectors/invoice.selector';
import { TransactionSearchModel } from '../../../transaction/models';
import { transactionAction } from '../../../transaction/store/transaction.action';
import { hasTransactionInStore } from '../../../transaction/guards/transaction-exits.guard';
import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { IHasId } from '../../../tools/reducer-helper/model/i-has-id';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { SessionSelector } from '../../../session/store/selectors/session.selector';

@Injectable()
export class InvoiceEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.SELECT_PAGE),
      mergeMap((action: InvoiceActions.SelectPage) =>
        this.getSelectedPager(action.pagerId).pipe(
          map((pager) => ({
            action,
            pager,
          }))
        )
      ),
      mergeMap(({ action, pager }) =>
        this.invoiceService.getPage(pager).pipe(
          map((response) => ({
            response,
            action,
            page: action.selectedPageNum,
          }))
        )
      ),
      map(
        ({ response, page, action }) =>
          new InvoiceActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId,
            action.wipe
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: InvoiceActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new InvoiceActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.SEARCHING),
      mergeMap((action: InvoiceActions.Searching) => zip(of(action), this.getInvoices(action.pagerId))),
      map(([action, resp]) => new InvoiceActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.LOADALL),
      mergeMap((action: InvoiceActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: InvoiceDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.invoiceService.getPage(pager).toPromise();
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new InvoiceActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new InvoiceActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.SINGLE_LOAD),
      mergeMap(async (action: InvoiceActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.invoiceService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new InvoiceActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActionType.RELOAD_ENTITY),
      mergeMap(async (action: InvoiceActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.invoiceService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new InvoiceActions.SingleLoadeCompleted(resp.values))
    )
  );

  resolveCampaigns$ = createEffect(
    () =>
      this.resolveEntities(
        'campaignId',
        CampaignV2Actions.ChangePager,
        CampaignV2SearchModel,
        CampaignV2Actions.LoadAll,
        (id) => this.campaignExistGuard.hasCampaignV2InStore(id).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveTransactions$ = createEffect(
    () =>
      this.resolveEntities(
        'transactionId',
        transactionAction.changePager as ReturnType<typeof createPagerActionGroup>['changePager'],
        TransactionSearchModel,
        transactionAction.loadAll,
        (id) => hasTransactionInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  getPager = this.store.select(InvoiceSelector.getPagers).pipe(take(1));
  getIds = this.store.select(InvoiceSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private invoiceService: InvoiceServiceAbstract,
    private campaignExistGuard: CampaignV2ExistGuard,
    private store: Store
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<InvoiceDetailsModel, InvoiceSearchModel>> {
    return this.store.select(InvoiceSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<InvoiceSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getInvoices(instance: string): Observable<PagerResponse<InvoiceDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.invoiceService.getPage(pager)),
      map((resp) => resp.values)
    );
  }

  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        InvoiceActionType.SINGLE_LOAD_COMPLETED,
        InvoiceActionType.LOADALL_COMPLETED,
        InvoiceActionType.LOAD_PAGE_COMPLETED
      ),
      switchMap((action) =>
        this.store.select(SessionSelector.selectIsManager).pipe(
          take(1),
          filter((isManager) => !isManager),
          map(() => action)
        )
      )
    );
  }

  resolveEntities<T extends IHasId, Z>(
    key: keyof InvoiceDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>> | ReturnType<typeof createPagerActionGroup>['changePager'],
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction> | ReturnType<typeof createPagerActionGroup>['loadAll'],
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action:
            | InvoiceActions.SingleLoadeCompleted
            | InvoiceActions.LoadAllCompleted
            | InvoiceActions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(10)),
      filter((e) => e.length > 0),
      mergeMap((requests) =>
        zip(
          ...requests
            .map((request) => request[key] as number)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) => {
        if ('constructor' in changePager) {
          this.store.dispatch(
            new (changePager as ClassConstructor<IChangePagerAction<T, Z>>)(
              {
                search: new search({
                  ids,
                }),
              },
              ids.join(',')
            )
          );
        } else {
          this.store.dispatch(
            (changePager as ReturnType<typeof createPagerActionGroup>['changePager'])({
              payload: {
                search: new search({
                  ids,
                }),
              },
              pagerId: ids.join(','),
            })
          );
        }
      }),
      tap((ids) => {
        if ('constructor' in loadAll) {
          this.store.dispatch(new (loadAll as ClassConstructor<ILoadAllAction>)(ids.join(',')));
        } else {
          this.store.dispatch(
            (loadAll as ReturnType<typeof createPagerActionGroup>['loadAll'])({
              pagerId: ids.join(','),
            })
          );
        }
      })
    );
  }
}
