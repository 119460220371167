/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';

import { debounceTime, delay, map, merge, mergeMap, of, pairwise, startWith } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SessionActions } from '@songpush/core/session/store/actions';
import { SessionSelector } from '@songpush/core/session/store/selectors';
import { filterEmpty } from '@songpush/core/tools/map';
import { walletAction } from '@songpush/core/wallet/store';
import { TicketActions } from '@songpush/core/ticket/store/actions';
import { SongActions } from '@songpush/core/song/store/actions';
import { transactionAction } from '@songpush/core/transaction/store';
import { CampaignV2Actions } from '@songpush/core/campaign-v2/store/actions';
import { DealActions } from '@songpush/core/deal/store/actions';
import { UserRelationType } from '@songpush/core/user/models';
import { teamAction } from '@songpush/core/team/store';
import { managerInvitationAction } from '@songpush/core/manager-invitation/store';
import { CompanyActions } from '@songpush/core/company/store/actions';
import { BankAccountActions } from '@songpush/core/bank-account/store/actions';
import { systemMessageAction } from '@songpush/core/system-message/store';
import { managerDealAction } from '@songpush/core/manager-deal/store';
import { dealLimitAction } from '@songpush/core/deal-limit/store';

import { WebsocketActions } from '../action/websocket.action';
import { WebsocketData } from '../../models/websocket-data';
import { WebsocketMessageType } from '../../models/websocket-message-type';
import { WebsocketTarget } from '../../models/websocket-target';
import { WebsocketAction } from '../../models/websocket-action';


@Injectable()
export class WebsocketEffects {
  tokenFromLogin$ = this.actions$.pipe(
    ofType(SessionActions.login),
    map((action) => action.token)
  );

  tokenFromSavedState$ = this.actions$.pipe(
    ofType(SessionActions.loadfromdbcompleted),
    map((action) => action?.state?.token)
  );

  tokenFromLogout$ = this.actions$.pipe(
    ofType(SessionActions.logout),
    map(() => null)
  );

  token$ = inject(Store).select(SessionSelector.selectToken);
  managerToken$ = inject(Store).select(SessionSelector.selectManagerToken);

  connectSession$ = createEffect(() =>
    this.token$.pipe(
      debounceTime(200),
      startWith(null),
      pairwise(),
      mergeMap(([prevTopic, topic]) =>
        merge(
          ...[
            ...(prevTopic ? [of(WebsocketActions.disconnect({ topic: prevTopic }))] : []),
            ...(topic ? [of(WebsocketActions.connect({ topic })).pipe(delay(400))] : []),
          ]
        )
      )
    )
  );

  connectManagerSession$ = createEffect(() =>
    this.managerToken$.pipe(
      debounceTime(200),
      startWith(null),
      pairwise(),
      mergeMap(([prevTopic, topic]) =>
        merge(
          ...[
            ...(prevTopic ? [of(WebsocketActions.disconnect({ topic: prevTopic }))] : []),
            ...(topic ? [of(WebsocketActions.connect({ topic })).pipe(delay(400))] : []),
          ]
        )
      )
    )
  );

  events$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebsocketActions.message),
      map(({ payload }) => this.resolveAction(payload)),
      filterEmpty()
    )
  );

  constructor(private actions$: Actions) {}

  resolveAction(message: WebsocketData) {
    switch (message.type) {
      case WebsocketMessageType.WalletChange:
        return walletAction.loadAll({ wipe: true, pagerId: 'default' });
      case WebsocketMessageType.Changes:
        switch (message.target) {
          case WebsocketTarget.Ticket:
            switch (message.action) {
              case WebsocketAction.Edit:
                return new TicketActions.ReloadEntity(message.id);
              case WebsocketAction.Create:
                return new TicketActions.SingleLoad(message.id);
              case WebsocketAction.Delete:
                return new TicketActions.DeleteCompleted(message.id);
              case WebsocketAction.ReloadAll:
                return new TicketActions.ChangePager(
                  { maxCount: null },
                  this.resolveInstance(message.scope, message.id)
                );
              default:
                return null;
            }
          case WebsocketTarget.Song:
            switch (message.action) {
              case WebsocketAction.Edit:
                return new SongActions.ReloadEntity(message.id);
              case WebsocketAction.Create:
                return new SongActions.SingleLoad(message.id);
              case WebsocketAction.Delete:
                return new SongActions.DeleteCompleted(message.id);
              case WebsocketAction.ReloadAll:
                return new SongActions.ChangePager({ maxCount: null });
              default:
                return null;
            }
          case WebsocketTarget.Session:
            switch (message.action) {
              case WebsocketAction.Delete:
                return null;
              default:
                return SessionActions.refreshuser({ manager: false, userId: message.id });
            }

          case WebsocketTarget.Transaction:
            switch (message.action) {
              case WebsocketAction.Edit:
                return transactionAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return transactionAction.singleLoad({ id: message.id });
              case WebsocketAction.Delete:
                return transactionAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return transactionAction.changePager({
                  payload: { maxCount: null },
                  pagerId: this.resolveInstance(message.scope, message.id),
                });
              default:
                return null;
            }
          case WebsocketTarget.CampaignV2:
            switch (message.action) {
              case WebsocketAction.Edit:
                return new CampaignV2Actions.ReloadEntity(message.id);
              case WebsocketAction.Create:
                return new CampaignV2Actions.ChangePager({ hasNew: true });
              case WebsocketAction.Delete:
                return new CampaignV2Actions.DeleteCompleted(message.id);
              case WebsocketAction.ReloadAll:
                return new CampaignV2Actions.ResetState();
              default:
                return null;
            }
          case WebsocketTarget.Deal:
            switch (message.action) {
              case WebsocketAction.Edit:
                return new DealActions.ReloadEntity(message.id);
              case WebsocketAction.Create:
                return new DealActions.ChangePager({ maxCount: null });
              case WebsocketAction.Delete:
                return new DealActions.DeleteCompleted(message.id);
              case WebsocketAction.ReloadAll:
                return new DealActions.ResetState();
              default:
                return null;
            }
          case WebsocketTarget.DealLimit:
            switch (message.action) {
              case WebsocketAction.Edit:
                return dealLimitAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return dealLimitAction.changePager({ payload: { maxCount: null }, pagerId: 'default' });
              case WebsocketAction.Delete:
                return dealLimitAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return dealLimitAction.resetState();
              default:
                return null;
            }

          case WebsocketTarget.ManagedUser:
            switch (message.action) {
              case WebsocketAction.Edit:
                return SessionActions.reloadEntity({ id: message.id, relation: UserRelationType.managed });
              case WebsocketAction.Create:
                return SessionActions.changeAllPager({
                  payload: { maxCount: null },
                  matcher: (pager) => pager.search?.realtion === UserRelationType.managed,
                });
              case WebsocketAction.Delete:
                return SessionActions.deleteCompleted({ id: message.id, pagerId: 'managed' });
              case WebsocketAction.ReloadAll:
                return SessionActions.changeAllPager({
                  payload: { maxCount: null },
                  matcher: (pager) => pager.search?.realtion === UserRelationType.managed,
                });
              default:
                return null;
            }

          case WebsocketTarget.Manager:
            switch (message.action) {
              case WebsocketAction.Edit:
                return SessionActions.reloadEntity({ id: message.id, relation: UserRelationType.manager });
              case WebsocketAction.Create:
                return SessionActions.changeAllPager({
                  payload: { maxCount: null },
                  matcher: (pager) => pager.search?.realtion === UserRelationType.manager,
                });
              case WebsocketAction.Delete:
                return SessionActions.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return SessionActions.changeAllPager({
                  payload: { maxCount: null },
                  matcher: (pager) => pager.search?.realtion === UserRelationType.manager,
                });
              default:
                return null;
            }

          case WebsocketTarget.Team:
            switch (message.action) {
              case WebsocketAction.Edit:
                return teamAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return teamAction.changeAllPager({ payload: { maxCount: null } });
              case WebsocketAction.Delete:
                return teamAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return teamAction.changeAllPager({ payload: { maxCount: null } });
              default:
                return null;
            }

          case WebsocketTarget.Invite:
            switch (message.action) {
              case WebsocketAction.Edit:
                return managerInvitationAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return managerInvitationAction.changeAllPager({ payload: { maxCount: null } });
              case WebsocketAction.Delete:
                return managerInvitationAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return managerInvitationAction.changeAllPager({ payload: { maxCount: null } });
              default:
                return null;
            }

          case WebsocketTarget.Company:
            return new CompanyActions.SingleLoad();

          case WebsocketTarget.BankAccount:
            return new BankAccountActions.SingleLoad();

          case WebsocketTarget.SystemMessage:
            switch (message.action) {
              case WebsocketAction.Edit:
                return systemMessageAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return systemMessageAction.changeAllPager({ payload: { maxCount: null } });
              case WebsocketAction.Delete:
                return systemMessageAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return systemMessageAction.changeAllPager({ payload: { maxCount: null } });
              default:
                return null;
            }
          case WebsocketTarget.ManagerDeal:
            switch (message.action) {
              case WebsocketAction.Edit:
                return managerDealAction.reloadEntity({ id: message.id });
              case WebsocketAction.Create:
                return managerDealAction.changeAllPager({ payload: { maxCount: null } });
              case WebsocketAction.Delete:
                return managerDealAction.deleteCompleted({ id: message.id });
              case WebsocketAction.ReloadAll:
                return managerDealAction.changeAllPager({ payload: { maxCount: null } });
              default:
                return null;
            }
          default:
            return null;
        }
      default:
        return null;
    }
  }
  resolveInstance(scope: WebsocketTarget, id?: number | string) {
    if (!scope || !id) {
      return 'default';
    }
    const enumKey = Object.keys(WebsocketTarget).find((key) => WebsocketTarget[key] === scope);
    return `${this.lowercaseFirstChar(enumKey)}-${id}`;
  }
  lowercaseFirstChar(txt: string) {
    return `${txt[0].toLowerCase()}${txt.slice(1)}`;
  }
}
