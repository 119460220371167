import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { SpotifyArtistState } from '../reducers/spotify-artist.reducer';

export type SpotifyArtistStatePart = { spotifyArtist: SpotifyArtistState };

class SpotifyArtistSelectors extends BaseMultiPagerSelector<
  SpotifyArtistState,
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel
> {
  constructor() {
    super((state: SpotifyArtistStatePart): SpotifyArtistState => state.spotifyArtist);
  }
}

export const SpotifyArtistSelector = new SpotifyArtistSelectors();
