import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { CitySearchModel } from '../models/city-search.model';
import { CityModel } from '../models/city.model';

export abstract class CityServiceAbstract {
  abstract getPage(model: PagerEntity<CityModel, CitySearchModel>): Promise<PagerResponseContainer<CityModel>>;
  abstract get(id: number): Promise<ResponseContainer<CityModel>>;
}
