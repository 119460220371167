export const LOAD_PAGE = '[Song] load page';
export const LOAD_PAGE_COMPLETED = '[Song] load page completed';
export const SELECT_PAGE = '[Song] select page';
export const CHANGE_PAGER = '[Song] change pager';
export const SINGLE_LOAD = '[Song] single load';
export const SINGLE_LOAD_COMPLETED = '[Song] single load completed';
export const SELECT = '[Song] select';
export const DELETE = '[Song] delete';
export const DELETE_COMPLETED = '[Song] delete completed';
export const SEARCH = '[Song] search';
export const SEARCHING = '[Song] searching';
export const LOADALL = '[Song] load all';
export const LOADALL_COMPLETED = '[Song] load all completed';
export const RESETSTATE = '[Song] reset state';
export const RELOAD_ENTITY = '[Song] reload entity';
