import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../../tools/mapping/mappings';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { DealActions, DealActionType } from '../actions';

export class DealState {
  entities: { [key: number]: DealDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<DealDetailsModel, DealSearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<DealDetailsModel, DealSearchModel>;
}

const initialPager = new PagerEntity<DealDetailsModel, DealSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null, // should be more than limit
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new DealSearchModel({
    search: '',
    statusGroup: '',
  }),
});

const initialState: DealState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: { ['default']: initialPager, ['search']: Mappings.assign(initialPager, { id: 'search' }) },
  ids: [],
  initialPager,
};

export const dealReducer = (state: DealState = initialState, action: DealActions.All): DealState => {
  switch (action.type) {
    case DealActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case DealActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case DealActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case DealActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case DealActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case DealActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case DealActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case DealActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case DealActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
