<ion-toolbar>
  <ion-buttons slot="start" class="ml-0">
    <ng-container *ngIf="environment.hybrid && back">
      <ui-back-button></ui-back-button>
    </ng-container>

    <ng-content select="[header-buttons-start]"></ng-content>
  </ion-buttons>

  <ion-title *ngIf="logo">
    <a class="inline-block" routerLink="/">
      <ui-logo class="mx-auto !h-[48px] !w-[48px]"></ui-logo>
    </a>
  </ion-title>
  <ng-content></ng-content>

  <ion-buttons slot="end" class="mr-0">
    <ng-content select="[header-buttons-end]"></ng-content>
  </ion-buttons>
</ion-toolbar>
