import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { BkkrModule } from '@bkkr/angular';
import { IonContent, IonHeader } from '@ionic/angular/standalone';

import { HeaderComponent } from '../header';

@Component({
  selector: 'ui-layout-split',
  standalone: true,
  imports: [CommonModule, BkkrModule, HeaderComponent, IonHeader, IonContent],
  templateUrl: './layout-split.component.html',
  styleUrls: ['./layout-split.component.scss'],
})
export class LayoutSplitComponent {
  @Input()
  canGoBack = false;

  @Input()
  header: 'all' | 'mobile-only' | 'none' = 'none';
}
