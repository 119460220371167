import { NgModule } from '@angular/core';

import { InstagramServiceAbstract } from '../service/instagram.service.abstract';
import { InstagramService } from '../service/instagram.service';



@NgModule({
  providers: [{ provide: InstagramServiceAbstract, useClass: InstagramService }],
})
export class InstagramStateModule {}
