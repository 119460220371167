import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { SongCreateModel } from '../models/song-create.model';
import { SongDetailsModel } from '../models/song-details.model';
import { SongEditModel } from '../models/song-edit.model';
import { SongSearchModel } from '../models/song-search.model';

export abstract class SongServiceAbstract {
  abstract edit(model: SongEditModel, id: number): Promise<ResponseContainer<SongDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<SongDetailsModel, SongSearchModel>
  ): Promise<PagerResponseContainer<SongDetailsModel>>;
  abstract get(id: number | string): Promise<ResponseContainer<SongDetailsModel>>;
  abstract get(spotifyId: string): Promise<ResponseContainer<SongDetailsModel>>;
  abstract create(model: SongCreateModel): Promise<ResponseContainer<SongDetailsModel>>;
  abstract delete(id: number): Promise<ResponseContainer<unknown>>;
  // abstract download(name: string): Observable<{
  //   progress: HttpProgressEvent;
  //   response: Blob;
  // }>;
  // abstract upload(file: File): Observable<{
  //   progress: HttpProgressEvent;
  //   response: ResponseContainer<IdNameModel>;
  // }>;
}
