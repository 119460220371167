import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, catchError, filter, map, of, switchMap, take, takeWhile, tap, zip } from 'rxjs';

import { Store } from '@ngrx/store';


import { UserServiceAbstract } from '../../user/services/user.service.abstract';
import { SessionActions } from '../store/actions/session.actions';
import { SessionSelector } from '../store/selectors/session.selector';
import { keepPagerLoaded } from '../../tools/operators/keep-pager-loaded';

export const sessionManagerUserSelectdGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const store = inject(Store);
  const rawId = route.params.userId;

  const selectedId = parseInt(rawId);

  if (isNaN(selectedId)) {
    router.navigate(['404']);
    return of(false);
  }

  return isUserCollectionLoaded(store).pipe(
    takeWhile((loaded) => !loaded, true),
    filter((loaded) => loaded),
    switchMap(() => zip(hasManagerInStore(selectedId, store), isUserSelected(selectedId, store).pipe(take(1)))),
    tap(([hasUser, isUserSelected]) => {
      if (hasUser) {
        if (!isUserSelected) {
          store.dispatch(SessionActions.selectuser({ selectedId, skipNavigation: true }));
        }
      } else {
        router.navigate(['404']);
      }
    }),
    switchMap(([hasUser]) => {
      if (hasUser) {
        return isUserSelected(selectedId, store).pipe(
          takeWhile((selected) => !selected, true),
          filter((selected) => selected)
        );
      } else {
        return of(false);
      }
    }),
    take(1)
  );
};

export const isUserSelected = (userId: number, store = inject(Store)) =>
  store.select(SessionSelector.selectSelectedId).pipe(map((selectedId) => selectedId === userId));

export const isUserCollectionLoaded = (store = inject(Store)) =>
  store.select(SessionSelector.selectPager('managed')).pipe(
    keepPagerLoaded({
      updateAction: SessionActions.loadAll({ pagerId: 'managed', wipe: true }),
      store,
    }),
    map(({ maxCount }) => maxCount !== null)
  );

export const getDefaultUser = (store = inject(Store)) => store.select(SessionSelector.selectDefaultUser);

export const selectDefaultUser = (store = inject(Store)) => isUserCollectionLoaded(store);

export const hasManagerInStore = (id: number, store = inject(Store)) =>
  store.select(SessionSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const managerUserExistGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const userId = route.params.userId ?? route.params.id;
  return hasManagedUser(parseInt(userId, 10)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => {
      return (exist || null) ?? router.navigate(['404']);
    })
  );
};

export const hasManagedUserInStore = (id: number, store = inject(Store)) =>
  store.select(SessionSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasManagedUserInApi = (id: number, userService = inject(UserServiceAbstract), store = inject(Store)) =>
  userService.get(id).pipe(
    map((resp) => SessionActions.singleLoadCompleted({ payload: resp.values })),
    tap((action) => store.dispatch(action)),
    map((action) => !!action?.payload),
    catchError(() => of(false))
  );

export const hasManagedUser = (
  id: number,
  userService = inject(UserServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasManagedUserInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasManagedUserInApi(id, userService, store);
    })
  );
