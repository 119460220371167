/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import { SpotifyTrackState } from '../reducers/spotify-track.reducer';

export type SpotifyTrackStatePart = { spotifyTrack: SpotifyTrackState };

class SpotifyTrackSelectors extends BaseMultiPagerSelector<
  SpotifyTrackState,
  SpotifyTrackDetailsModel,
  SpotifyTrackSearchModel
> {
  constructor() {
    super((state: SpotifyTrackStatePart): SpotifyTrackState => state.spotifyTrack);
  }
}

export const SpotifyTrackSelector = new SpotifyTrackSelectors();
