import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';

import { ContentCategoryModel } from '../models/content-category.model';
import { SettingsSelector } from '../../settings/store/selectors';
import { ContentCategoryServiceAbstract } from './content-category.service.abstract';
import { filterEmpty } from '../../tools/map/filter-empty';


@Injectable()
export class ContentCategoryService extends ContentCategoryServiceAbstract {
  private contentCategorysUrl = this.apiUrl + 'datas/content-category';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string,
    private store: Store
  ) {
    super();
  }

  getAll(): Promise<ContentCategoryModel[]> {
    return this.store
      .select(SettingsSelector.getLanguage)
      .pipe(
        take(1),
        filterEmpty(),
        switchMap((lang) => this.http.get(this.contentCategorysUrl + '/' + lang + '.json')),
        map((resp) => plainToClass(ContentCategoryModel, resp as any[]))
      )
      .toPromise();
  }
}
