import { Injectable } from '@angular/core';

import { defer, Observable } from 'rxjs';
import { delayWhen, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';

import { SettingsSelector } from '../../../settings/store/selectors';
import { SubGenreActions, SubGenreActionType } from '../actions';
import { SubGenreSelector } from '../selectors';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { SubGenreModel } from '../../models/sub-genre.model';
import { SubGenreServiceAbstract } from '../../services/sub-genre.service.abstract';

@Injectable()
export class SubGenreEffects {
  openDB$: Observable<LocalForage> = defer(() => this.storage.ready()).pipe(
    delayWhen(() =>
      this.store.select(SettingsSelector.getLoaded).pipe(
        filter((loaded) => !!loaded),
        take(1)
      )
    )
  );

  initSubGenre$ = createEffect(() =>
    this.actions$.pipe(
      ofType('@ngrx/effects/init'),
      switchMap(() => this.openDB$),
      // load from db and from api
      mergeMap(() => [new SubGenreActions.LoadFromDb()])
    )
  );

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubGenreActionType.LOAD_FROM_DB),
      switchMap(() => this.openDB$),
      switchMap(() => this.storage.get(this.GENRE_KEY)),
      // filter non stored state
      filterEmpty(),
      map((plainState) => (plainState ? plainToClass(SubGenreModel, plainState as any[]) : null)),
      map((subGenres) => new SubGenreActions.LoadFromDbCompleted(subGenres))
    )
  );

  saveToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubGenreActionType.SAVE_TO_DB),
      mergeMap(() => this.openDB$),
      // get current
      mergeMap(() => this.store.select(SubGenreSelector.getAll).pipe(take(1))),
      // if there is no token we dont have to save
      mergeMap((state) => this.storage.set(this.GENRE_KEY, state)),
      map(() => new SubGenreActions.SaveToDbCompleted())
    )
  );

  loadFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubGenreActionType.LOAD),
      mergeMap(() => this.subGenreService.getAll()),
      map((subGenres) => new SubGenreActions.LoadCompleted(subGenres))
    )
  );

  loadCompletedToSave = createEffect(() =>
    this.actions$.pipe(
      ofType(SubGenreActionType.LOAD_COMPLETED),
      map(() => new SubGenreActions.SaveToDb())
    )
  );

  private readonly GENRE_KEY = 'sub-genres';
  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: Storage,
    private subGenreService: SubGenreServiceAbstract
  ) {}
}
