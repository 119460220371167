import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { TicketDealCreateModel } from '../../models/ticket-deal-create.model';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';

export abstract class TicketServiceAbstract {
  //  abstract edit(model: TicketEditModel, id: number): Promise<ResponseContainer<TicketDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<TicketDetailsModel, TicketSearchModel>
  ): Promise<PagerResponseContainer<TicketDetailsModel>>;
  abstract get(id: number): Promise<ResponseContainer<TicketDetailsModel>>;
  abstract createWithDeal(model: TicketDealCreateModel): Promise<ResponseContainer<TicketDetailsModel>>;
}
