import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import * as TicketActionType from './ticket.actions.type';

export class ReloadEntity implements Action {
  readonly type = TicketActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = TicketActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = TicketActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: TicketDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}

export class SelectPage implements Action {
  readonly type = TicketActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default') {}
}

export class ChangePager implements Action {
  readonly type = TicketActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<TicketDetailsModel, TicketSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = TicketActionType.SEARCH;
  constructor(
    public payload: TicketSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = TicketActionType.SEARCHING;
  constructor(
    public payload: TicketSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = TicketActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = TicketActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: TicketDetailsModel) {}
}
export class Select implements Action {
  readonly type = TicketActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = TicketActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = TicketActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = TicketActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = TicketActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<TicketDetailsModel, TicketSearchModel>,
    public payload: TicketDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = TicketActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
