import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import * as CompanyActionType from './company.actions.type';

export class ReloadEntity implements Action {
  readonly type = CompanyActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = CompanyActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = CompanyActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: CompanyDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}

export class SelectPage implements Action {
  readonly type = CompanyActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = CompanyActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<CompanyDetailsModel, CompanySearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = CompanyActionType.SEARCH;
  constructor(
    public payload: CompanySearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = CompanyActionType.SEARCHING;
  constructor(
    public payload: CompanySearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = CompanyActionType.SINGLE_LOAD;
  constructor(public id?: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = CompanyActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: CompanyDetailsModel) {}
}
export class Select implements Action {
  readonly type = CompanyActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = CompanyActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = CompanyActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = CompanyActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = CompanyActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<CompanyDetailsModel, CompanySearchModel>,
    public payload: CompanyDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = CompanyActionType.RESETSTATE;
}

export class ReorderLocal implements Action {
  readonly type = CompanyActionType.REORDER_LOCAL;
  constructor(public from: number, public to: number, public slaveScheduleId: number, public pagerId: string) {}
}
export class ReorderRemote implements Action {
  readonly type = CompanyActionType.REORDER_REMOTE;
  constructor(public pagerId: string, public slaveScheduleId: number) {}
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState
  | ReorderLocal;
