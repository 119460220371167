import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionActions } from '../store/actions';
import { SessionSelector } from '../store/selectors/session.selector';
import { filterEmpty } from '../../tools/map/filter-empty';

export const sessionManagerRefreshGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  return store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded === true),
    switchMap(() => store.select(SessionSelector.selectManager)),
    filterEmpty(),
    tap(({ fromdb }) => (fromdb === true ? store.dispatch(SessionActions.refreshuser({ manager: true })) : null)),
    filter((user) => user.fromdb === false),
    map(() => true),
    take(1)
  );
};
