import { Injectable } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { debounceTime, filter, first, map, mergeMap, take } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { CityServiceAbstract } from '../../services/city.service.abstract';
import { CityActionType, CityActions } from '../actions';
import { CitySelector } from '../selectors/city.selector';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class CityEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.SELECT_PAGE),
      mergeMap(async (action: CityActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      mergeMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.cityService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new CityActions.LoadPageCompleted(page, response.values.maxCount, response.values.results, action.pagerId)
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: CityActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new CityActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.SEARCHING),
      mergeMap((action: CityActions.Searching) => zip(of(action), this.getCitys(action.pagerId))),
      map(([action, resp]) => new CityActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.LOADALL),
      mergeMap((action: CityActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: CityModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.cityService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new CityActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => new CityActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.SINGLE_LOAD),
      mergeMap(async (action: CityActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.cityService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CityActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActionType.RELOAD_ENTITY),
      mergeMap(async (action: CityActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.cityService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new CityActions.SingleLoadeCompleted(resp.values))
    )
  );

  getPager = this.store.select(CitySelector.getPagers).pipe(take(1));
  getIds = this.store.select(CitySelector.getIds).pipe(take(1));

  constructor(private actions$: Actions, private cityService: CityServiceAbstract, private store: Store) {}

  getSelectedPager(instance: string): Observable<PagerEntity<CityModel, CitySearchModel>> {
    return this.store.select(CitySelector.getPager(instance)).pipe(first());
  }

  getSearchModel(instance: string): Observable<CitySearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getCitys(instance: string): Observable<PagerResponse<CityModel>> {
    return this.getSelectedPager(instance).pipe(
      first(),
      mergeMap((pager) => this.cityService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
}
