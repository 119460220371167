import { BaseStaticSelector } from '../../../common/store/selectors/base-static-selector';
import { GenreModel } from '../../models/genre.model';
import { GenreState } from '../reducers/genre.reducer';

export type GenreStatePart = { genre: GenreState };
class GenreSelectors extends BaseStaticSelector<GenreState, GenreModel> {
  constructor() {
    super((state: GenreStatePart): GenreState => state.genre);
  }
}

export const GenreSelector = new GenreSelectors();
