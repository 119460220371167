import { createSelector } from '@ngrx/store';

import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { CountryState } from '../reducers/country.reducer';

export type CountryStatePart = { country: CountryState };
class CountrySelectors extends BaseMultiPagerSelector<CountryState, CountryModel, CountrySearchModel> {
  getKnownIds = createSelector(this.getState, (state) => state.knownIds);

  constructor() {
    super((state: CountryStatePart): CountryState => state.country);
  }
}

export const CountrySelector = new CountrySelectors();
