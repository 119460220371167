import { Component, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { take } from 'rxjs';

import { BkkrModule, DrawerController } from '@bkkr/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SettingsSelector } from '@songpush/core/settings/store/selectors';

import { LanguageSelectModalComponent } from '../language-select-modal';

@Component({
  selector: 'shared-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  standalone: true,
  imports: [CommonModule, BkkrModule, TranslateModule],
})
export class LanguageSelectComponent {
  private drawerCtrl = inject(DrawerController);
  private store = inject(Store);

  language$ = this.store.select(SettingsSelector.getLanguage);

  async openDrawer() {
    const completedEmitter = new EventEmitter();
    const drawer = await this.drawerCtrl.create({
      component: LanguageSelectModalComponent,
      closeIcon: 'check',
      componentProps: {
        completed: completedEmitter,
      },
    });
    completedEmitter.pipe(take(1)).subscribe(() => drawer.dismiss());
    drawer.present();
  }
}
