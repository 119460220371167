import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../../tools/mapping/mappings';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { BankAccountDetailsModel } from '../../models/bank-account-details.model';
import { BankAccountSearchModel } from '../../models/bank-account-search.model';
import { BankAccountActionType, BankAccountActions } from '../actions';


export class BankAccountState {
  entities: { [key: number]: BankAccountDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>;
}
const initialState: BankAccountState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 50,
    asc: true,
    orderBy: 'id',
    search: new BankAccountSearchModel({
      search: '',
    }),
  }),
};

export const bankAccountReducer = (
  state: BankAccountState = initialState,
  action: BankAccountActions.All
): BankAccountState => {
  switch (action.type) {
    case BankAccountActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case BankAccountActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case BankAccountActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case BankAccountActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case BankAccountActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case BankAccountActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case BankAccountActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case BankAccountActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case BankAccountActionType.RESETSTATE:
      return initialState;

    case BankAccountActionType.UPDATE_MANDATE:
      return Mappings.assign(state, {
        entities: { [state.ids[0]]: Mappings.assign(state.entities[state.ids[0]], { mandate: action.payload }) },
      });

    // case BankAccountActionType.REORDER_LOCAL:
    //   return ReducerTools.reorderLocal(action, state, state.initialPager);

    default:
      return state;
  }
};
