import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { filter, map, switchMap, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';


import { UserRole } from '../models/user-role';
import { checkRoles } from '../tools/role-tools/check-roles';
import { SessionSelector } from '../../session/store/selectors/session.selector';

export const managerRoleGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);
  return store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded),
    switchMap(() => store.select(SessionSelector.selectManagerRoles)),
    filter((roles) => roles !== BigInt(0)),
    map((userRoles) => checkRoles(userRoles, getPathRoles(route))),
    tap((canNavigate) => {
      if (!canNavigate) {
        router.navigate(['403'], { queryParams: { redirect: state.url } });
      }
    })
  );
};

const getPathRoles = (route: ActivatedRouteSnapshot): UserRole[] => route.data.roles || [];
