import { NgModule, makeEnvironmentProviders } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideState } from '@ngrx/store';

import { systemMessageReducer } from '../store/system-message.reducer';
import { SystemMessageEffects } from '../store/system-message.effects';
import { SystemMessageServiceAbstract } from '../providers/system-message.service.abstract';
import { SystemMessageService } from '../providers/system-message.service';



export const provideSystemMessageState = () =>
  makeEnvironmentProviders([
    provideState('systemMessage', systemMessageReducer),
    provideEffects([SystemMessageEffects]),
    { provide: SystemMessageServiceAbstract, useClass: SystemMessageService },
  ]);

@NgModule({
  imports: [
    EffectsModule.forFeature([SystemMessageEffects]),
    StoreModule.forFeature('systemMessage', systemMessageReducer),
  ],
  providers: [{ provide: SystemMessageServiceAbstract, useClass: SystemMessageService }],
})
export class SystemMessageStateModule {}
