import { Injectable } from '@angular/core';

import { defer } from 'rxjs';
import { delayWhen, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';

import { SettingsSelector } from '../../../settings/store/selectors';
import { LanguageModel } from '../../models/language.model';
import { LanguageServiceAbstract } from '../../services/language.service.abstract';
import { LanguageActions, LanguageActionType } from '../actions';
import { LanguageSelector } from '../selectors/language.selector';
import { filterEmpty } from '../../../tools/map/filter-empty';


@Injectable()
export class LanguageEffects {
  openDB$ = defer(() => this.storage.ready()).pipe(
    delayWhen(() =>
      this.store.select(SettingsSelector.getLoaded).pipe(
        filter((loaded) => !!loaded),
        take(1)
      )
    )
  );

  initLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType('@ngrx/effects/init'),
      switchMap(() => this.openDB$),
      // load from db and from api
      mergeMap(() => [new LanguageActions.LoadFromDb()])
    )
  );

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionType.LOAD_FROM_DB),
      switchMap(() => this.openDB$),
      switchMap(() => this.storage.get(this.GENRE_KEY)),
      // filter non stored state
      filterEmpty(),
      map((plainState) => (plainState ? plainToClass(LanguageModel, plainState as any[]) : null)),
      map((languages) => new LanguageActions.LoadFromDbCompleted(languages))
    )
  );

  saveToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionType.SAVE_TO_DB),
      mergeMap(() => this.openDB$),
      // get current
      mergeMap(() => this.store.select(LanguageSelector.getAll).pipe(take(1))),
      // if there is no token we dont have to save
      mergeMap((state) => this.storage.set(this.GENRE_KEY, state)),
      map(() => new LanguageActions.SaveToDbCompleted())
    )
  );

  loadFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionType.LOAD),
      mergeMap(() => this.languageService.getAll()),
      map((languages) => new LanguageActions.LoadCompleted(languages))
    )
  );

  loadCompletedToSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActionType.LOAD_COMPLETED),
      map(() => new LanguageActions.SaveToDb())
    )
  );

  private readonly GENRE_KEY = 'languages';

  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: Storage,
    private languageService: LanguageServiceAbstract
  ) {}
}
