import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { managerInvitationAction } from './manager-invitation.action';

export type ManagerInvitationState = IHasPagerEntities<ManagerInvitationDetailsModel, ManagerInvitationSearchModel>;
const initialPager = new PagerEntity<ManagerInvitationDetailsModel, ManagerInvitationSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: true,
  orderBy: 'id',
  search: new ManagerInvitationSearchModel({}),
});

const managerInvitationInitialState: ManagerInvitationState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const managerInvitationReducer = createPagerReducer({
  details: ManagerInvitationDetailsModel,
  filter: ManagerInvitationSearchModel,
  initialState: managerInvitationInitialState,
  actions: managerInvitationAction,
  source: 'ManagerInvitation',
});
