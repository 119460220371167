import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BkkrModule } from '@bkkr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IonButtons, IonTitle, IonToolbar } from '@ionic/angular/standalone';

import { ENVIRONMENT } from '@songpush/core/common/tokens';

import { BackButtonComponent } from '../back-button';
import { LogoComponent } from '../logo';

@Component({
  selector: 'ui-header',
  standalone: true,
  imports: [
    CommonModule,
    BkkrModule,
    TranslateModule,
    RouterModule,
    LogoComponent,
    BackButtonComponent,
    IonToolbar,
    IonButtons,
    IonTitle,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public readonly environment = inject(ENVIRONMENT);

  @Input()
  back = false;

  @Input()
  logo = !this.environment.hybrid;
}
