import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { CompanyActions, CompanyActionType } from '../actions';

export class CompanyState {
  entities: { [key: number]: CompanyDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<CompanyDetailsModel, CompanySearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<CompanyDetailsModel, CompanySearchModel>;
}

const initialPager = new PagerEntity<CompanyDetailsModel, CompanySearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null, // should be more than limit
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CompanySearchModel({
    search: '',
    campaignId: '',
    slaveScheduleId: '',
  }),
});

const initialState: CompanyState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager,
};

export const companyReducer = (state: CompanyState = initialState, action: CompanyActions.All): CompanyState => {
  switch (action.type) {
    case CompanyActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case CompanyActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case CompanyActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case CompanyActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case CompanyActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case CompanyActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case CompanyActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case CompanyActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case CompanyActionType.RESETSTATE:
      return initialState;

    // case CompanyActionType.REORDER_LOCAL:
    //   return ReducerTools.reorderLocal(action, state, state.initialPager);

    default:
      return state;
  }
};
