import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';

import { BkkrModule } from '@bkkr/angular';
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SettingsLanguage } from '@songpush/core/settings/models';
import { SettingsActions } from '@songpush/core/settings/store/actions';
import { SettingsSelector } from '@songpush/core/settings/store/selectors';
import { FlagComponent } from '@songpush/ui/flag';

@Component({
  selector: 'shared-language-select-form',
  templateUrl: './language-select-form.component.html',
  styleUrls: ['./language-select-form.component.scss'],
  standalone: true,
  imports: [CommonModule, BkkrModule, TranslateModule, FlagComponent, IonRadioGroup, IonRadio, IonItem, IonLabel],
})
export class LanguageSelectFormComponent {
  private store = inject(Store);

  @Output()
  completed = new EventEmitter();

  selectedLanguage$ = this.store.select(SettingsSelector.getLanguage);

  SettingsLanguage = SettingsLanguage;

  onComplete(event) {
    const language = event.detail.value;
    this.store.dispatch(SettingsActions.changeLanguage({ language }));

    this.completed.emit(language);
  }
}
