import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { DealState } from '../reducers/deal.reducer';

export type DealStatePart = { deal: DealState };

export const getDealState = (state: DealStatePart): DealState => state.deal;

class DealSelectors extends BaseMultiPagerSelector<DealState, DealDetailsModel, DealSearchModel> {
  constructor() {
    super((state: DealStatePart): DealState => state.deal);
  }
}

export const DealSelector = new DealSelectors();
