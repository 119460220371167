import { Injectable } from '@angular/core';

import { Observable, interval, of, zip } from 'rxjs';
import { bufferToggle, debounceTime, filter, groupBy, map, mergeAll, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';






import { SocialBlockActionType, SocialBlockActions } from '../actions';
import { SocialBlockSelector } from '../selectors';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { Mappings } from '../../../tools/mapping/mappings';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { SocialBlockServiceAbstract } from '../../services/providers/social-block.service.abstract';
import { FormValidatorServiceAbstract } from '../../../form/services/form-validator/form-validator.service.abstract';
import { PagerEntity } from '../../../common/models/PagerEntity';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { IChangePagerAction } from '../../../tools/reducer-helper/model/change-pager';
import { ILoadAllAction } from '../../../tools/reducer-helper/model/load-all';

@Injectable()
export class SocialBlockEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.SELECT_PAGE),
      mergeMap(async (action: SocialBlockActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      mergeMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.socialBlockService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new SocialBlockActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: SocialBlockActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new SocialBlockActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.SEARCHING),
      mergeMap((action: SocialBlockActions.Searching) => zip(of(action), this.getSocialBlocks(action.pagerId))),
      map(([action, resp]) => new SocialBlockActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  deleteSocialBlock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.DELETE),
      mergeMap(async (action: SocialBlockActions.Delete) => ({
        action,
        response: await this.socialBlockService.delete(action.id),
      })),
      tap(({ response }) =>
        response.status.globalMessage ? this.formValidator.showToast(response.status.globalMessage) : null
      ),
      filter(({ action, response }) => response?.status?.success),
      map(({ action }) => new SocialBlockActions.DeleteCompleted(action.id))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.LOADALL),
      mergeMap((action: SocialBlockActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: SocialBlockDetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.socialBlockService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return new SocialBlockActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new SocialBlockActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.SINGLE_LOAD),
      mergeMap(async (action: SocialBlockActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.socialBlockService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SocialBlockActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.RELOAD_ENTITY),
      mergeMap(async (action: SocialBlockActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.socialBlockService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SocialBlockActions.SingleLoadeCompleted(resp.values))
    )
  );

  autoTriggerReorderRemote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocialBlockActionType.REORDER_LOCAL),
      map((action: SocialBlockActions.ReorderLocal) => action),
      groupBy((action) => action.pagerId),
      map((group) => group.pipe(debounceTime(3000))),
      mergeAll(),
      map((action) => new SocialBlockActions.ReorderRemote(action.pagerId, action.slaveScheduleId))
    )
  );

  getPager = this.store.select(SocialBlockSelector.getPagers).pipe(take(1));
  getIds = this.store.select(SocialBlockSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private socialBlockService: SocialBlockServiceAbstract,
    private store: Store,
    private formValidator: FormValidatorServiceAbstract
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>> {
    return this.store.select(SocialBlockSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<SocialBlockSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getSocialBlocks(instance: string): Observable<PagerResponse<SocialBlockDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.socialBlockService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
  getLoadComplete() {
    return this.actions$.pipe(
      ofType(
        SocialBlockActionType.SINGLE_LOAD_COMPLETED,
        SocialBlockActionType.LOADALL_COMPLETED,
        SocialBlockActionType.LOAD_PAGE_COMPLETED
      )
    );
  }

  resolveEntities<T, Z>(
    key: keyof SocialBlockDetailsModel,
    changePager: ClassConstructor<IChangePagerAction<T, Z>>,
    search: ClassConstructor<Z>,
    loadAll: ClassConstructor<ILoadAllAction>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>
  ) {
    return this.getLoadComplete().pipe(
      mergeMap(
        (
          action:
            | SocialBlockActions.SingleLoadeCompleted
            | SocialBlockActions.LoadAllCompleted
            | SocialBlockActions.LoadPageCompleted
        ) => (Array.isArray(action.payload) ? action.payload : [action.payload])
      ),
      bufferToggle(this.getLoadComplete(), () => interval(10)),
      filter((e) => e.length > 0),
      mergeMap((socialBlocks) =>
        zip(
          ...socialBlocks
            .map((socialBlock) => socialBlock[key] as number)
            .reduce((ids, id) => (ids.includes(id) ? ids : [...ids, id]), [] as number[])
            .filter((id) => !!id)
            .map((id) => checkExist(id))
        )
      ),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) =>
        this.store.dispatch(
          new changePager(
            {
              search: new search({
                ids,
              }),
            },
            ids.join(',')
          )
        )
      ),
      tap((ids) => this.store.dispatch(new loadAll(ids.join(','))))
    );
  }
}
