import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import {
  ClassConstructor,
  ClassTransformOptions,
  instanceToPlain,
  plainToClassFromExist,
} from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../mapping/mappings';
import { IHasId } from './model/i-has-id';
import { Identifier } from './model/identifier';

export abstract class PagerServiceAbstract<
  DetailsModel extends IHasId,
  FilterModel,
  CreateModel,
  EditModel
> {
  abstract url: string;
  abstract detailsModel: ClassConstructor<DetailsModel>;
  abstract filterModel: ClassConstructor<FilterModel>;
  abstract createModel: ClassConstructor<CreateModel>;
  abstract editModel: ClassConstructor<EditModel>;
  protected isManagerResource = false;

  protected get getPageUrl() {
    return this.url;
  }
  protected get getSingleUrl() {
    return this.url;
  }
  protected get getDeleteUrl() {
    return this.url;
  }
  protected get getEditUrl() {
    return this.url;
  }

  protected get getPageClassTransformOptions(): ClassTransformOptions {
    return null;
  }
  protected get getEditClassTransformOptions(): ClassTransformOptions {
    return null;
  }
  protected get getCreateClassTransformOptions(): ClassTransformOptions {
    return null;
  }

  protected http = inject(HttpClient);

  protected getParams(
    params = new HttpParams(),
    isManagerResource = this.isManagerResource
  ) {
    return isManagerResource ? params.append('managerResource', true) : params;
  }

  getPage(
    pager: PagerEntity<DetailsModel, FilterModel>
  ): Observable<PagerResponseContainer<DetailsModel>> {
    const params = this.getParams(
      Mappings.pagerToDomain(pager, this.getPageClassTransformOptions)
    );

    return this.http
      .get(this.getPageUrl, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(this.detailsModel),
            resp
          )
        )
      );
  }

  get(id?: number): Observable<ResponseContainer<DetailsModel>> {
    const params = this.getParams();
    return this.http
      .get(`${this.getSingleUrl}/${id}`, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(this.detailsModel), resp)
        )
      );
  }

  create(model: CreateModel): Observable<ResponseContainer<DetailsModel>> {
    const domain = instanceToPlain(model, this.getCreateClassTransformOptions);
    const params = this.getParams();
    return this.http
      .post(this.url, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(this.detailsModel), resp)
        )
      );
  }

  delete(id: Identifier): Observable<ResponseContainer<unknown>> {
    const params = this.getParams();
    return this.http
      .delete(`${this.getDeleteUrl}/${id}`, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      );
  }

  edit(
    model: EditModel,
    id: Identifier
  ): Observable<ResponseContainer<DetailsModel>> {
    const params = this.getParams();
    const domain = instanceToPlain(model, this.getEditClassTransformOptions);
    return this.http
      .put(`${this.getEditUrl}/${id}`, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(this.detailsModel), resp)
        )
      );
  }
}
