export const LOAD_PAGE = '[Deal] load page';
export const LOAD_PAGE_COMPLETED = '[Deal] load page completed';
export const SELECT_PAGE = '[Deal] select page';
export const CHANGE_PAGER = '[Deal] change pager';
export const SINGLE_LOAD = '[Deal] single load';
export const SINGLE_LOAD_COMPLETED = '[Deal] single load completed';
export const SELECT = '[Deal] select';
export const DELETE = '[Deal] delete';
export const DELETE_COMPLETED = '[Deal] delete completed';
export const SEARCH = '[Deal] search';
export const SEARCHING = '[Deal] searching';
export const LOADALL = '[Deal] load all';
export const LOADALL_COMPLETED = '[Deal] load all completed';
export const RESETSTATE = '[Deal] reset state';
export const RELOAD_ENTITY = '[Deal] reload entity';
