import { Injectable } from '@angular/core';

import { debounceTime, shareReplay } from 'rxjs';

import { Store } from '@ngrx/store';

import { PagerEntity } from '@songpush/core/common/models';
import { DealDetailsModel, DealSearchModel, DealStatus } from '@songpush/core/deal/models';
import { keepScopeLoaded } from '@songpush/core/tools/operators';
import { DealSelector } from '@songpush/core/deal/store/selectors';
import { DealActions } from '@songpush/core/deal/store/actions';



@Injectable()
export class DealFacade {
  private statusCreatedTarget: Partial<PagerEntity<DealDetailsModel, DealSearchModel>> = {
    search: new DealSearchModel({
      status: DealStatus.created,
    }),
  };

  private statusCreatedScopeId = `status-${this.statusCreatedTarget.search.status}`;

  statusCreatedScope$ = this.store.select(DealSelector.getScope(`${this.statusCreatedScopeId}`)).pipe(
    debounceTime(0),
    keepScopeLoaded({
      store: this.store,
      changePagerAction: new DealActions.ChangePager(
        {
          ...this.statusCreatedTarget,
          maxCount: null,
        },
        this.statusCreatedScopeId
      ),
      target: this.statusCreatedTarget,
      updateAction: new DealActions.LoadAll(this.statusCreatedScopeId),
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(private store: Store) {}
}
