
import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { StatDetailsModel } from '../models/stat-details.model';
import { StatSearchDealModel, StatSearchModel } from '../models/stat-search.model';
import { statAction } from './stat.action';

export type StatState = IHasPagerEntities<StatDetailsModel, StatSearchModel>;
const initialPager = new PagerEntity<StatDetailsModel, StatSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null,
  limit: 50,
  asc: true,
  orderBy: 'id',
  search: new StatSearchDealModel({}),
});

const statInitialState: StatState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const statReducer = createPagerReducer({
  details: StatDetailsModel,
  filter: StatSearchModel,
  initialState: statInitialState,
  actions: statAction,
  source: 'Stat',
});
