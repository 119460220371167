import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';

import { analyticsAction } from './analytics.actions';
import { SessionActions } from '../../session/store/actions/session.actions';

@Injectable()
export class AnalyticsEffects {
  protected readonly actions = inject(Actions);

  navigation$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerNavigatedAction),
      map((action) => analyticsAction.pageview({ url: action.payload.event.urlAfterRedirects, title: undefined }))
    )
  );

  login$ = createEffect(() =>
    this.actions.pipe(
      ofType(SessionActions.login, SessionActions.loadfromdbcompleted, SessionActions.selectusersuccess),
      map((action) => {
        switch (action.type) {
          case SessionActions.login.type:
            return action.user.id;
          case SessionActions.loadfromdbcompleted.type:
            return action.state?.selectedId;
        }
      }),
      map((userId) => analyticsAction.login({ userId }))
    )
  );

  logout$ = createEffect(() =>
    this.actions.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => analyticsAction.logout())
    )
  );
}
