import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';

export abstract class CountryServiceAbstract {
  abstract getPage(model: PagerEntity<CountryModel, CountrySearchModel>): Promise<PagerResponseContainer<CountryModel>>;
  abstract get(id: number): Promise<ResponseContainer<CountryModel>>;
}
