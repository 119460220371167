
import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { TeamDetailsModel } from '../models/team-details.model';
import { TeamSearchModel } from '../models/team-search.model';
import { teamAction } from './team.action';

export type TeamState = IHasPagerEntities<TeamDetailsModel, TeamSearchModel>;
const initialPager = new PagerEntity<TeamDetailsModel, TeamSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null,
  limit: 50,
  asc: true,
  orderBy: 'id',
  search: new TeamSearchModel({}),
});

const teamInitialState: TeamState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const teamReducer = createPagerReducer({
  details: TeamDetailsModel,
  filter: TeamSearchModel,
  initialState: teamInitialState,
  actions: teamAction,
  source: 'Team',
});
