import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { TicketState } from '../reducers/ticket.reducer';

export type TicketStatePart = { ticket: TicketState };
class TicketSelectors extends BaseMultiPagerSelector<TicketState, TicketDetailsModel, TicketSearchModel> {
  constructor() {
    super((state: TicketStatePart): TicketState => state.ticket);
  }
}

export const TicketSelector = new TicketSelectors();
