import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { UserServiceAbstract } from '../services/user.service.abstract';

@Injectable({
  providedIn: 'root',
})
export class UserPasswordHashExistGuard implements CanActivate {
  constructor(private userService: UserServiceAbstract, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const hash: string = route.params.hash;

    const exist = await this.userService
      .forgotPasswordHashExist(hash)
      .then((resp) => resp?.status?.success && resp.values.exist);

    if (!exist) {
      void this.router.navigate(['404', 'USER_FORGOT_PASSWORD_HASH_NOT_EXIST']);
    }

    return exist;
  }
}
