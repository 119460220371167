import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@webed/angular-tooltip';
import { IonBackButton } from '@ionic/angular/standalone';

import { ENVIRONMENT } from '@songpush/core/common/tokens';



@Component({
  selector: 'ui-back-button',
  standalone: true,
  imports: [CommonModule, IonBackButton, TranslateModule, TooltipModule],
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  public readonly environment = inject(ENVIRONMENT);

  @Input()
  defaultHref?: string;

  @Input()
  disabled?: boolean;
}
