/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from '@ngrx/store';

import { getTiktokSoundUrlRegex } from '@songpush/validators/tools/tiktok-sound-url-regex';

import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { Mappings } from '../../../tools/mapping/mappings';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import { SongState } from '../reducers/song.reducer';

export type SongStatePart = { song: SongState };

const getSongIdType = (id: number | string) => {
  const tiktokRegex = getTiktokSoundUrlRegex();
  if (tiktokRegex.test(`${id}`)) {
    return 'tiktok';
  }
  if (isNaN(id as number)) {
    return 'spotify';
  }
  return 'internal';
};

export class SongSelectors extends BaseMultiPagerSelector<
  SongState,
  SongDetailsModel,
  SongSearchModel
> {
  constructor() {
    super((state: SongStatePart): SongState => state.song);
  }

  getBySpotifyId = (spotifyId: string) =>
    createSelector(this.getList, (list) =>
      list.find((item) => item.spotifyTrackId === spotifyId)
    );

  getByTiktokUrl = (tiktokUrl: string) =>
    createSelector(this.getList, (list) =>
      list.find((item) => item.tiktokUrl === tiktokUrl)
    );

  getByUnkownId = (id: number | string) => {
    switch (getSongIdType(id)) {
      case 'internal':
        return this.getItem(id as number);
      case 'spotify':
        return this.getBySpotifyId(id as string);
      case 'tiktok':
        return this.getByTiktokUrl(Mappings.getBaseUrl(id as string));
    }
  };
}

export const SongSelector = new SongSelectors();
