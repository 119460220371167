import { NgModule } from '@angular/core';

import { InviteServiceAbstract } from '../service/inivite.service.abstract';
import { InviteService } from '../service/inivite.service';



@NgModule({
  providers: [{ provide: InviteServiceAbstract, useClass: InviteService }],
})
export class InviteStateModule {}
