import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, filter, groupBy, map, merge, mergeMap, take, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { FormValidatorServiceAbstract } from '../../form/services/form-validator/form-validator.service.abstract';
import { Mappings } from '../mapping/mappings';
import { IHasId } from './model/i-has-id';
import { PagerActions } from './model/pager-actions';
import { PagerSelector } from './model/pager-selector';
import { PagerServiceAbstract } from './pager-service';

export abstract class PagerEffects<K extends string, DetailsModel extends IHasId, FilterModel, EditModel, CreateModel> {
  protected formValidator = inject(FormValidatorServiceAbstract);
  protected actions$ = inject(Actions);
  protected store = inject(Store);
  protected router = inject(Router);

  selectPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.selectPage),
      mergeMap((action) =>
        this.getPager(action.pagerId).pipe(
          map((pager) => ({
            page: action?.selectedPageNum ?? pager.selectedPageNum,
            pager,
            action,
          }))
        )
      ),
      mergeMap(({ page, pager, action }) =>
        this.pagerService.getPage(pager).pipe(
          map((response) => ({
            response,
            page,
            action,
          }))
        )
      ),
      map(({ response, page, action }) =>
        this.actions.loadPageCompleted({
          page,
          maxCount: response.values.maxCount,
          payload: response.values.results,
          pagerId: action.pagerId,
          wipe: action.wipe,
        })
      )
    )
  );

  loadPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadPage),
      mergeMap((action) =>
        this.getPager(action.pagerId).pipe(
          map((pager) => ({
            page: action.page || pager.selectedPageNum,
            pager,
            action,
          }))
        )
      ),
      mergeMap(({ page, pager, action }) =>
        this.pagerService.getPage(pager).pipe(
          map((response) => ({
            response,
            page,
            action,
          }))
        )
      ),
      map(({ response, page, action }) =>
        this.actions.loadPageCompleted({
          page,
          maxCount: response.values.maxCount,
          payload: response.values.results,
          pagerId: action.pagerId,
          wipe: action.wipe,
        })
      )
    )
  );

  loadAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadAll),
      mergeMap((action) => this.getPager(action.pagerId).pipe(map((pager) => ({ pager, action })))),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: DetailsModel[] = [];

        while (pager.maxCount === null || pager.limit * (pager.selectedPageNum - 1) < pager.maxCount) {
          const resp = await this.pagerService.getPage(pager).toPromise();
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return this.actions.loadAllCompleted({
          pager,
          payload,
          pagerId: action.pagerId,
        });
      })
    )
  );

  singleLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.singleLoad),
      mergeMap((action) => this.getIds().pipe(map((ids) => ({ action, ids })))),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.pagerService.get(action.id as number)),
      filter((resp) => !!resp?.values),
      map((resp) => this.actions.singleLoadCompleted({ payload: resp.values }))
    )
  );

  reloadEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.reloadEntity),
      mergeMap((action) => this.getIds().pipe(map((ids) => ({ action, ids })))),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.pagerService.get(action.id as number)),
      filter((resp) => !!resp?.values),
      map((resp) => this.actions.singleLoadCompleted({ payload: resp.values }))
    )
  );

  delete: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.delete),
      mergeMap((action) => this.pagerService.delete(action.id).pipe(map((response) => ({ response, action })))),
      groupBy(({ response }) => response?.status?.success),
      mergeMap((groups) =>
        merge(
          groups.pipe(
            filter(({ response }) => response?.status?.success),
            map(({ action }) => this.actions.deleteCompleted({ id: action.id }))
          ),
          groups.pipe(
            filter(({ response }) => !response?.status?.success),
            tap(({ response }) =>
              this.formValidator.showToast(
                response?.status?.globalMessage || this.formValidator.translate('GLOBAL_ERROR_UNKNOWN')
              )
            ),
            map(({ action }) => this.actions.deleteFailed({ id: action.id }))
          )
        )
      )
    )
  );

  getPagers = () => this.store.select(this.featureSelector.selectPagers).pipe(take(1));
  getIds = () => this.store.select(this.featureSelector.selectIds).pipe(take(1));
  getAll = () => this.store.select(this.featureSelector.selectAll).pipe(take(1));

  constructor(
    protected actions: PagerActions<K, DetailsModel, FilterModel>,
    protected featureSelector: PagerSelector<K, DetailsModel, FilterModel, any>,
    protected pagerService: PagerServiceAbstract<DetailsModel, FilterModel, CreateModel, EditModel>
  ) {}
  getPager(instance: string): Observable<PagerEntity<DetailsModel, FilterModel>> {
    return this.store.select(this.featureSelector.selectPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<FilterModel> {
    return this.getPager(instance).pipe(map((pager) => pager.search));
  }

  getItems(instance: string): Observable<PagerResponseContainer<DetailsModel>> {
    return this.getPager(instance).pipe(
      mergeMap((pager) => this.pagerService.getPage(pager)),
      map((resp) => resp)
    );
  }
}
