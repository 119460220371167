import { inject } from '@angular/core';

import { map, mergeMap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';


import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { TransactionEditModel } from '../models/transaction-edit.model';
import { transactionAction } from './transaction.action';
import { transactionSelector } from './transaction.selector';
import { transactionDefaultPager } from './transaction.reducer';
import { SessionActions } from '../../session/store/actions/session.actions';
import { TransactionCreateModel } from '../models/transaction-create.model';
import { TransactionSearchModel } from '../models/transaction-search.model';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { walletAction } from '../../wallet/store/wallet.action';
import { Mappings } from '../../tools/mapping/mappings';
import { WalletType } from '../../wallet/models/wallet-type';
import { TransactionServiceAbstract } from '../providers/transaction.service.abstract';

export class TransactionEffects extends PagerEffects<
  'Transaction',
  TransactionDetailsModel,
  TransactionSearchModel,
  TransactionEditModel,
  TransactionCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutcompleted),
      map(() => transactionAction.resetState())
    )
  );

  onLoadWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletAction.loadAllCompleted),
      mergeMap(({ payload }) => [
        transactionAction.changeAllPager({
          matcher: () => true,
          payload: {
            search: Mappings.assign(transactionDefaultPager.search, {
              walletId: payload
                .filter(({ type }) => [WalletType.payin, WalletType.payout].includes(type))
                .map(({ id }) => id),
            }),
            maxCount: null,
          },
        }),
      ])
    )
  );

  onUserSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectusersuccess),
      map(() =>
        transactionAction.changeAllPager({
          payload: { maxCount: null },
          matcher: (pager) => (pager.id + '').startsWith('user'),
        })
      )
    )
  );

  constructor() {
    super(transactionAction, transactionSelector, inject(TransactionServiceAbstract));
  }
}
