

import { FlowProgressItemModel } from '@songpush/core/flow/model';
import { Mappings } from '@songpush/core/tools/mapping';


import { FlowProgressActionType, FlowProgressActions } from '../actions';
import { FlowOpts } from '../../models/flow-opts';

export interface FlowProgressState extends FlowOpts {
  ids: number[];
  entities: { [key: number]: FlowProgressItemModel };
}

const initState: FlowProgressState = {
  ids: [],
  entities: {},
  selected: null,
  filled: null,
  collapse: null,
  canLeave: true,
};

export const FlowProgressReducer = (state = initState, action: FlowProgressActions.All): FlowProgressState => {
  switch (action.type) {
    case FlowProgressActionType.INIT:
      return Mappings.assign(state, {
        ...addPayloadToState(initState, action.payload),
        ...action.opts,
      });

    case FlowProgressActionType.CHANGEOPTS:
      return Mappings.assign(state, action.payload as Partial<FlowProgressState>);

    case FlowProgressActionType.EDIT:
      return Mappings.assign(state, editEntity(state, action.payload));

    case FlowProgressActionType.CLEANUP:
      return Mappings.assign(state, cleanUpEntities(state, action.ids));

    // case FlowProgressActionType.INITIALIZED:
    //   return Mappings.assign(state, { initialized: true });

    default:
      return state;
  }
};

const addPayloadToState = (state: FlowProgressState, payload: FlowProgressItemModel[]): Partial<FlowProgressState> => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((item) => item.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
});

const editEntity = (
  state: FlowProgressState,
  payload: Partial<FlowProgressItemModel> & { id: number }
): Partial<FlowProgressState> => ({
  entities: Mappings.assign(state.entities, {
    [payload.id]: Mappings.assign(state.entities[payload.id], payload),
  }),
});

const cleanUpEntities = (state: FlowProgressState, ids: number[]) =>
  addPayloadToState(
    state,
    ids.map((id) => state.entities[id]).map((ent) => cleanUpEntity(ent))
  );

const cleanUpEntity = (entity: FlowProgressItemModel) => {
  const { ...cleaned } = entity;
  return cleaned;
};
