import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import * as CountryActionType from './country.actions.type';

export class ReloadEntity implements Action {
  readonly type = CountryActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = CountryActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = CountryActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: CountryModel[],
    public pagerId: string = 'default',
    public wipe: boolean = false
  ) {}
}

export class SelectPage implements Action {
  readonly type = CountryActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = CountryActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<CountryModel, CountrySearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = CountryActionType.SEARCH;
  constructor(
    public payload: CountrySearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = CountryActionType.SEARCHING;
  constructor(
    public payload: CountrySearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = CountryActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = CountryActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: CountryModel) {}
}
export class Select implements Action {
  readonly type = CountryActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = CountryActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = CountryActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = CountryActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = CountryActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<CountryModel, CountrySearchModel>,
    public payload: CountryModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = CountryActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
