import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { UserServiceAbstract } from '../services/user.service.abstract';
import { UserActions } from '../store/actions';
import { UserSelector } from '../store/selectors/user.selector';

export const userExistGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const userId = route.params.userId ?? route.params.id;
  return hasUser(parseInt(userId, 10)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => {
      return (exist || null) ?? router.navigate(['404']);
    })
  );
};

export const hasUserInStore = (id: number, store = inject(Store)) =>
  store.select(UserSelector.getItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasUserInApi = (id: number, userService = inject(UserServiceAbstract), store = inject(Store)) =>
  of(userService.get(id)).pipe(
    mergeMap((promise) => promise),
    map((user) => new UserActions.SingleLoadeCompleted(user.values)),
    tap((action: UserActions.SingleLoadeCompleted) => store.dispatch(action)),
    map((action) => !!action?.payload),
    catchError(() => of(false))
  );

export const hasUser = (
  id: number,
  userService = inject(UserServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasUserInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasUserInApi(id, userService, store);
    })
  );
