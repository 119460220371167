import { NgModule, makeEnvironmentProviders } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideState } from '@ngrx/store';

import { statReducer } from '../store/stat.reducer';
import { StatEffects } from '../store/stat.effects';
import { StatServiceAbstract } from '../providers/stat.service.abstract';
import { StatService } from '../providers/stat.service';


@NgModule({
  imports: [EffectsModule.forFeature([StatEffects]), StoreModule.forFeature('stat', statReducer)],
  providers: [{ provide: StatServiceAbstract, useClass: StatService }],
})
export class StatStateModule {}

export const provideStatState = () =>
  makeEnvironmentProviders([
    provideState('stat', statReducer),
    provideEffects([StatEffects]),
    { provide: StatServiceAbstract, useClass: StatService },
  ]);
