import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform, inject } from '@angular/core';

import { map, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { checkRoles } from '../tools/role-tools/check-roles';
import { teamSelector } from '../../team/store/team.selector';
import { UserRole } from '../models/user-role';

@Pipe({
  name: 'teamRole',
  standalone: true,
  pure: false,
})
export class TeamRolePipe implements PipeTransform {
  store = inject(Store);
  roles$ = this.store.select(SessionSelector.selectedTeamId).pipe(
    switchMap((teamId) => this.store.select(teamSelector.selectItem(teamId))),
    map((team) => team?.roles || BigInt(0))
  );
  cdr = inject(ChangeDetectorRef, { skipSelf: true });

  transform(role: UserRole) {
    return new AsyncPipe(this.cdr).transform(this.roles$.pipe(map((userRoles) => checkRoles(userRoles, [role]))));
  }
}
