import { BaseMultiPagerSelector } from '../../../common/store';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { CityState } from '../reducers/city.reducer';

export type CityStatePart = { city: CityState };

export const getCityState = (state: CityStatePart): CityState => state.city;

class CitySelectors extends BaseMultiPagerSelector<CityState, CityModel, CitySearchModel> {
  constructor() {
    super((state: CityStatePart): CityState => state.city);
  }
}

export const CitySelector = new CitySelectors();
