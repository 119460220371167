import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { InvoiceActions, InvoiceActionType } from '../actions';

export class InvoiceState {
  entities: { [key: number]: InvoiceDetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<InvoiceDetailsModel, InvoiceSearchModel>;
  };
  initialPager: PagerEntity<InvoiceDetailsModel, InvoiceSearchModel>;
  ids: number[];
}
const initialState: InvoiceState = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    limit: 50,
    asc: false,
    orderBy: 'id',
    search: new InvoiceSearchModel({
      search: '',
    }),
  }),
};

export const invoiceReducer = (state: InvoiceState = initialState, action: InvoiceActions.All): InvoiceState => {
  switch (action.type) {
    case InvoiceActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case InvoiceActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case InvoiceActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case InvoiceActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case InvoiceActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case InvoiceActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case InvoiceActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case InvoiceActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case InvoiceActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
