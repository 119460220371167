import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';
import { SpotifyTrackDetailsModel } from '../models/spotify-track-details.model';
import { SpotifyTrackEditModel } from '../models/spotify-track-edit.model';
import { SpotifyTrackSearchModel } from '../models/spotify-track-search.model';
import { SpotifyTrackServiceAbstract } from './spotify-track.service.abstract';

@Injectable()
export class SpotifyTrackService extends SpotifyTrackServiceAbstract {
  private readonly spotifyTrackrl = this.apiUrl + 'spotify-song';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  edit(model: SpotifyTrackEditModel, id: string): Promise<ResponseContainer<SpotifyTrackDetailsModel>> {
    throw 'not supported';
  }

  getPage(
    pager: PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>
  ): Promise<PagerResponseContainer<SpotifyTrackDetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.spotifyTrackrl, { params } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(SpotifyTrackDetailsModel), resp)))
      .toPromise();
  }
  get(id: string): Promise<ResponseContainer<SpotifyTrackDetailsModel>> {
    return this.http
      .get(`${this.spotifyTrackrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(SpotifyTrackDetailsModel), resp)))
      .toPromise();
  }
  delete(id: string): Promise<ResponseContainer<unknown>> {
    throw 'not supported';
  }
}
