import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { combineLatest, defer, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { FormValidatorServiceAbstract } from '../../form/services/form-validator';
import { UserServiceAbstract } from '../../user/services';
import { SessionSelector } from '../store/selectors/session.selector';
import { keepPageScopeLoaded } from '../../tools/operators/keep-page-scope-loaded';
import { SessionActions } from '../store/actions/session.actions';
import { Identifier } from '../../tools/reducer-helper/model/identifier';


@Injectable()
export class SessionManagedFacade {
  store = inject(Store);
  actions$ = inject(Actions);
  validator = inject(FormValidatorServiceAbstract);
  userService = inject(UserServiceAbstract);

  scope$ = this.store.select(SessionSelector.selectScope('managed')).pipe(
    keepPageScopeLoaded({
      store: this.store,
      updateAction: SessionActions.loadAll({ pagerId: 'managed', wipe: true }),
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  pager$ = this.scope$.pipe(
    map(({ pager }) => pager),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  selectDefaultUser = (redirectUrl: string = null) =>
    combineLatest([
      this.store.select(SessionSelector.selectSelectedId),
      this.scope$.pipe(filter(({ pager: { maxCount } }) => maxCount !== null)),
    ]).pipe(
      take(1),
      map(([selectedId, { all }]) => selectedId ?? all[0]?.id),
      tap((id) => this.store.dispatch(SessionActions.selectuser({ selectedId: id, redirectUrl }))),
      switchMap(() =>
        this.actions$.pipe(ofType(SessionActions.selectusersuccess, SessionActions.selectuserfailed)).pipe(take(1))
      )
    );

  selectDefaultUserExcept = (exceptUserId: Identifier) =>
    combineLatest([
      this.store.select(SessionSelector.selectSelectedId),
      this.scope$.pipe(filter(({ pager: { maxCount } }) => maxCount !== null)),
    ]).pipe(
      take(1),
      map(([selectedId, { all }]) =>
        selectedId && selectedId !== exceptUserId ? selectedId : all.find(({ id }) => id !== exceptUserId)?.id
      ),
      tap((id) => this.store.dispatch(SessionActions.selectuser({ selectedId: id }))),
      switchMap(() =>
        this.actions$.pipe(
          ofType(SessionActions.selectusersuccess, SessionActions.selectuserfailed, SessionActions.emptyselecteduser),
          take(1)
        )
      )
    );

  removeUser = (userId: Identifier, fg: FormGroup = null) =>
    defer(() => this.validator.validate(fg, async () => await this.userService.deleteUser(userId))).pipe(
      filter(({ status: { success } }) => success),
      switchMap(() => this.selectDefaultUserExcept(userId)),
      tap(() =>
        this.store.dispatch(
          SessionActions.changePager({
            payload: { maxCount: null },
            pagerId: 'managed',
          })
        )
      )
    );
}
