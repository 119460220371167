import { BaseStaticSelector } from '../../../common/store/selectors/base-static-selector';
import { MoodModel } from '../../models/mood.model';
import { MoodState } from '../reducers/mood.reducer';

export type MoodStatePart = { mood: MoodState };

class MoodSelectors extends BaseStaticSelector<MoodState, MoodModel> {
  constructor() {
    super((state: MoodStatePart): MoodState => state.mood);
  }
}

export const MoodSelector = new MoodSelectors();
