export const LOAD_PAGE = '[SpotifyArtist] load page';
export const LOAD_PAGE_COMPLETED = '[SpotifyArtist] load page completed';
export const SELECT_PAGE = '[SpotifyArtist] select page';
export const CHANGE_PAGER = '[SpotifyArtist] change pager';
export const SINGLE_LOAD = '[SpotifyArtist] single load';
export const SINGLE_LOAD_COMPLETED = '[SpotifyArtist] single load completed';
export const SELECT = '[SpotifyArtist] select';
export const DELETE = '[SpotifyArtist] delete';
export const DELETE_COMPLETED = '[SpotifyArtist] delete completed';
export const SEARCH = '[SpotifyArtist] search';
export const SEARCHING = '[SpotifyArtist] searching';
export const LOADALL = '[SpotifyArtist] load all';
export const LOADALL_COMPLETED = '[SpotifyArtist] load all completed';
export const RESETSTATE = '[SpotifyArtist] reset state';
