import { Injectable } from '@angular/core';

import { defer, Observable } from 'rxjs';
import { delayWhen, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToInstance } from 'class-transformer';

import { SettingsSelector } from '../../../settings/store/selectors';
import { ContentCategoryModel } from '../../models/content-category.model';
import { ContentCategoryServiceAbstract } from '../../services/content-category.service.abstract';
import { ContentCategoryActions, ContentCategoryActionType } from '../actions';
import { ContentCategorySelector } from '../selectors/content-category.selector';
import { filterEmpty } from '../../../tools/map/filter-empty';


@Injectable()
export class ContentCategoryEffects {
  openDB$: Observable<LocalForage> = defer(() => this.storage.ready()).pipe(
    delayWhen(() =>
      this.store.select(SettingsSelector.getLoaded).pipe(
        filter((loaded) => !!loaded),
        take(1)
      )
    )
  );

  initContentCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType('@ngrx/effects/init'),
      switchMap(() => this.openDB$),
      // load from db and from api
      mergeMap(() => [new ContentCategoryActions.LoadFromDb()])
    )
  );

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentCategoryActionType.LOAD_FROM_DB),
      switchMap(() => this.openDB$),
      switchMap(() => this.storage.get(this.GENRE_KEY)),
      // filter non stored state
      filterEmpty(),
      map((plainState) => (plainState ? plainToInstance(ContentCategoryModel, plainState as any[]) : null)),
      map((subGenres) => new ContentCategoryActions.LoadFromDbCompleted(subGenres))
    )
  );

  saveToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentCategoryActionType.SAVE_TO_DB),
      mergeMap(() => this.openDB$),
      // get current
      mergeMap(() => this.store.select(ContentCategorySelector.getAll).pipe(take(1))),
      // if there is no token we dont have to save
      mergeMap((state) => this.storage.set(this.GENRE_KEY, state)),
      map(() => new ContentCategoryActions.SaveToDbCompleted())
    )
  );

  loadFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentCategoryActionType.LOAD),
      mergeMap(() => this.subGenreService.getAll()),
      map((subGenres) => new ContentCategoryActions.LoadCompleted(subGenres))
    )
  );

  loadCompletedToSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentCategoryActionType.LOAD_COMPLETED),
      map(() => new ContentCategoryActions.SaveToDb())
    )
  );

  private readonly GENRE_KEY = 'content-categorys';

  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: Storage,
    private subGenreService: ContentCategoryServiceAbstract
  ) {}
}
