import { createSelector } from '@ngrx/store';

import { IHasStaticEntities } from '../../../tools/reducer-helper/model/has-static-entities';

export class BaseStaticSelector<T extends IHasStaticEntities<U>, U> {
  getEntities = createSelector(this.getState, (state) => state.entities);
  getLoaded = createSelector(this.getState, (state) => state.loaded);
  getIds = createSelector(this.getState, (state) => state.ids);

  getAll = createSelector(this.getEntities, this.getIds, (ents, ids) => ids.map((id) => ents[id]));

  constructor(public getState: (state: unknown) => T) {}
}
