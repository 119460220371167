import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform, inject } from '@angular/core';

import { map } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { checkRoles } from '../tools/role-tools/check-roles';
import { filterEmpty } from '../../tools/map/filter-empty';
import { UserRole } from '../models/user-role';

@Pipe({
  name: 'userRole',
  standalone: true,
  pure: false,
})
export class UserRolePipe implements PipeTransform {
  roles$ = inject(Store).select(SessionSelector.selectUserRolesOrManagerRoles).pipe(filterEmpty());
  cdr = inject(ChangeDetectorRef, { skipSelf: true });

  transform(role: UserRole) {
    return new AsyncPipe(this.cdr).transform(this.roles$.pipe(map((userRoles) => checkRoles(userRoles, [role]))));
  }
}
