import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { TranslateParser } from '@ngx-translate/core';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { interpolateString } from '../../tools/interpolate-string/interpolate-string';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2Status } from '../models/campaign-v2-status';
import { CampaignV2Selector, CampaignV2StatePart } from '../store/selectors/campaign-v2.selector';
import { filterEmpty } from '../../tools/map/filter-empty';


@Injectable()
export class CampaignV2StatusGuard implements CanActivate {
  constructor(private store: Store<CampaignV2StatePart>, private translate: TranslateParser, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const campaignId = parseInt(route.params.campaignId);
    const campaignStatuses: CampaignV2Status[] = route.data.campaignStatuses || [];

    const redirectConfig = route.data.campaignStatusRedirect;

    return this.hasStatus(campaignId, campaignStatuses).pipe(
      tap(
        (exist) =>
          (exist || null) ??
          this.resolveUrlByConfig(campaignId, redirectConfig).subscribe((url) => this.router.navigateByUrl(url))
      )
    );
  }

  resolveUrlByConfig(campaignId: number, redirectConfig: Map<CampaignV2Status, string>) {
    return combineLatest([this.getCampaign(campaignId), this.store.select(SessionSelector.selectPathPrefix)]).pipe(
      take(1),
      map(([campaign, sessionPrefix]) =>
        interpolateString(redirectConfig[campaign.status], { ...campaign, sessionPrefix })
      )
    );
  }

  getCampaign(id: number): Observable<CampaignV2DetailsModel> {
    return this.store.select(CampaignV2Selector.getItem(id)).pipe(filterEmpty(), take(1));
  }

  hasStatus(campaignId: number, campaignStatuses: CampaignV2Status[]) {
    return this.getCampaign(campaignId).pipe(map(({ status }) => campaignStatuses.includes(status)));
  }
}
