import { Observable } from 'rxjs';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ReorderModel } from '../../common/models/reorder.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { BankAccountCreateModel } from '../models/bank-account-create.model';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountEditModel } from '../models/bank-account-edit.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { BankAccountMandateModel } from '../models/bank-account-mandate.model';

export abstract class BankAccountServiceAbstract {
  abstract edit(model: BankAccountEditModel, id?: number): Promise<ResponseContainer<BankAccountDetailsModel>>;
  abstract create(model: BankAccountCreateModel): Promise<ResponseContainer<BankAccountDetailsModel>>;
  abstract getPage(
    pager: PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>
  ): Promise<PagerResponseContainer<BankAccountDetailsModel>>;
  abstract get(id?: number): Promise<ResponseContainer<BankAccountDetailsModel>>;
  abstract delete(id: number): Promise<ResponseContainer<unknown>>;
  abstract reorder(model: ReorderModel, id: number): Promise<ResponseContainer<unknown>>;
  abstract checkTaxNumber(taxNumber: string, countryISO: string): Promise<boolean>;
  abstract createMandate(): Observable<ResponseContainer<BankAccountMandateModel>>;
}
