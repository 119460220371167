import { createAction, emptyProps, props } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { IHasId } from './model/i-has-id';
import { Identifier } from './model/identifier';

export const createPagerActionGroup = <K extends string, DetailsModel extends IHasId, SearchModel>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    reloadEntity: createAction(`[${source}] reloadEntity`, props<{ id: Identifier }>()),
    loadPage: createAction(`[${source}] loadPage`, props<{ page: number; pagerId?: string; wipe?: boolean }>()),
    loadPageCompleted: createAction(
      `[${source}] loadPageCompleted`,
      props<{
        page: number;
        maxCount: number;
        payload: DetailsModel[];
        pagerId?: string;
        wipe?: boolean;
      }>()
    ),
    selectPage: createAction(
      `[${source}] selectPage`,
      props<{
        selectedPageNum: number;
        pagerId: string;
        wipe?: boolean;
      }>()
    ),
    changePager: createAction(
      `[${source}] changePager`,
      props<{
        payload: Partial<PagerEntity<DetailsModel, SearchModel>>;
        pagerId: string;
      }>()
    ),
    singleLoad: createAction(
      `[${source}] singleLoad`,
      props<{
        id: Identifier;
      }>()
    ),
    singleLoadCompleted: createAction(
      `[${source}] singleLoadCompleted`,
      props<{
        payload: DetailsModel;
        skipCheck?: boolean;
      }>()
    ),
    delete: createAction(
      `[${source}] delete`,
      props<{
        id: Identifier;
        redirect?: boolean;
      }>()
    ),
    deleteCompleted: createAction(
      `[${source}] deleteCompleted`,
      props<{ id: Identifier; pagerId?: string; redirect?: boolean }>()
    ),
    deleteFailed: createAction(`[${source}] deleteFailed`, props<{ id: Identifier }>()),
    loadAll: createAction(`[${source}] loadAll`, props<{ pagerId: string; wipe?: boolean }>()),
    loadAllCompleted: createAction(
      `[${source}] loadAllCompleted`,
      props<{
        pager: PagerEntity<DetailsModel, SearchModel>;
        payload: DetailsModel[];
        pagerId: string;
      }>()
    ),
    resetState: createAction(`[${source}] resetState`, emptyProps),
    changeAllPager: createAction(
      `[${source}] changeAllPager`,
      props<{
        payload: Partial<PagerEntity<DetailsModel, SearchModel>>;
        matcher?: (pager: PagerEntity<DetailsModel, SearchModel>) => boolean;
      }>()
    ),
  };
};

export type PagerActionGroup<K extends string, DetailsModel extends IHasId, SearchModel> = ReturnType<
  typeof createPagerActionGroup<K, DetailsModel, SearchModel>
>;
