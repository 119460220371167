import { Action, ActionReducer } from '@ngrx/store';

import { CommonActionType } from '@songpush/core/common/store/actions';
import { Mappings } from '@songpush/core/tools/mapping';



export type MetaReducer = (reducer: ActionReducer<any, Action>) => ActionReducer<any, Action>;

export const patchStoreMetaReducer: MetaReducer =
  (reducer: ActionReducer<any, Action>) => (state: any, action: Action) => {
    if (action.type === CommonActionType.PATCH_STORE) {
      return Mappings.assign(state, (action as any).state);
    }
    return reducer(state, action);
  };
