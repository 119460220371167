<ion-back-button
  [text]="'GLOBAL_BACK' | translate"
  [tooltip]="'GLOBAL_BACK' | translate"
  placement="right"
  [showDelay]="200"
  [hideDelay]="0"
  [displayTouchscreen]="false"
  [disabled]="disabled"
  [defaultHref]="defaultHref ?? '/'"
  [mode]="!environment.hybrid ? 'md' : null"
></ion-back-button>
