import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import * as SpotifyTrackActionType from './spotify-track.actions.type';

export class ReloadEntity implements Action {
  readonly type = SpotifyTrackActionType.RELOAD_ENTITY;
  constructor(public id: string) {}
}
export class LoadPage implements Action {
  readonly type = SpotifyTrackActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = SpotifyTrackActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: SpotifyTrackDetailsModel[],
    public pagerId: string = 'default',
    public wipe?: boolean
  ) {}
}

export class SelectPage implements Action {
  readonly type = SpotifyTrackActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = SpotifyTrackActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = SpotifyTrackActionType.SEARCH;
  constructor(
    public payload: SpotifyTrackSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = SpotifyTrackActionType.SEARCHING;
  constructor(
    public payload: SpotifyTrackSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = SpotifyTrackActionType.SINGLE_LOAD;
  constructor(public id: string) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = SpotifyTrackActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: SpotifyTrackDetailsModel) {}
}
export class Select implements Action {
  readonly type = SpotifyTrackActionType.SELECT;
  constructor(public id: string) {}
}
export class Delete implements Action {
  readonly type = SpotifyTrackActionType.DELETE;
  constructor(public id: string) {}
}
export class DeleteCompleted implements Action {
  readonly type = SpotifyTrackActionType.DELETE_COMPLETED;
  constructor(public id: string) {}
}
export class LoadAll implements Action {
  readonly type = SpotifyTrackActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = SpotifyTrackActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>,
    public payload: SpotifyTrackDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = SpotifyTrackActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
