import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { CommonActionType } from '@songpush/core/common/store/actions';



@Injectable()
export class ParentStateGuard implements CanActivate {
  private first = false;

  constructor(private store: Store) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.first) {
      this.first = true;
      const initialState = await this.getInitialState();
      if (initialState) {
        this.store.dispatch(initialState);
        window.opener = null;
      }
    }
    return true;
  }

  async getInitialState() {
    let stateRef = null;
    try {
      if (!!window.opener && window.opener !== window && window.origin === window.opener.origin) {
        let resolveRef;
        const handleFn = (event) => {
          if (event.data?.type === CommonActionType.PATCH_STORE) {
            resolveRef(event?.data);
          }
        };
        stateRef = await new Promise<void>((resolve) => {
          resolveRef = resolve;
          window.addEventListener('message', handleFn);
          setTimeout(() => resolve(), 2000);
        });
        window.removeEventListener('message', handleFn);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    return stateRef;
  }
}
