/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class PagerEntity<T, U extends Partial<{ search?: string }>> {
  @Exclude()
  id: Identifier;
  @Exclude()
  pages: {
    [key: number]: (number | string)[];
    [key: string]: (number | string)[];
  };
  @Expose({ name: 'skip', toPlainOnly: true })
  @Expose({ toClassOnly: true })
  @Transform((opts) => ((opts.value ?? 1) - 1) * opts.obj.limit, {
    toPlainOnly: true,
  })
  @Transform((opts) => (isNaN(opts.value) ? null : parseInt(opts.value, 10)), {
    toClassOnly: true,
  })
  selectedPageNum: number;
  @Exclude()
  maxCount: number;
  @Expose({ toPlainOnly: true })
  @Transform((opts) => (opts.value ? parseInt(opts.value, 10) : null))
  limit: number;
  @Expose({ name: 'order', toPlainOnly: true })
  @Expose({ toClassOnly: true })
  orderBy: keyof T | '';
  @Expose()
  @Transform((opts) =>
    opts.value === 'true'
      ? true
      : opts.value === 'false'
      ? false
      : (opts.value as boolean)
  )
  asc: boolean;
  @Expose({ toPlainOnly: true })
  @Type(({ newObject }) => (newObject as PagerEntity<T, U>).type)
  search: U;

  @Exclude()
  hasNew?: boolean;

  @Exclude()
  public type: new (...args: unknown[]) => U;

  constructor(ent?: Partial<PagerEntity<T, U>>) {
    if (!ent) {
      return;
    }
    Object.assign(this, ent);
    if (ent.search?.constructor) {
      this.type = ent.search.constructor as typeof this.type;
    }
  }
}
