import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { instanceToPlain, plainToClassFromExist } from 'class-transformer';

import { getTaxNumberRegex } from '@songpush/validators/tools/tax-number-regex';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { ReorderModel } from '../../../common/models/reorder.model';
import { Mappings } from '../../../tools/mapping/mappings';
import { SocialBlockAddMany } from '../../models/social-block-add-many.model';
import { SocialBlockCreateModel } from '../../models/social-block-create.model';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockEditModel } from '../../models/social-block-edit.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { SocialBlockServiceAbstract } from './social-block.service.abstract';

@Injectable()
export class SocialBlockService extends SocialBlockServiceAbstract {
  private readonly socialBlockUrl = this.apiUrl + 'user/blacklist';
  private readonly socialBlockAddManyUrl = this.apiUrl + 'user/blacklist/all';
  private readonly reorderUrl = `${this.apiUrl}user/blacklist/reorder`;
  protected isManagerResource = true;
  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  protected getParams(
    params = new HttpParams(),
    isManagerResource = this.isManagerResource
  ) {
    return isManagerResource ? params.append('managerResource', true) : params;
  }

  create(
    model: SocialBlockCreateModel
  ): Promise<ResponseContainer<SocialBlockDetailsModel>> {
    const params = this.getParams();
    const domain = instanceToPlain(model);
    return this.http
      .post(this.socialBlockUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(SocialBlockDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  edit(
    model: SocialBlockEditModel,
    id?: number
  ): Promise<ResponseContainer<SocialBlockDetailsModel>> {
    const params = this.getParams();
    const domain = instanceToPlain(model);
    return this.http
      .put(id ? `${this.socialBlockUrl}/${id}` : this.socialBlockUrl, domain, {
        params,
      })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(SocialBlockDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  addMany(
    model: SocialBlockAddMany
  ): Promise<ResponseContainer<SocialBlockDetailsModel>> {
    const params = this.getParams();
    const domain = instanceToPlain(model);

    return this.http
      .post(this.socialBlockAddManyUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(SocialBlockDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }

  getPage(
    pager: PagerEntity<SocialBlockDetailsModel, SocialBlockSearchModel>
  ): Promise<PagerResponseContainer<SocialBlockDetailsModel>> {
    const params: unknown = this.getParams(Mappings.pagerToDomain(pager));
    return this.http
      .get(this.socialBlockUrl, { params } as any)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(SocialBlockDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  get(id?: number): Promise<ResponseContainer<SocialBlockDetailsModel>> {
    const params = this.getParams();
    return this.http
      .get(id ? `${this.socialBlockUrl}/${id}` : `${this.socialBlockUrl}`, {
        params,
      })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(SocialBlockDetailsModel),
            resp
          )
        )
      )
      .toPromise();
  }
  delete(id: number): Promise<ResponseContainer<unknown>> {
    const params = this.getParams();
    return this.http
      .delete(`${this.socialBlockUrl}/${id}`, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  reorder(
    model: ReorderModel,
    id: number
  ): Promise<ResponseContainer<unknown>> {
    const domainModel = instanceToPlain(model);
    const params = this.getParams();
    return this.http
      .put(`${this.reorderUrl}/${id}`, domainModel, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp))
      )
      .toPromise();
  }

  checkTaxNumber(taxNumber: string, countryISO: string) {
    return Promise.resolve(
      !!getTaxNumberRegex(countryISO)?.exec?.(taxNumber?.toLowerCase())
    );
  }
}
