import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { BankAccountDetailsModel } from '../../models/bank-account-details.model';
import { BankAccountSearchModel } from '../../models/bank-account-search.model';
import * as BankAccountActionType from './bank-account.actions.type';
import { BankAccountMandateModel } from '../../models/bank-account-mandate.model';

export class ReloadEntity implements Action {
  readonly type = BankAccountActionType.RELOAD_ENTITY;
  constructor(public id?: number) {}
}
export class LoadPage implements Action {
  readonly type = BankAccountActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = BankAccountActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: BankAccountDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}

export class SelectPage implements Action {
  readonly type = BankAccountActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = BankAccountActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = BankAccountActionType.SEARCH;
  constructor(
    public payload: BankAccountSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = BankAccountActionType.SEARCHING;
  constructor(
    public payload: BankAccountSearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = BankAccountActionType.SINGLE_LOAD;
  constructor(public id?: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = BankAccountActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: BankAccountDetailsModel) {}
}
export class Select implements Action {
  readonly type = BankAccountActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = BankAccountActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = BankAccountActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = BankAccountActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = BankAccountActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<BankAccountDetailsModel, BankAccountSearchModel>,
    public payload: BankAccountDetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = BankAccountActionType.RESETSTATE;
}

export class ReorderLocal implements Action {
  readonly type = BankAccountActionType.REORDER_LOCAL;
  constructor(public from: number, public to: number, public slaveScheduleId: number, public pagerId: string) {}
}
export class ReorderRemote implements Action {
  readonly type = BankAccountActionType.REORDER_REMOTE;
  constructor(public pagerId: string, public slaveScheduleId: number) {}
}
export class UpdateMandate implements Action {
  readonly type = BankAccountActionType.UPDATE_MANDATE;
  constructor(public payload: BankAccountMandateModel) {}
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState
  | ReorderLocal
  | UpdateMandate;
