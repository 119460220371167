import { Action } from '@ngrx/store';

import { LanguageModel } from '../../models/language.model';
import * as LanguageActionTypes from './language.actions.type';

export class LoadFromDb implements Action {
  readonly type = LanguageActionTypes.LOAD_FROM_DB;
}
export class LoadFromDbCompleted implements Action {
  readonly type = LanguageActionTypes.LOAD_FROM_DB_COMPLETED;
  constructor(public payload: LanguageModel[]) {}
}
export class SaveToDb implements Action {
  readonly type = LanguageActionTypes.SAVE_TO_DB;
}
export class SaveToDbCompleted implements Action {
  readonly type = LanguageActionTypes.SAVE_TO_DB_COMPLETED;
}
export class Load implements Action {
  readonly type = LanguageActionTypes.LOAD;
}
export class LoadCompleted implements Action {
  readonly type = LanguageActionTypes.LOAD_COMPLETED;
  constructor(public payload: LanguageModel[]) {}
}
export class ResetState implements Action {
  readonly type = LanguageActionTypes.RESET_STATE;
}

export type All = LoadFromDbCompleted | LoadCompleted | ResetState;
