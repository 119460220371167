import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';

import { SettingsSelector } from '../../settings/store';
import { GenreModel } from '../models/genre.model';
import { GenreServiceAbstract } from './genre.service.abstract';
import { filterEmpty } from '../../tools/map/filter-empty';


@Injectable()
export class GenreService extends GenreServiceAbstract {
  private genresUrl = this.apiUrl + 'datas/main-genre';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string,
    private store: Store
  ) {
    super();
  }

  getAll(): Promise<GenreModel[]> {
    return this.store
      .select(SettingsSelector.getLanguage)
      .pipe(
        take(1),
        filterEmpty(),
        switchMap((lang) => this.http.get(`${this.genresUrl}/${lang}.json`)),
        map((resp) => plainToClass(GenreModel, resp as any[]))
      )
      .toPromise();
  }
}
