import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import { BkkrModule } from '@bkkr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IonContent, IonHeader } from '@ionic/angular/standalone';

import { FlagComponent } from '@songpush/ui/flag';

import { LanguageSelectFormComponent } from '../language-select-form';

@Component({
  selector: 'shared-language-select-modal',
  templateUrl: './language-select-modal.component.html',
  styleUrls: ['./language-select-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BkkrModule,
    TranslateModule,
    FlagComponent,
    LanguageSelectFormComponent,
    IonHeader,
    IonContent,
  ],
})
export class LanguageSelectModalComponent {
  @Output()
  completed = new EventEmitter();

  onComplete() {
    this.completed.emit();
  }
}
