export const LOAD_PAGE = '[BankAccount] load page';
export const LOAD_PAGE_COMPLETED = '[BankAccount] load page completed';
export const SELECT_PAGE = '[BankAccount] select page';
export const CHANGE_PAGER = '[BankAccount] change pager';
export const SINGLE_LOAD = '[BankAccount] single load';
export const SINGLE_LOAD_COMPLETED = '[BankAccount] single load completed';
export const SELECT = '[BankAccount] select';
export const DELETE = '[BankAccount] delete';
export const DELETE_COMPLETED = '[BankAccount] delete completed';
export const SEARCH = '[BankAccount] search';
export const SEARCHING = '[BankAccount] searching';
export const LOADALL = '[BankAccount] load all';
export const LOADALL_COMPLETED = '[BankAccount] load all completed';
export const RESETSTATE = '[BankAccount] reset state';
export const RELOAD_ENTITY = '[BankAccount] reload entity';
export const REORDER_LOCAL = '[BankAccount] reorder local';
export const REORDER_REMOTE = '[BankAccount] reorder remote';
export const UPDATE_MANDATE = '[BankAccount] update mandate';
