import { Action } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import * as CampaignV2ActionType from './campaign-v2.actions.type';

export class ReloadEntity implements Action {
  readonly type = CampaignV2ActionType.RELOAD_ENTITY;
  constructor(public id: number) {}
}
export class LoadPage implements Action {
  readonly type = CampaignV2ActionType.LOAD_PAGE;
  constructor(public page: number, public pagerId: string = 'default') {}
}

export class LoadPageCompleted implements Action {
  readonly type = CampaignV2ActionType.LOAD_PAGE_COMPLETED;
  constructor(
    public page: number,
    public maxCount: number,
    public payload: CampaignV2DetailsModel[],
    public pagerId: string = 'default',
    public wipe: boolean = false
  ) {}
}

export class SelectPage implements Action {
  readonly type = CampaignV2ActionType.SELECT_PAGE;
  constructor(public selectedPageNum: number, public pagerId: string = 'default', public wipe?: boolean) {}
}

export class ChangePager implements Action {
  readonly type = CampaignV2ActionType.CHANGE_PAGER;
  constructor(
    public payload: Partial<PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>>,
    public pagerId: string = 'default'
  ) {}
}

export class Search implements Action {
  readonly type = CampaignV2ActionType.SEARCH;
  constructor(
    public payload: CampaignV2SearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class Searching implements Action {
  readonly type = CampaignV2ActionType.SEARCHING;
  constructor(
    public payload: CampaignV2SearchModel,
    public event = { silent: false, force: false },
    public pagerId: string = 'default'
  ) {}
}
export class SingleLoad implements Action {
  readonly type = CampaignV2ActionType.SINGLE_LOAD;
  constructor(public id: number) {}
}

export class SingleLoadeCompleted implements Action {
  readonly type = CampaignV2ActionType.SINGLE_LOAD_COMPLETED;
  constructor(public payload: CampaignV2DetailsModel) {}
}
export class Select implements Action {
  readonly type = CampaignV2ActionType.SELECT;
  constructor(public id: number) {}
}
export class Delete implements Action {
  readonly type = CampaignV2ActionType.DELETE;
  constructor(public id: number) {}
}
export class DeleteCompleted implements Action {
  readonly type = CampaignV2ActionType.DELETE_COMPLETED;
  constructor(public id: number) {}
}
export class LoadAll implements Action {
  readonly type = CampaignV2ActionType.LOADALL;
  constructor(public pagerId: string = 'default') {}
}

export class LoadAllCompleted implements Action {
  readonly type = CampaignV2ActionType.LOADALL_COMPLETED;
  constructor(
    public pager: PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>,
    public payload: CampaignV2DetailsModel[],
    public pagerId: string = 'default'
  ) {}
}
export class ResetState implements Action {
  readonly type = CampaignV2ActionType.RESETSTATE;
}

export type All =
  | LoadPage
  | LoadPageCompleted
  | SelectPage
  | ChangePager
  | SingleLoadeCompleted
  | Select
  | DeleteCompleted
  | Searching
  | LoadAllCompleted
  | ResetState;
