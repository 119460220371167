import { Injectable } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponse } from '../../../common/models/PagerResponseContainer';
import { FormValidatorServiceAbstract } from '../../../form/services/form-validator/form-validator.service.abstract';
import { Mappings } from '../../../tools/mapping/mappings';
import { ObjectExtensions } from '../../../tools/object/object-extension';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import { SpotifyTrackServiceAbstract } from '../../services/spotify-track.service.abstract';
import { SpotifyTrackActionType, SpotifyTrackActions } from '../actions';
import { SpotifyTrackSelector } from '../selectors/spotify-track.selector';
import { SessionActions } from '../../../session/store/actions/session.actions';

@Injectable()
export class SpotifyTrackEffects {
  selectPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.SELECT_PAGE),
      mergeMap(async (action: SpotifyTrackActions.SelectPage) => ({
        selectedPageNum: action.selectedPageNum,
        pager: await this.getSelectedPager(action.pagerId).toPromise(),
        action,
      })),
      mergeMap(async ({ selectedPageNum, pager, action }) => ({
        response: await this.spotifyTrackService.getPage(pager),
        page: selectedPageNum,
        action,
      })),
      map(
        ({ response, page, action }) =>
          new SpotifyTrackActions.LoadPageCompleted(
            page,
            response.values.maxCount,
            response.values.results,
            action.pagerId,
            action.wipe
          )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.SEARCH),
      debounceTime(400),
      // prevent duplicate search start
      mergeMap((action: SpotifyTrackActions.Search) => zip(of(action), this.getSearchModel(action.pagerId))),
      filter(
        ([action, search]) =>
          !action.event.silent && (!ObjectExtensions.compare(action.payload, search) || action.event.force)
      ),
      map(([action]) => new SpotifyTrackActions.Searching(action.payload, action.event, action.pagerId))
    )
  );

  searching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.SEARCHING),
      mergeMap((action: SpotifyTrackActions.Searching) => zip(of(action), this.getSpotifyTracks(action.pagerId))),
      map(([action, resp]) => new SpotifyTrackActions.LoadPageCompleted(1, resp.maxCount, resp.results, action.pagerId))
    )
  );

  deleteSpotifyTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.DELETE),
      mergeMap(async (action: SpotifyTrackActions.Delete) => ({
        action,
        response: await this.spotifyTrackService.delete(action.id),
      })),
      tap(({ response }) =>
        response.status.globalMessage ? this.formValidator.showToast(response.status.globalMessage) : null
      ),
      filter(({ action, response }) => response?.status?.success),
      map(({ action }) => new SpotifyTrackActions.DeleteCompleted(action.id))
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.LOADALL),
      mergeMap((action: SpotifyTrackActions.LoadAll) =>
        this.getSelectedPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: SpotifyTrackDetailsModel[] = [];

        let maxCount = pager?.maxCount;
        while (maxCount === null || pager.limit * (pager.selectedPageNum - 1) < maxCount) {
          const resp = await this.spotifyTrackService.getPage(pager);
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
          maxCount = pager?.maxCount;
        }
        return new SpotifyTrackActions.LoadAllCompleted(pager, payload, action.pagerId);
      })
    )
  );

  resetState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.resetuserstate),
      map(() => new SpotifyTrackActions.ResetState())
    )
  );

  singleLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.SINGLE_LOAD),
      mergeMap(async (action: SpotifyTrackActions.SingleLoad) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => !ids.includes(action.id)),
      mergeMap(({ action }) => this.spotifyTrackService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SpotifyTrackActions.SingleLoadeCompleted(resp.values))
    )
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpotifyTrackActionType.RELOAD_ENTITY),
      mergeMap(async (action: SpotifyTrackActions.ReloadEntity) => ({
        action,
        ids: await this.getIds.toPromise(),
      })),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.spotifyTrackService.get(action.id)),
      filter((resp) => !!resp?.values),
      map((resp) => new SpotifyTrackActions.SingleLoadeCompleted(resp.values))
    )
  );

  getPager = this.store.select(SpotifyTrackSelector.getPagers).pipe(take(1));
  getIds = this.store.select(SpotifyTrackSelector.getIds).pipe(take(1));

  constructor(
    private actions$: Actions,
    private spotifyTrackService: SpotifyTrackServiceAbstract,
    private store: Store,
    private formValidator: FormValidatorServiceAbstract
  ) {}

  getSelectedPager(instance: string): Observable<PagerEntity<SpotifyTrackDetailsModel, SpotifyTrackSearchModel>> {
    return this.store.select(SpotifyTrackSelector.getPager(instance)).pipe(take(1));
  }

  getSearchModel(instance: string): Observable<SpotifyTrackSearchModel> {
    return this.getSelectedPager(instance).pipe(map((pager) => pager.search));
  }

  getSpotifyTracks(instance: string): Observable<PagerResponse<SpotifyTrackDetailsModel>> {
    return this.getSelectedPager(instance).pipe(
      take(1),
      mergeMap((pager) => this.spotifyTrackService.getPage(pager)),
      map((resp) => resp.values)
    );
  }
}
