import { PagerEntity } from '../../../common/models/PagerEntity';
import { ReducerTools } from '../../../tools/reducer-tools/reducer-tools';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2Actions, CampaignV2ActionType } from '../actions';

export class CampaignV2State {
  entities: { [key: number]: CampaignV2DetailsModel };
  selectedId: number;
  pagerIds: string[];
  pagers: {
    [key: string]: PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>;
  };
  ids: number[];
  initialPager: PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>;
}

const initialState: CampaignV2State = {
  entities: {},
  selectedId: null,
  pagerIds: [],
  pagers: {},
  ids: [],
  initialPager: new PagerEntity({
    id: 'default',
    pages: {},
    selectedPageNum: null,
    maxCount: null, // should be more than limit
    hasNew: false,
    limit: 30,
    asc: true,
    orderBy: '',
    search: new CampaignV2SearchModel({
      search: '',
    }),
  }),
};

export const campaignV2Reducer = (
  state: CampaignV2State = initialState,
  action: CampaignV2Actions.All
): CampaignV2State => {
  switch (action.type) {
    case CampaignV2ActionType.SELECT_PAGE:
      return ReducerTools.selectPageReduce(action, state, state.initialPager);

    case CampaignV2ActionType.LOAD_PAGE_COMPLETED:
      return ReducerTools.loadPageCompleteReduce(action, state, state.initialPager);

    case CampaignV2ActionType.LOADALL_COMPLETED:
      return ReducerTools.loadAllCompletedReduce(action, state);

    case CampaignV2ActionType.CHANGE_PAGER:
      return ReducerTools.changePagerReduce(action, state, state.initialPager);

    case CampaignV2ActionType.SEARCHING:
      return ReducerTools.searchingReduce(action, state, state.initialPager);

    case CampaignV2ActionType.SELECT:
      return ReducerTools.selectReduce(action, state, state.initialPager);

    case CampaignV2ActionType.SINGLE_LOAD_COMPLETED:
      return ReducerTools.singleLoadCompletedReduce(action, state);

    case CampaignV2ActionType.DELETE_COMPLETED:
      return ReducerTools.deleteReduce(action, state, state.initialPager);

    case CampaignV2ActionType.RESETSTATE:
      return initialState;

    default:
      return state;
  }
};
