import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { Mappings } from '../../../tools/mapping/mappings';
import { TicketDealCreateModel } from '../../models/ticket-deal-create.model';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { TicketServiceAbstract } from './ticket.service.abstract';

@Injectable()
export class TicketService extends TicketServiceAbstract {
  private readonly ticketCampaignV2Url = this.apiUrl + 'ticket-v2/deal';
  private readonly ticketSingleUrl = this.apiUrl + 'ticket';

  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string
  ) {
    super();
  }

  createWithDeal(model: TicketDealCreateModel): Promise<ResponseContainer<TicketDetailsModel>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.ticketCampaignV2Url, domain)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(TicketDetailsModel), resp)))
      .toPromise();
  }
  getPage(
    pager: PagerEntity<TicketDetailsModel, TicketSearchModel>
  ): Promise<PagerResponseContainer<TicketDetailsModel>> {
    const params: unknown = Mappings.pagerToDomain(pager);
    return this.http
      .get(this.ticketSingleUrl, { params } as any)
      .pipe(map((resp) => plainToClassFromExist(new PagerResponseContainer(TicketDetailsModel), resp)))
      .toPromise();
  }
  get(id: number): Promise<ResponseContainer<TicketDetailsModel>> {
    return this.http
      .get(`${this.ticketSingleUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(TicketDetailsModel), resp)))
      .toPromise();
  }
  delete(id: number): Promise<ResponseContainer<unknown>> {
    return this.http
      .delete(`${this.ticketSingleUrl}/${id}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)))
      .toPromise();
  }
}
