import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { interpolateString } from '../../tools/interpolate-string/interpolate-string';
import { SongDetailsModel } from '../models/song-details.model';
import { SongSelector } from '../store/selectors/song.selector';
import { filterEmpty } from '../../tools/map/filter-empty';

const naviageToUrl = (
  store = inject(Store),
  router = inject(Router),
  redirectUrl: string,
  continueUrl: string,
  extraParams: any
) =>
  store
    .select(SessionSelector.selectPathPrefix)
    .pipe(
      tap((sessionPrefix) => {
        const params = { ...extraParams, sessionPrefix };
        router.navigate(redirectUrl.split('/').map((part) => interpolateString(part, params)));
      }),
      take(1)
    )
    .subscribe();

@Injectable()
export class SongHasTiktokGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const redirectUrl: string = route.data.fullFillSongRedirectUrl || '404';
    const continueUrl = route.data.continueUrl;
    return this.hasHasTikTok(route.params.id).pipe(
      // navigate if !exist navigate to 404
      tap((exist) => (exist || null) ?? naviageToUrl(this.store, this.router, redirectUrl, continueUrl, route.params))
    );
  }

  getSongFromStore(id: number | string): Observable<SongDetailsModel> {
    return this.store.select(SongSelector.getByUnkownId(id)).pipe(filterEmpty(), take(1));
  }

  hasHasTikTok(id: number | string): Observable<boolean> {
    return this.getSongFromStore(id).pipe(map(({ tiktokUrl }) => !!tiktokUrl));
  }
}
