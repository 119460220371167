export const LOAD_PAGE = '[City] load page';
export const LOAD_PAGE_COMPLETED = '[City] load page completed';
export const SELECT_PAGE = '[City] select page';
export const CHANGE_PAGER = '[City] change pager';
export const SINGLE_LOAD = '[City] single load';
export const SINGLE_LOAD_COMPLETED = '[City] single load completed';
export const SELECT = '[City] select';
export const DELETE = '[City] delete';
export const DELETE_COMPLETED = '[City] delete completed';
export const SEARCH = '[City] search';
export const SEARCHING = '[City] searching';
export const LOADALL = '[City] load all';
export const LOADALL_COMPLETED = '[City] load all completed';
export const RESETSTATE = '[City] reset state';
export const RELOAD_ENTITY = '[City] reload entity';
