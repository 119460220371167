import { BaseMultiPagerSelector } from '../../../common/store';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserSearchModel } from '../../models/user-search.model';
import { UserState } from '../reducers/user.reducer';

export type UserStatePart = { user: UserState };
class UserSelectors extends BaseMultiPagerSelector<UserState, UserDetailsModel, UserSearchModel> {
  constructor() {
    super((state: UserStatePart): UserState => state.user);
  }
}

export const UserSelector = new UserSelectors();
