/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from '@ngrx/store';

import { BaseMultiPagerSelector } from '../../../common/store/selectors/base-multi-pager-selector';
import { BankAccountDetailsModel } from '../../models/bank-account-details.model';
import { BankAccountSearchModel } from '../../models/bank-account-search.model';
import { BankAccountState } from '../reducers/bank-account.reducer';
import { BankAccountMandateStatus } from '../../models/bank-account-mandate.status';


export type BankAccountStatePart = { bankAccount: BankAccountState };

class BankAccountSelectors extends BaseMultiPagerSelector<
  BankAccountState,
  BankAccountDetailsModel,
  BankAccountSearchModel
> {
  get = createSelector(this.getEntities, this.getIds, (ents, ids) => ents[ids?.[0]]);
  getMandate = createSelector(this.get, (bankAccount) => bankAccount?.mandate);
  canCreateMandate = createSelector(
    this.get,
    (bankAccount) =>
      !!bankAccount &&
      (!bankAccount?.mandate ||
        [BankAccountMandateStatus.expired, BankAccountMandateStatus.failed].includes(bankAccount.mandate.status))
  );

  constructor() {
    super((state: BankAccountStatePart): BankAccountState => state.bankAccount);
  }
}

export const BankAccountSelector = new BankAccountSelectors();
