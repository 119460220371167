import { Observable } from 'rxjs';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { MangopayRequestUrlModel } from '../../mangopay/models/mangopay-request-url.model';
import { MangopayUrlModel } from '../../mangopay/models/mangopay-url.model';
import { CampaignV2CreateModel } from '../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../models/campaign-v2-edit.model';
import { CampaignV2SearchModel } from '../models/campaign-v2-search.model';
import { CampaignV2SumModel } from '../models/campaign-v2-sum.model';
import { CampaignV2SummaryPreviewParamsModel } from '../models/campaign-v2-summary-preview.params.model';
import { CampaignV2TargetModel } from '../models/campaign-v2-target.model';

export abstract class CampaignV2ServiceAbstract {
  abstract getPage(
    pager: PagerEntity<CampaignV2DetailsModel, CampaignV2SearchModel>
  ): Promise<PagerResponseContainer<CampaignV2DetailsModel>>;
  abstract get(id: number): Promise<ResponseContainer<CampaignV2DetailsModel>>;
  abstract create(model: CampaignV2CreateModel): Promise<ResponseContainer<CampaignV2DetailsModel>>;
  abstract delete(id: number): Promise<ResponseContainer<unknown>>;
  abstract edit(model: CampaignV2EditModel, id: number): Promise<ResponseContainer<unknown>>;
  abstract setTarget(model: CampaignV2TargetModel, id: number): Promise<ResponseContainer<CampaignV2DetailsModel>>;
  abstract getPayoutUrl(model: MangopayRequestUrlModel, id: number): Promise<ResponseContainer<MangopayUrlModel>>;
  abstract getSummaryPreview(
    amount: CampaignV2SummaryPreviewParamsModel
  ): Promise<ResponseContainer<CampaignV2SumModel>>;
  abstract payFromWallet(model: MangopayRequestUrlModel, id: number): Observable<ResponseContainer<MangopayUrlModel>>;
}
