import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { interpolateString } from '../../tools/interpolate-string/interpolate-string';
import { SongServiceAbstract } from '../services/song.service.abstract';
import { hasSong } from './song-exits.guard';

export const songNotExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const redirectUrl: string = route.data.redirectUrl || '404';
  const continueUrl: string = route.data.continueUrl;
  const router = inject(Router);
  const store = inject(Store);

  return hasSong(
    decodeURIComponent(route.params.id),
    inject(SongServiceAbstract),
    inject(Store)
  ).pipe(
    // navigate if !exist navigate to 404
    map((exist) => !exist),
    tap(
      (notExist) =>
        (notExist || null) ??
        naviageToUrl(store, router, redirectUrl, continueUrl, {
          ...route.params,
          id: route.params.id,
        })
    )
  );
};

const naviageToUrl = (
  store = inject(Store),
  router = inject(Router),
  redirectUrl: string,
  continueUrl: string,
  extraParams: any
) =>
  store
    .select(SessionSelector.selectPathPrefix)
    .pipe(
      tap((sessionPrefix) => {
        let queryParams = {};
        const params = { ...extraParams, sessionPrefix };

        if (continueUrl) {
          queryParams = {
            ...queryParams,
            /// TODO resolve navigation encode on song edit
            redirectUrl: continueUrl,
          };
        }

        router.navigate(
          redirectUrl.split('/').map((part) => interpolateString(part, params)),
          { queryParams }
        );
      }),
      take(1)
    )
    .subscribe();
