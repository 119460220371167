import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { WebscocketLocalEffects } from './state/websocket-local.effects';

@NgModule({
  imports: [EffectsModule.forFeature([WebscocketLocalEffects])],
})
export class WebsocketStateModule {}
