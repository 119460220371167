import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SongServiceAbstract } from '../services/song.service.abstract';
import { SongActions } from '../store/actions';
import { SongSelector } from '../store/selectors/song.selector';
import { SessionSelector } from '../../session/store/selectors/session.selector';
import { interpolateString } from '../../tools/interpolate-string/interpolate-string';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

export const songExistGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const redirectUrl: string = route.data.redirectUrl || '404';
  const router = inject(Router);
  const store = inject(Store);

  return hasSong(route.params.id, inject(SongServiceAbstract), inject(Store)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => (exist || null) ?? naviageToUrl(store, router, redirectUrl, route.params))
  );
};

const naviageToUrl = (store = inject(Store), router = inject(Router), redirectUrl: string, extraParams: any) =>
  store
    .select(SessionSelector.selectPathPrefix)
    .pipe(
      tap((sessionPrefix) => {
        const params = { ...extraParams, sessionPrefix };
        router.navigate(redirectUrl.split('/').map((part) => interpolateString(part, params)));
      }),
      take(1)
    )
    .subscribe();

export const hasSongInStore = (id: number | string, store = inject(Store)): Observable<boolean> =>
  store.select(SongSelector.getByUnkownId(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasSongInApi = (
  id: Identifier,
  songService = inject(SongServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  defer(() => songService.get(id)).pipe(
    tap((resp) => {
      if (resp.values) {
        store.dispatch(new SongActions.SingleLoadeCompleted(resp.values));
      }
    }),
    map((resp) => !!resp?.values),
    catchError(() => of(false))
  );

export const hasSong = (
  id: Identifier,
  songService = inject(SongServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasSongInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasSongInApi(id, songService, store);
    })
  );
