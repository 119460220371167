import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';

import { catchError, map, mergeMap, of, shareReplay } from 'rxjs';

import { plainToClass } from 'class-transformer';
import { TranslateModule } from '@ngx-translate/core';
import { BkkrModule } from '@bkkr/angular';
import { IonCol, IonRow, IonSpinner } from '@ionic/angular/standalone';


import { LogoComponent } from '@songpush/ui/logo';
import { CopyrightComponent } from '@songpush/ui/copyright';
import { LayoutSplitComponent } from '@songpush/ui/layout-split';
import { ResponseContainer } from '@songpush/core/common/models';

import { LanguageSelectComponent } from '../../../language/components/language-select/language-select.component';
import { EmailServiceAbstract } from '../../services/email.service.abstract';


@Component({
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss'],
  standalone: true,
  imports: [
    LayoutSplitComponent,
    RouterLink,
    LogoComponent,
    BkkrModule,
    NgIf,
    LanguageSelectComponent,
    CopyrightComponent,
    AsyncPipe,
    TranslateModule,
    IonRow,
    IonCol,
    IonSpinner,
  ],
})
export class EmailUnsubscribeComponent {
  activatedRoute = inject(ActivatedRoute);
  emailService = inject(EmailServiceAbstract);

  response$ = this.activatedRoute.params.pipe(
    map(({ token }) => token),
    mergeMap((token) => this.emailService.unsubscribe(token)),
    catchError((err) =>
      of(
        plainToClass(ResponseContainer, {
          status: { success: false, globalMessage: 'GLOBAL_ERROR_UNKNOWN' },
        })
      )
    ),
    shareReplay(1)
  );
}
