import { Expose, Transform, Type, plainToClass } from 'class-transformer';

import { CampaignV2BriefingModel } from '../../campaign-v2/models/campaign-v2-briefing.model';
import { CurrencyV2Model } from '../../common/models/currency-v2.model';
import { SocialType } from '../../social/models/social-type';
import { DealHistoryModel } from './deal-history.model';
import { DealKpiModel } from './deal-kpi.model';
import { DealStatus } from './deal-status';
import { DealStatusGroup } from './deal-status-group';
import { Identifier } from '../../tools/reducer-helper/model/identifier';


const parseIntIfNotNaN = (value) => {
  const numberValue = parseInt(value);

  return isNaN(numberValue) ? undefined : numberValue / 100;
};

const mapBudget = ({ value }) => parseIntIfNotNaN(value?.amount) ?? parseIntIfNotNaN(value) ?? null;

export class DealDetailsModel {
  @Expose()
  id: number;
  @Expose()
  artistId?: number;
  @Expose()
  creatorId?: number;
  @Expose()
  songId: number;
  @Expose()
  @Type(() => CurrencyV2Model)
  price: CurrencyV2Model;
  @Expose()
  platform: SocialType;
  @Expose()
  viewScoreOnPlatform: number;
  @Expose()
  @Transform(({ obj }) => obj.history?.[0]?.status)
  status: DealStatus;
  @Expose()
  campaignId?: number;
  @Expose()
  expire: string;
  @Expose()
  proofUrl?: string;
  @Expose()
  inserted: string;
  @Expose()
  deleted: boolean;
  @Expose()
  deletable: boolean;
  @Expose()
  @Transform(({ obj }) => (obj.history as DealHistoryModel[])?.reverse(), { toClassOnly: true })
  history: DealHistoryModel[];
  @Expose()
  targetMinViewers: number;
  @Expose()
  trackingStart: string;
  @Expose()
  trackingEnd: string;
  @Expose()
  @Type(() => DealKpiModel)
  @Transform(({ obj }) => plainToClass(DealKpiModel, { ...obj.kpi, price: obj.price }))
  kpi: DealKpiModel;
  @Expose()
  ticketId: number;
  @Expose()
  statusGroup: DealStatusGroup;
  @Expose()
  @Type(() => CampaignV2BriefingModel)
  briefing: CampaignV2BriefingModel;
  @Expose()
  isSlideShow: boolean;
  @Expose()
  slideShowImages?: string[];
  @Expose()
  slideShowMp3?: string;

  @Expose()
  @Transform(({ obj }) => ((obj.history || []) as DealHistoryModel[]).flatMap((his) => his.userId))
  historyUserIds?: Identifier[];
}
