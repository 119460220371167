import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { AnalyticsEffects } from '../store/analytics.effect';

@NgModule({
  imports: [EffectsModule.forFeature([AnalyticsEffects])],
})
export class AnalyticsStateModule {}
